import CustomToast from 'components/CustomToast';
import Config from 'configuration';
import {pagePath} from 'configuration/routeConfig';
import {logout} from 'features/Auth/authSlice';
import {handleChangePage, handleChangePageSize, handleChangeTotalPage, uploadImage} from 'features/Home/homeSlice';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {calcTotalPage} from 'utils/calcTotalPage';
import propsProvider from './programPropsProvider';
import {addEvent, deleteEvent, getEventList, updateEvent, uploadBannerProgram, uploadProgramPdf} from './programSlice';
import ProgramMainView from './template/ProgramMainView';
import {getBase64} from 'utils/getBase64';

const ProgramContainer = (props) => {
    const {home, userLoginInfo, programList} = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const {pathname} = location;
    const {paginationData, activePage} = home;
    const [isOpenProgramModal, setOpenProgramModal] = useState(false);
    const [isProgramEdit, setIsProgramEdit] = useState(false);
    const [selectedProgram, setSelectedProgram] = useState({});
    const [searchProgramValue, setSearchProgramValue] = useState('');
    const [programBanner, setProgramBanner] = useState();
    const [descriptionProgram, setDescriptionProgram] = useState('');
    const [pdfUpload, setPdfUpload] = useState();

    const navigateTo = (path) => {
        try {
            if (path === pagePath.logoutUrl) {
                dispatch(logout()).then((res) => history.push(pagePath.authLoginUrl));
            } else {
                history.push(path);
            }
        } catch (e) {
            CustomToast(false, 'Có lỗi xảy ra');
        }
    };

    const setPage = (value) => {
        dispatch(handleChangePage({activePage: activePage, page: value}));
    };

    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage: activePage, pageSize: value}));
        setPage(1);
    };

    const setTotalPage = (count, pageSize) => {
        const value = calcTotalPage(count, pageSize);
        // console.log('value', value);
        dispatch(handleChangeTotalPage({activePage: activePage, totalPage: value}));
    };

    const handleSelectRow = (event, id) => {};
    const handleDeleteSelectedRows = async () => {};

    const handleSubmitInvalid = (_, event) => {
        console.log('submit invalid');
        // event.target.classList.add('wasvalidated');
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            let newSelected = [];
            // if (activePage === 0) {
            //     newSelected = programList.map((data) => data?.id);
            // } else {
            //     newSelected = sponsorList.map((data) => data?.id);
            // }
            // setCheckedData(newSelected);
            return;
        }
    };

    const handleAddProgram = async (data) => {
        const {name, address, speaker, start_date, end_time, join_link, live_stream} = data;

        const payload = {
            name: name,
            speaker: speaker,
            address: address,
            document_path: selectedProgram?.document_path ?? '',
            description: descriptionProgram,
            live_stream: live_stream,
            start_date: start_date,
            end_time: end_time,
            join_link: join_link
        };
        const res = await dispatch(
            !isProgramEdit ? addEvent(payload) : updateEvent({id: selectedProgram?.id, body: payload})
        ).unwrap();
        if (res?.success) {
            if (pdfUpload) {
                await handleAddPdfProgram(res.data.id).then(() => {
                    fetchProgramList();
                    closeProgramModal();
                });
            }
            if (programBanner) {
                await AddBannerProgram(res.data.id);
            }
            CustomToast('success', `${isProgramEdit ? 'Chỉnh sửa' : 'Thêm'} chương trình thành công`);
            if (!isProgramEdit) navigateTo(pagePath.programUrl);
            else {
                fetchProgramList();
                closeProgramModal();
            }
            fetchProgramList();
            closeProgramModal();
        } else {
            CustomToast(false, `${isProgramEdit ? 'Chỉnh sửa' : 'Thêm'} chương trình không thành công`);
        }
    };

    const handleDeleteProgram = async () => {
        try {
            const res = await dispatch(deleteEvent(selectedProgram?.id)).unwrap();
            console.log('selectedProgram?.id', selectedProgram?.id);
            const {success} = res;
            if (success) {
                CustomToast(success, 'Xóa chương trình thành công');
            }
        } catch (error) {
            CustomToast(false, 'Xóa chương trình thất bại');
        }
        fetchProgramList();
        closeProgramModal();
    };

    const handleSearchProgram = () => {
        fetchProgramList();
    };

    const handleUploadBannerProgram = async (e) => {
        const file = e.target.files[0];
        const {size} = file;

        if (size > 5 * 1024 * 1024) {
            CustomToast(false, 'Tệp vượt quá giới hạn dung lượng cho phép');
            return;
        }

        const previewUrl = await getBase64(file);
        setProgramBanner({url: previewUrl, file});
    };
    const handleDeleteBannerProgram = (e) => {
        setProgramBanner();
    };
    const AddBannerProgram = async (program_id) => {
        let fileFormData = new FormData();
        const file = programBanner.file;
        fileFormData.append('file', file);
        await dispatch(uploadBannerProgram({id: program_id, file: fileFormData})).then((res) => {
            fetchProgramList();
            closeProgramModal();
        });
    };
    const handleAddPdfProgram = async (id) => {
        let fileFormData = new FormData();
        fileFormData.append('file', pdfUpload);
        await dispatch(
            uploadProgramPdf({
                id: id,
                file: fileFormData
            })
        );
    };
    const handleUploadPdf = async (e) => {
        const file = e.target.files[0];
        // if (size > 5 * 1024 * 1024) {
        //     CustomToast(false, 'Tệp vượt quá giới hạn dung lượng cho phép');
        //     return;
        // }

        // const previewUrl = await getBase64(file);
        setPdfUpload(file);
    };
    const handleDeletePdf = () => {
        setPdfUpload(null);
    };
    const onUploadImage = async (image) => {
        if (userLoginInfo?.id) {
            const res = await dispatch(uploadImage({id: userLoginInfo?.id, body: image}));

            if (res?.payload?.success) {
                return res?.payload?.data?.physical_path;
            }
        }
    };
    const openProgramModal = (program, isEdit) => {
        setIsProgramEdit(isEdit);
        setSelectedProgram(program);
        setOpenProgramModal(true);
        setProgramBanner(program?.image_path ? {url: Config.apiConfig.endPoint + program?.image_path} : null);
        setDescriptionProgram(program?.description ?? '');
        setPdfUpload({name: program?.document_path ?? null});
    };

    const closeProgramModal = () => {
        setSelectedProgram({});
        setOpenProgramModal(false);
        setIsProgramEdit(false);
        setProgramBanner(null);
    };

    const fetchProgramList = async () => {
        const res = await dispatch(
            getEventList({
                Filters: searchProgramValue ? `name|address|speaker@=${searchProgramValue}` : '',
                Sorts: '',
                Page: paginationData[activePage].page,
                PageSize: paginationData[activePage].pageSize
            })
        ).unwrap();
        if (res?.success) {
            const {data} = res;
            setTotalPage(data?.total, data?.pageSize);
        }
    };

    useEffect(() => {
        fetchProgramList();
    }, [paginationData]);

    const programContainerProps = {
        programList,
        navigateTo,
        searchProgramValue,
        setSearchProgramValue,
        handleSearchProgram,
        setPage,
        setPageSize,
        handleSelectAllClick,
        handleSelectRow,
        handleDeleteSelectedRows,
        paginationData,
        activePage,
        openProgramModal,
        history,
        handleSubmitInvalid,
        handleAddProgram,
        handleUploadPdf,
        pdfUpload,
        handleDeletePdf,
        descriptionProgram,
        setDescriptionProgram,
        onUploadImage,
        programBanner,
        handleDeleteBannerProgram,
        handleUploadBannerProgram,
        isOpenProgramModal,
        closeProgramModal,
        isProgramEdit,
        handleDeleteProgram,
        selectedProgram
    };

    return <ProgramMainView {...propsProvider(programContainerProps)} />;
};

export default ProgramContainer;
