import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {Close} from 'assets';
import CustomButton from 'components/CustomButton/CustomButton';
import React from 'react';

const EditCatalogue = (props) => {
    const {isOpenCatalogueModal, closeCatalogueModal, fetchDeleteCatalogue} = props;

    return (
        <>
            <Dialog
                maxWidth={'xs'}
                fullWidth={true}
                open={isOpenCatalogueModal}
                onClose={closeCatalogueModal}
                aria-labelledby='voucher-modal'
                aria-describedby='apply-voucher-to-get-discount'
                className='dialog'>
                <form
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault();
                        fetchDeleteCatalogue();
                    }}>
                    <DialogTitle className='dialog-title' id='voucher-modal'>
                        <span>{'Xác nhận'}</span>
                        <button className='dialog-title__close'>
                            <Close
                                className='cursor-pointer'
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeCatalogueModal();
                                }}
                            />
                        </button>
                    </DialogTitle>

                    <DialogContent className='dialog-content'>{'Bạn có chắc muốn xóa catalogue này?'}</DialogContent>
                    <DialogActions className='dialog-actions'>
                        <CustomButton
                            variant='text'
                            onClick={(e) => {
                                e.preventDefault();
                                closeCatalogueModal();
                            }}>
                            Hủy
                        </CustomButton>
                        <CustomButton
                            variant='contained'
                            type='submit'
                            color={'danger'}
                            // disabled={!isFormValid && isSponsorEdit}
                        >
                            {'Đồng ý'}
                        </CustomButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default EditCatalogue;
