import {Delete, Edit} from 'assets';

export default function propsProvider(props) {
    const {
        consultants,
        paginationData,
        activePage,
        navigateTo,
        setPage,
        setPageSize,
        searchConsultantValue,
        setSearchConsultantValue,
        handleSearchConsultant,
        handleSelectAllClick,
        handleSelectRow,
        checkedData,
        handleDeleteSelectedRows,
        openConsultantModal,
        history,
        modalContent,
        handleAddConsultant,
        onUploadImage,
        handleDeleteTempAvatarConsultant,
        handleSubmitInvalid,
        descriptionConsultant,
        setDescriptionConsultant,
        handleUploadAvatarConsultant,
        avatarConsultant,
        isConsultantEdit,
        isOpenConsultantModal,
        selectedConsultant,
        closeConsultantModal,
        handleDeleteConsultant
    } = props;

    const {page, pageSize, totalPage = 1} = paginationData[activePage] || {};
    const consultantHeadCells = [
        {
            id: 'Họ và tên',
            align: 'left',
            padding: 'normal',
            label: 'Họ và tên'
        },
        {
            id: 'Bằng cấp',
            align: 'left',
            padding: 'normal',
            label: 'Bằng cấp'
        },
        {
            id: 'Nơi làm việc',
            align: 'left',
            padding: 'normal',
            label: 'Nơi làm việc'
        },
        {
            id: 'Điện thoại',
            align: 'left',
            padding: 'normal',
            label: 'Điện thoại'
        },
        {
            id: 'Email',
            align: 'left',
            padding: 'normal',
            label: 'Email'
        },
        {
            id: 'Thao tác',
            align: 'center',
            padding: 'normal',
            label: 'Thao tác'
        }
    ];

    const consultantTableData =
        consultants &&
        consultants.map((el) => ({
            id: el?.id,
            full_name: {value: el?.full_name, isshow: true},
            degree: {value: el?.degree, isshow: true},
            work_unit: {value: el?.work_unit, isshow: true},
            cell_phone: {value: el?.cell_phone, isshow: true},
            email: {value: el?.email, isshow: true},
            actions: {
                value: (
                    <div className='d-flex justify-content-center gap-3'>
                        <Edit className='cursor-pointer' onClick={() => openConsultantModal(el, true)} />
                        <Delete className='cursor-pointer' onClick={() => openConsultantModal(el, false)} />
                    </div>
                ),
                isshow: true
            },
            programObj: {
                ...el
            }
        }));

    return {
        history,
        modalContent,
        consultantListProps: {
            navigateTo,
            page,
            pageSize,
            totalPage,
            setPage,
            setPageSize,
            headCells: consultantHeadCells,
            tableData: consultantTableData,
            searchConsultantValue,
            setSearchConsultantValue,
            handleSearchConsultant,
            handleSelectAllClick,
            handleSelectRow,
            checkedData,
            handleDeleteSelectedRows
        },
        addConsultantProps: {
            handleSubmitInvalid,
            handleAddConsultant,
            navigateTo,
            onUploadImage,
            setDescriptionConsultant,
            descriptionConsultant,
            handleDeleteTempAvatarConsultant,
            handleUploadAvatarConsultant,
            avatarConsultant,
            isConsultantEdit
        },
        editConsultantProps: {
            handleSubmitInvalid,
            isOpenConsultantModal,
            isConsultantEdit,
            selectedConsultant,
            handleAddConsultant,
            closeConsultantModal,
            handleDeleteConsultant,
            onUploadImage,
            setDescriptionConsultant,
            descriptionConsultant,
            handleDeleteTempAvatarConsultant,
            handleUploadAvatarConsultant,
            avatarConsultant
        }
    };
}
