import CustomButton from 'components/CustomButton/CustomButton';
import React from 'react';

const ActionBar = ({selectedRow, rowCount, handleMultiAction}) => {
    return (
        <div className='d-flex align-items-center gap-3'>
            <span style={{fontSize: '17px', fontFamily: 'Lato, sans-serif', color: '#000'}}>
                Đã chọn {selectedRow === rowCount ? 'tất cả' : `${selectedRow} dòng`}
            </span>
            <CustomButton
                onClick={handleMultiAction}
                color='danger'
                variant='outlined'
                style={{padding: '13px 35px', borderRadius: '8px'}}>
                Xóa
            </CustomButton>
        </div>
    );
};

export default ActionBar;
