import {FormControl, FormHelperText} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomTextEditor from 'components/CustomTextEditor';
import GroupInput from 'components/GroupInput/GroupInput';
import InputFiles from 'components/InputFiles/InputFiles';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import {pagePath} from 'configuration/routeConfig';
import {Controller, useForm} from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';
const defaultValues = {
    full_name: '',
    degree: '',
    work_unit: '',
    cell_phone: '',
    email: '',
    capacity_at: ''
};

const AddConsultant = (props) => {
    const {
        handleSubmitInvalid,
        handleAddConsultant,
        navigateTo,
        onUploadImage,
        setDescriptionConsultant,
        descriptionConsultant,
        handleDeleteTempAvatarConsultant,
        handleUploadAvatarConsultant,
        avatarConsultant,
    } = props;
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch
    } = useForm({defaultValues});
    const addConsultantInputs = [
        {
            label: 'Họ tên',
            placeholder: 'Nhập họ tên',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'full_name'
        },
        {
            label: 'Bằng cấp',
            placeholder: 'Nhập bằng cấp',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'degree'
        },
        {
            label: 'Nơi làm việc',
            placeholder: 'Nhập nơi làm việc',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'work_unit'
        },
        {
            label: 'Số điện thoại',
            placeholder: 'Nhập số điện thoại',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'cell_phone'
        },
        {
            label: 'Địa chỉ email',
            placeholder: 'Nhập địa chỉ email',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'email'
        }
        // {
        //     label: 'Năng lực',
        //     placeholder: 'Nhập năng lực',
        //     isRequired: false,
        //     rules: { required: { value: false, messages: 'trường này là bắt buộc' } },
        //     type: 'text',
        //     name: 'capacity_at'
        // }
    ];
    return (
        <CustomCard title={'Thông tin chuyên gia'}>
            <form
                className='mt-2 add-news'
                method='POST'
                action='#'
                noValidate
                onSubmit={handleSubmit(handleAddConsultant, handleSubmitInvalid)}>
                <div style={{width: '100%'}}>
                    <div className='d-flex align-items-center flex-wrap gap-3'>
                        {avatarConsultant && (
                            <PreviewImage
                                image={avatarConsultant?.url}
                                onDeleteImage={handleDeleteTempAvatarConsultant}
                            />
                        )}
                        {!avatarConsultant && (
                            <InputFiles
                                type='image'
                                inputNotes={['Thêm hình ảnh', 'Định dạng tệp PNG, JPEG, JPG', 'Kích thước: tối đa 5MB']}
                                accept={['.png', '.jpeg', '.jpg']}
                                handleUpload={handleUploadAvatarConsultant}
                                name='image'
                                control={control}
                            />
                        )}
                    </div>
                    {errors['images']?.message && <FormHelperText error>{errors['images']?.message}</FormHelperText>}
                </div>
                {addConsultantInputs.map((input, idx) => {
                    const {type, rules, groupInputData, label, name, ...restProps} = input;
                    return (
                        <FormControl key={idx}>
                            {type !== 'group' ? (
                                <Controller
                                    name={name}
                                    control={control}
                                    rules={rules}
                                    key={idx}
                                    render={({field}) =>
                                        type === 'date' ? (
                                            <CustomDatePicker {...field} {...restProps} label={label} />
                                        ) : (
                                            <CustomInput label={label} {...field} {...restProps} type={type} />
                                        )
                                    }
                                />
                            ) : (
                                <GroupInput control={control} label={label} key={idx} groupInputData={groupInputData} />
                            )}
                        </FormControl>
                    );
                })}
                <CustomTextEditor
                    value={descriptionConsultant}
                    setValue={setDescriptionConsultant}
                    onUploadImage={onUploadImage}
                />
                <br />
                <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                    <CustomButton variant='outlined' onClick={() => navigateTo(pagePath.consultantUrl)} type='button'>
                        Hủy
                    </CustomButton>
                    <CustomButton variant='contained' type='submit'>
                        Lưu
                    </CustomButton>
                </div>
            </form>
        </CustomCard>
    );
};

export default AddConsultant;
