import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {calcTotalPage} from 'utils/calcTotalPage';
import propsProvider from './customerPropsProvider';

import CustomToast from 'components/CustomToast';
import {pagePath} from 'configuration/routeConfig';
import {logout} from 'features/Auth/authSlice';
import {handleChangePage, handleChangePageSize, handleChangeTotalPage} from 'features/Home/homeSlice';
import {getBase64} from 'utils/getBase64';
import CustomerMainView from './template/CustomerMainView';
import {
    addCustomer,
    addNewTag,
    deleteCustomer,
    getAllTag,
    getCustomerList,
    importFileCustomer,
    updateCustomer
} from './customerSlice';
import * as XLSX from 'xlsx';

const CustomerContainer = (props) => {
    const {home, customerList, tagList} = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const {paginationData, activePage} = home;
    const [checkedData, setCheckedData] = useState([]);
    const [searchCustomerValue, setSearchCustomerValue] = useState();
    const [isCustomerEdit, setCustomerEdit] = useState(false);
    const [isOpenCustomerModal, setOpenCustomerModal] = useState();
    const [isOpenImportCustomerModal, setIsOpenImportCustomer] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [errorText, setErrorText] = useState();
    const [importExcelData, setImportExcelData] = useState();

    const navigateTo = (path) => {
        try {
            if (path === pagePath.logoutUrl) {
                dispatch(logout()).then((res) => history.push(pagePath.authLoginUrl));
            } else {
                history.push(path);
            }
        } catch (e) {
            CustomToast(false, 'Có lỗi xảy ra');
        }
    };

    const setPage = (value) => {
        dispatch(handleChangePage({activePage: activePage, page: value}));
    };

    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage: activePage, pageSize: value}));
        setPage(1);
    };

    const setTotalPage = (count, pageSize) => {
        const value = calcTotalPage(count, pageSize);
        dispatch(handleChangeTotalPage({activePage: activePage, totalPage: value}));
    };

    const handleSelectRow = (event, id) => {};
    const handleDeleteSelectedRows = async () => {};
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            let newSelected = [];
            // if (activePage === 0) {
            //     newSelected = programList.map((data) => data?.id);
            // } else {
            //     newSelected = sponsorList.map((data) => data?.id);
            // }
            setCheckedData(newSelected);
            return;
        }

        setCheckedData([]);
    };
    const handleSubmitInvalid = (_, event) => {
        console.log('submit invalid');
        // event.target.classList.add('wasvalidated');
    };

    const fetchCustomerList = async () => {
        const res = await dispatch(
            getCustomerList({
                Filters: searchCustomerValue ? `full_name|name_tag|email@=${searchCustomerValue}` : '',
                Sorts: '-created_at',
                Page: paginationData[activePage]?.page ?? 1,
                PageSize: paginationData[activePage]?.pageSize ?? 10
            })
        ).unwrap();
        if (res?.success) {
            const {data} = res;
            setTotalPage(data?.total, data?.pageSize);
        }
    };
    const fetchAllTag = () => {
        dispatch(
            getAllTag({
                Filters: '',
                Sorts: '-created_at',
                Page: 1,
                PageSize: 10000
            })
        ).unwrap();
    };
    const handleAddCustomer = async (data) => {
        const {full_name, work_unit, phone_number, email, note, tags} = data;

        if (Object.keys(tags).length === 0) {
            return;
        }
        const customer = tagList.filter((customer) => customer.id === tags);

        const body = {
            full_name,
            unit_name: work_unit,
            phone_number,
            email,
            note,
            tag_id: tags
        };
        const res = await dispatch(
            !isCustomerEdit
                ? addCustomer({tag_name: customer[0]?.name, body})
                : updateCustomer({id: selectedCustomer?.id, newTag: customer[0]?.name, body})
        ).unwrap();
        const {success} = res;
        if (success) {
            CustomToast('success', `${isCustomerEdit ? 'Chỉnh sửa' : 'Thêm'} thành công`);
            if (!isCustomerEdit) navigateTo(pagePath.customerURL);
            else {
                fetchCustomerList();
                closeCustomerModal();
            }
            fetchCustomerList();
        } else {
            CustomToast(success, `${isCustomerEdit ? 'Chỉnh sửa' : 'Thêm'} khách hàng không thành công`);
        }
    };
    const handleDeleteCustomer = async (data) => {
        const res = await dispatch(deleteCustomer(selectedCustomer?.id)).unwrap();

        if (res) {
            CustomToast(res, 'Xóa khách hàng thành công');
            fetchCustomerList();
            closeCustomerModal();
        } else {
            CustomToast(false, 'Xóa khách hàng thành công');
        }
    };
    const handleAddTag = async (data) => {
        const {newTag} = data;
        const existTag = tagList.filter((tag) => tag.name === newTag);
        if (newTag.length === 0 || existTag.length > 0) {
            setErrorText(`Đã có tag ${newTag}`);
            return;
        }

        const body = {
            name: newTag
        };

        const res = await dispatch(addNewTag(body)).unwrap();

        const {success} = res;
        if (success) {
            fetchAllTag();
            CustomToast('success', `${isCustomerEdit ? 'Chỉnh sửa' : 'Thêm'} thành công`);
            navigateTo(pagePath.customerURL);
        } else {
            CustomToast(success, `${isCustomerEdit ? 'Chỉnh sửa' : 'Thêm'} tag không thành công`);
        }
    };
    const handleUploadExcel = async (e) => {
        setImportExcelData(e.target.files[0]);
        // const promise = new Promise((resolve, reject) => {
        //     const fileReader = new FileReader();
        //     fileReader.readAsArrayBuffer(e.target.files[0]);

        //     fileReader.onload = (e) => {
        //         const bufferArray = e.target.result;

        //         const wb = XLSX.read(bufferArray, {type: 'buffer'});

        //         const wsname = wb.SheetNames[0];

        //         const ws = wb.Sheets[wsname];

        //         const data = XLSX.utils.sheet_to_json(ws);

        //         resolve(data);
        //     };

        //     fileReader.onerror = (error) => {
        //         reject(error);
        //     };
        // });

        // promise.then((data) => {
        //     if (data && data.length > 0) {
        //         setImportExcelData(data);
        //     }
        // });
    };
    const handleChangeFilterByTag = useCallback(async (tag_name) => {
        // const res = await dispatch(
        //     getCustomerList({
        //         Filters: tag_name === 'Tất cả' ? '' : `full_name|name_tag|email@=${tag_name}`,
        //         Sorts: '-created_at',
        //         Page: paginationData[activePage]?.page ?? 1,
        //         PageSize: paginationData[activePage]?.pageSize ?? 10
        //     })
        // ).unwrap();
        // if (res?.success) {
        //     const {data} = res;
        //     setTotalPage(data?.total, data?.pageSize);
        // }
        setSearchCustomerValue(tag_name === 'Tất cả' ? '' : tag_name);
    }, []);

    const onSubmitImportDataExcel = () => {
        if (importExcelData) {
            let fileFormData = new FormData();
            fileFormData.append('title', importExcelData?.name);
            fileFormData.append('file', importExcelData);
            dispatch(importFileCustomer({file: fileFormData})).then((res) => {
                console.log('importFileCustomer', res);
                const {success} = res?.payload;
                if (success) {
                    CustomToast('success', 'Import file thành công');
                    fetchCustomerList();
                    closeImportCustomer();
                } else {
                    CustomToast('false', 'Import file thất bại');
                }
            });
        }
    };
    const openCustomerModal = (consultant, isEdit) => {
        setOpenCustomerModal(true);
        setCustomerEdit(isEdit);
        setSelectedCustomer(consultant);
    };
    const closeCustomerModal = () => {
        setSelectedCustomer({});
        setOpenCustomerModal(false);
        setCustomerEdit(false);
    };
    const closeImportCustomer = () => {
        setImportExcelData(null);
        setIsOpenImportCustomer(false);
    };
    const onDeleteExcel = () => {
        setImportExcelData(null);
    };
    useEffect(() => {
        fetchAllTag();
    }, []);
    useEffect(() => {
        paginationData && fetchCustomerList();
    }, [paginationData]);

    useEffect(() => {
        fetchCustomerList();
    }, [searchCustomerValue]);

    const companyContainerProps = {
        history,
        navigateTo,
        customerList,
        checkedData,
        handleDeleteSelectedRows,
        setPageSize,
        handleSelectRow,
        setPage,
        paginationData,
        activePage,
        searchCustomerValue,
        setSearchCustomerValue,
        fetchCustomerList,
        openCustomerModal,
        handleSubmitInvalid,
        isOpenCustomerModal,
        isCustomerEdit,
        selectedCustomer,
        handleAddCustomer,
        closeCustomerModal,
        handleDeleteCustomer,
        handleAddTag,
        tagList,
        errorText,
        handleUploadExcel,
        isOpenImportCustomerModal,
        setIsOpenImportCustomer,
        onDeleteExcel,
        importExcelData,
        onSubmitImportDataExcel,
        closeImportCustomer,
        handleChangeFilterByTag
    };

    return <CustomerMainView {...propsProvider(companyContainerProps)} />;
};

export default CustomerContainer;
