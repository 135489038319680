import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import authApi from 'api/authenticateApi';
import Config from 'configuration';
import serviceRequest from 'app/serviceRequest';

const initialAuth = () => {
    const auth = sessionStorage.getItem(Config.storageKey.auth);
    if (auth) {
        return { ...JSON.parse(auth) };
    }

    return {
        userLoginInfo: {},
        isLogin: false,
        permission: [],
        email: ''
    };
};
export const authenticate = createAsyncThunk('auth/authenticate', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.authenticate,
        payload: data
    });
});
export const getMyInfo = createAsyncThunk('auth/getMyInfo', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.getMyInfo
    });
});

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.logout,
        options: { skipLoader: false }
    });
});

export const forgetPasswordReq = createAsyncThunk('auth/forget-password', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.forgotPassword,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const resetPasswordReq = createAsyncThunk('auth/reset-password', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.resetPassword,
        payload: data
    });
});
export const deleteAccount = createAsyncThunk('auth/deleteAccount', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.deleteAccount,
        payload: data
    });
});
export const sendOtpDelete = createAsyncThunk('auth/sendOtpDelete', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.sendOTPDelete,
        payload: data
    });
});
const auth = createSlice({
    name: 'auth',
    initialState: initialAuth(),
    reducers: {
        handleSetEmail: (state, action) => {
            state.email = action.payload;
        }
    },
    extraReducers: (builders) => {
        builders
            .addCase(authenticate.fulfilled, (state, action) => {
                state.token = action.payload.token;
                state.isLogin = action.payload.success;
                sessionStorage.setItem(Config.storageKey.auth, JSON.stringify(state));
            })
            .addCase(getMyInfo.fulfilled, (state, action) => {
                state.userLoginInfo = action.payload?.user;
                sessionStorage.setItem(Config.storageKey.auth, JSON.stringify(state));
            })
            .addCase(logout.fulfilled, () => {
                sessionStorage.removeItem(Config.storageKey.cart);
                sessionStorage.removeItem(Config.storageKey.auth);

                window.location.reload();
            });
    }
});

const { reducer, actions } = auth;
export const { handleSetEmail } = actions;
export default reducer;
