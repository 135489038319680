import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import supplierApi from 'api/supplierApi';
import serviceRequest from 'app/serviceRequest';

export const getSupplierList = createAsyncThunk('supplier/getSupplierList', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: supplierApi.getSuppliers,
        payload: data
    });
});
export const addSupplier = createAsyncThunk('supplier/addSupplier', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: supplierApi.addSupplier,
        payload: data
    });
});
export const updateSupplier = createAsyncThunk('supplier/updateSupplier', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: supplierApi.updateSupplier,
        payload: data
    });
});
export const deleteSupplier = createAsyncThunk('supplier/deleteSupplier', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: supplierApi.deleteSupplier,
        payload: data
    });
});
export const deleteProduct = createAsyncThunk('supplier/deleteProduct', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: supplierApi.deleteProduct,
        payload: data
    });
});

export const getProductByOrganizationId = createAsyncThunk(
    'supplier/getProductByOrganizationId',
    async (data, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: supplierApi.getProductByOrganizationId,
            payload: data
        });
    }
);
export const addProduct = createAsyncThunk('supplier/addProduct', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: supplierApi.addProduct,
        payload: data
    });
});

export const updateProduct = createAsyncThunk('supplier/updateProduct', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: supplierApi.updateProduct,
        payload: data
    });
});

export const uploadProductImage = createAsyncThunk('supplier/uploadProductImage', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: supplierApi.uploadProductImage,
        payload: data
    });
});

export const uploadSupplierLogo = createAsyncThunk('supplier/uploadSupplierLogo', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: supplierApi.uploadSupplierLogo,
        payload: data
    });
});

export const uploadSupplierBanner = createAsyncThunk('supplier/uploadSupplierBanner', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: supplierApi.uploadSupplierBanner,
        payload: data
    });
});

export const getProductById = createAsyncThunk('supplier/getProductById', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: supplierApi.getProduct,
        payload: data
    });
});
const supplier = createSlice({
    name: 'supplier',
    initialState: {},
    reducers: {},
    extraReducers: (builders) => {
        builders.addCase(getSupplierList.fulfilled, (state, action) => {
            // console.log('action', action);
            state.supplierList = action?.payload?.data?.collection;
        });
    }
});

const {reducer, actions} = supplier;
export default reducer;
