import {configureStore} from '@reduxjs/toolkit';
import homeReducer from 'features/Home/homeSlice';
import authReducer from 'features/Auth/authSlice';
import loaderReducer from './loaderSlice';
import consultantReducer from 'features/Consultant/consultantSlice';
import companyInfoReducer from 'features/Company/companySlice';
import programReducer from 'features/Program/programSlice';
import newsReducer from 'features/News/newsSlice';
import supplierReducer from 'features/Supplier/supplierSlice';
import bannerReducer from 'features/Banner/bannerSlice';
import catalogueReducer from 'features/Catalogue/catalogueSlice';
import checkInReducer from 'features/CheckIn/checkInSlice';
import customerReducer from 'features/Customer/customerSlice';

const rootReducer = {
    home: homeReducer,
    auth: authReducer,
    loader: loaderReducer,
    consultant: consultantReducer,
    companyInfo: companyInfoReducer,
    program: programReducer,
    news: newsReducer,
    supplier: supplierReducer,
    banner: bannerReducer,
    catalogue: catalogueReducer,
    checkIn: checkInReducer,
    customer: customerReducer
};

const store = configureStore({
    reducer: rootReducer
});

export default store;
