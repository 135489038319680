import {pagePath} from 'configuration/routeConfig';
import Home from 'features/Home';
import SupplierList from './SupplierList';
import AddSupplier from './AddSupplier';
import EditSupplier from './EditSupplier';

const SupplierMainView = (props) => {
    const {supplierListProps, history, addSupplierProps, editSupplierProps} = props;
    let SupplierComponent = null;
    switch (history.location.pathname) {
        case pagePath.supplierUrl:
            SupplierComponent = <SupplierList {...supplierListProps} />;
            break;
        case pagePath.supplierCreateUrl:
            SupplierComponent = <AddSupplier {...addSupplierProps} />;
            break;
        default:
            SupplierComponent = <SupplierList {...supplierListProps} />;
            break;
    }
    return (
        <Home>
            <EditSupplier {...editSupplierProps} />
            {SupplierComponent}
        </Home>
    );
};

export default SupplierMainView;
