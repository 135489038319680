import React from 'react';
import CompanyContainer from './companyContainer';
import {useSelector} from 'react-redux';
import {homeSelector, consultantSelector, authSelector, companyInfoSelector} from 'app/selectors';

const Company = (props) => {
    const home = useSelector(homeSelector);
    const consultant = useSelector(consultantSelector);
    const auth = useSelector(authSelector);
    const companySelector = useSelector(companyInfoSelector);
    const {companyInfo = []} = companySelector;
    const {userLoginInfo} = auth;
    const {consultants} = consultant;
    const consultantContainerProps = {
        home,
        consultants,
        userLoginInfo,
        companyInfo
    };

    return <CompanyContainer {...consultantContainerProps} />;
};

export default Company;
