import React, {useEffect} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import {Close, Trash, pdfIcon} from 'assets';
import {Controller, useForm} from 'react-hook-form';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomDatePicker from 'components/CustomDatePicker';
import GroupInput from 'components/GroupInput/GroupInput';
import dayjs from 'dayjs';
import InputFiles from 'components/InputFiles/InputFiles';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import CustomTextEditor from 'components/CustomTextEditor';
import PreviewPdf from 'components/PreviewPdf';
const defaultValues = {
    name: '',
    address: '',
    start_date: '',
    end_time: '',
    description: '',
    speaker: '',
    live_stream: '',
    document: {}
};

const EditProgram = (props) => {
    const {
        isOpenProgramModal,
        closeProgramModal,
        isProgramEdit,
        handleAddProgram,
        handleSubmitInvalid,
        handleDeleteProgram,
        selectedProgram,
        handleUploadPdf,
        pdfUpload,
        handleDeletePdf,
        programBanner,
        handleUploadBannerProgram,
        handleDeleteBannerProgram,
        descriptionProgram,
        setDescriptionProgram,
        onUploadImage
    } = props;

    const {
        handleSubmit,
        control,
        formState: {errors},
        watch,
        reset,
        setValue
    } = useForm({defaultValues});
    const watchAllFields = watch();
    // const isFormValid = Object.values(watchAllFields).every((value) => {
    //     if (value && typeof value === 'object') {
    //         return new Date(value).toISOString().length > 0;
    //     }

    //     return value?.length > 0;
    // });

    const addProgramInputs = [
        {
            label: 'Tên chương trình',
            placeholder: 'Nhập tên chương trình',
            isRequired: true,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'name'
        },
        {
            label: 'Địa điểm',
            placeholder: 'Nhập địa điểm',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'address'
        },
        // {
        //     label: 'Mô tả',
        //     placeholder: 'Nhập mô tả',
        //     isRequired: false,
        //     rules: { required: { value: true, messages: 'trường này là bắt buộc' } },
        //     type: 'text',
        //     name: 'description'
        // },
        {
            label: (
                <span>
                    Thời gian <span className='text-required'>*</span>
                </span>
            ),
            type: 'group',
            control,
            groupInputData: [
                {
                    placeholder: 'Từ',
                    isRequired: true,
                    rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
                    type: 'date',
                    name: 'start_date'
                },
                {
                    placeholder: 'Đến',
                    isRequired: true,
                    rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
                    type: 'date',
                    name: 'end_time'
                }
            ]
        },
        {
            label: 'Link đăng ký chương trình',
            placeholder: 'Nhập link đăng ký chương trình',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'join_link'
        },
        {
            label: 'Đường dẫn live stream',
            placeholder: 'Nhập đường dẫn',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'url',
            name: 'live_stream'
        },
        {
            label: 'Speaker',
            placeholder: 'Nhập speaker',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'speaker'
        },
        {
            label: 'Tệp tin',
            placeholder: 'Thêm tệp tin',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'file',
            name: 'document'
        }
    ];

    useEffect(() => {
        reset();
    }, [isOpenProgramModal]);

    useEffect(() => {
        if (isProgramEdit && selectedProgram) {
            setValue('name', selectedProgram?.name);
            setValue('address', selectedProgram?.address);
            setValue('description', selectedProgram?.description);
            setValue('live_stream', selectedProgram?.live_stream);
            setValue('start_date', selectedProgram?.start_date);
            setValue('end_time', selectedProgram?.end_time);
            setValue('speaker', selectedProgram?.speaker);
            setValue('speaker', selectedProgram?.speaker);
            setValue('document_path', selectedProgram?.document_path);
            setValue('join_link', selectedProgram?.join_link);
        }
    }, [isOpenProgramModal, selectedProgram]);

    return (
        <>
            <Dialog
                maxWidth={isProgramEdit ? 'sm' : 'xs'}
                fullWidth={true}
                open={isOpenProgramModal}
                onClose={closeProgramModal}
                aria-labelledby='voucher-modal'
                aria-describedby='apply-voucher-to-get-discount'
                className='dialog'>
                <form
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (isProgramEdit) {
                            handleSubmit(handleAddProgram, handleSubmitInvalid)();
                        } else {
                            handleDeleteProgram();
                        }
                    }}>
                    <DialogTitle className='dialog-title' id='voucher-modal'>
                        <span>{isProgramEdit ? 'Chỉnh sửa chương trình' : 'Xác nhận'}</span>
                        <button className='dialog-title__close'>
                            <Close
                                className='cursor-pointer'
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeProgramModal();
                                }}
                            />
                        </button>
                    </DialogTitle>

                    <DialogContent className='dialog-content' style={{padding: '32px 24px'}}>
                        <div className='add-program'>
                            {!isProgramEdit
                                ? 'Bạn có chắc muốn xóa chương trình này?'
                                : addProgramInputs.map((input, idx) => {
                                      const {type, rules, groupInputData, label, name, ...restProps} = input;

                                      return (
                                          <FormControl key={idx}>
                                              {type !== 'group' ? (
                                                  <Controller
                                                      name={name}
                                                      control={control}
                                                      rules={rules}
                                                      key={idx}
                                                      render={({field: {value, ...restField}}) =>
                                                          type === 'date' ? (
                                                              <CustomDatePicker
                                                                  {...restField}
                                                                  {...restProps}
                                                                  label={label}
                                                                  value={value}
                                                              />
                                                          ) : (
                                                              <div style={{marginBottom: 10}}>
                                                                  {type === 'file' ? (
                                                                      <>
                                                                          {pdfUpload ? (
                                                                              <PreviewPdf
                                                                                  fileName={pdfUpload.name}
                                                                                  onDeletePdf={handleDeletePdf}
                                                                              />
                                                                          ) : (
                                                                              <InputFiles
                                                                                  type='file'
                                                                                  inputNotes={[
                                                                                      'Định dạng tệp PDF',
                                                                                      'Kích thước: tối đa 100MB'
                                                                                  ]}
                                                                                  accept={['.pdf']}
                                                                                  handleUpload={handleUploadPdf}
                                                                                  name='pdf'
                                                                                  control={control}
                                                                              />
                                                                          )}
                                                                      </>
                                                                  ) : (
                                                                      <CustomInput
                                                                          label={label}
                                                                          value={value}
                                                                          {...restField}
                                                                          {...restProps}
                                                                      />
                                                                  )}
                                                              </div>
                                                          )
                                                      }
                                                  />
                                              ) : (
                                                  <GroupInput
                                                      control={control}
                                                      label={label}
                                                      key={idx}
                                                      groupInputData={groupInputData}
                                                  />
                                              )}
                                          </FormControl>
                                      );
                                  })}
                        </div>
                        {isProgramEdit && (
                            <div style={{marginTop: 10, marginBottom: 10}}>
                                <div className='d-flex align-items-center flex-wrap gap-3'>
                                    {programBanner && (
                                        <PreviewImage
                                            image={programBanner?.url}
                                            onDeleteImage={() => handleDeleteBannerProgram()}
                                        />
                                    )}

                                    {!programBanner && (
                                        <InputFiles
                                            type='image'
                                            inputNotes={['Thêm banner', 'Định dạng tệp PNG, JPEG, JPG']}
                                            accept={['.png', '.jpeg', '.jpg']}
                                            handleUpload={handleUploadBannerProgram}
                                            name='image'
                                            control={control}
                                        />
                                    )}
                                </div>
                                {/* {errors['images']?.message && <FormHelperText error>{errors['images']?.message}</FormHelperText>} */}
                            </div>
                        )}
                        {isProgramEdit && (
                            <CustomTextEditor
                                value={descriptionProgram}
                                setValue={setDescriptionProgram}
                                onUploadImage={onUploadImage}
                            />
                        )}
                    </DialogContent>
                    <DialogActions className='dialog-actions'>
                        <CustomButton
                            variant='text'
                            onClick={(e) => {
                                e.preventDefault();
                                closeProgramModal();
                            }}>
                            Hủy
                        </CustomButton>
                        <CustomButton
                            variant='contained'
                            type='submit'
                            color={!isProgramEdit ? 'danger' : 'primary'}
                            // disabled={!isFormValid && isSponsorEdit}
                        >
                            {isProgramEdit ? 'Lưu thay đổi' : 'Đồng ý'}
                        </CustomButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default EditProgram;
