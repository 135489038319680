export const homeSelector = (state) => state.home;

export const authSelector = (state) => state.auth;

export const loaderSelector = (state) => state.loader;

export const consultantSelector = (state) => state.consultant;

export const companyInfoSelector = (state) => state.companyInfo;

export const programSelector = (state) => state.program;

export const newsSelector = (state) => state.news;

export const supplierSelector = (state) => state.supplier;

export const bannerSelector = (state) => state.banner;

export const catalogueSelector = (state) => state.catalogue;

export const checkInSelector = (state) => state.checkIn;

export const customerSelector = (state) => state.customer;
