import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LogoutIcon from '@mui/icons-material/Logout';
import {authSelector, homeSelector} from 'app/selectors';
import {Buildings} from 'assets';
import CustomToast from 'components/CustomToast';
import {pagePath} from 'configuration/routeConfig';
import {logout} from 'features/Auth/authSlice';
import {useEffect, useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import propsProvider from './homePropsProvider';
import {handleChangActivePage} from './homeSlice';
import HomeMainView from './template/HomeMainView';

const HomeContainer = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const {pathname} = location;
    const home = useSelector(homeSelector);
    const auth = useSelector(authSelector);
    const {paginationData, activePage} = home;

    const [sidebarList, setSidebarList] = useState([
        {
            title: 'Thiết lập thông tin',
            items: [
                {
                    title: 'Thông tin',
                    icon: <CalendarMonthIcon />,
                    to: pagePath.companyInfoUrl,
                    isActive: true,
                    items: [
                        {
                            title: 'Sửa thông tin',
                            to: pagePath.updateCompanyInfoUrl
                        }
                    ]
                },
                {
                    title: 'Chương trình',
                    icon: <CalendarMonthIcon />,
                    to: pagePath.programUrl,
                    isActive: false,
                    items: [
                        {
                            title: 'Thêm mới',
                            to: pagePath.programCreateUrl
                        }
                    ]
                },
                {
                    title: 'Tin Tức',
                    icon: <CalendarMonthIcon />,
                    to: pagePath.newsUrl,
                    isActive: false,
                    items: [
                        {
                            title: 'Thêm mới',
                            to: pagePath.newsCreateUrl
                        }
                    ]
                },
                {
                    title: 'Nhà cung cấp',
                    icon: <CalendarMonthIcon />,
                    to: pagePath.supplierUrl,
                    isActive: false,
                    items: [
                        {
                            title: 'Thêm mới',
                            to: pagePath.supplierCreateUrl
                        }
                    ]
                },
                {
                    title: 'Banner',
                    icon: <Buildings />,
                    to: pagePath.bannerUrl,
                    isActive: false,
                    items: [
                        {
                            title: 'Thêm mới',
                            to: pagePath.bannerCreateUrl
                        }
                    ]
                },
                {
                    title: 'Catalogue',
                    icon: <Buildings />,
                    to: pagePath.catalogueUrl,
                    isActive: false,
                    items: [
                        {
                            title: 'Thêm mới',
                            to: pagePath.catalogueCreateUrl
                        }
                    ]
                },
                {
                    title: 'Chuyên gia',
                    icon: <CalendarMonthIcon />,
                    to: pagePath.consultantUrl,
                    isActive: false,
                    items: [
                        {
                            title: 'Thêm mới',
                            to: pagePath.consultantCreateUrl
                        }
                    ]
                },
                {
                    title: 'Danh sách check in',
                    icon: <CalendarMonthIcon />,
                    to: pagePath.checkInURL,
                    isActive: false,
                    items: [
                        {
                            title: 'Danh sách check in',
                            to: pagePath.updateCompanyInfoUrl
                        }
                    ]
                },
                {
                    title: 'Danh sách khách hàng',
                    icon: <CalendarMonthIcon />,
                    to: pagePath.customerURL,
                    isActive: false,
                    items: [
                        {
                            title: 'Danh sách check in',
                            to: pagePath.updateCompanyInfoUrl
                        }
                    ]
                }
            ]
        },
        {
            title: 'Tài khoản',
            items: [{title: 'Đăng xuất', icon: <LogoutIcon />, to: pagePath.logoutUrl}]
        }
    ]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const navigateTo = (path) => {
        try {
            if (path === pagePath.logoutUrl) {
                dispatch(logout()).then((res) => history.push(pagePath.authLoginUrl));
            } else {
                history.push(path);
            }
        } catch (e) {
            CustomToast(false, 'Có lỗi xảy ra');
        }
    };

    const checkIsLogin = () => {
        const {token} = auth;
        if (!token) {
            history.push(pagePath.authLoginUrl);
        }
    };

    useEffect(() => {
        checkIsLogin();
    }, []);

    useLayoutEffect(() => {
        const newBreadcrumbs = [];
        const newSidebarList = [...sidebarList];

        newSidebarList[0].items = newSidebarList[0].items.map((item, idx) => {
            const isItemActive = pathname.includes(item.to);
            item.isActive = isItemActive;

            if (isItemActive) {
                dispatch(handleChangActivePage({activePage: idx}));
                newBreadcrumbs.push({title: item.title, to: item.to});
                item.items.forEach((subItem) => {
                    if (pathname.includes(subItem.to)) {
                        newBreadcrumbs.push({title: subItem.title, to: subItem.to});
                    }
                });
            }

            return item;
        });

        setSidebarList(newSidebarList);
        setBreadcrumbs(newBreadcrumbs);
    }, [pathname]);

    const homeContainerProps = {
        sidebarList,
        navigateTo,
        breadcrumbs,
        ...props,
        activePage
    };

    return <HomeMainView {...propsProvider(homeContainerProps)} />;
};

export default HomeContainer;
