import {TableCell, TableRow} from '@mui/material';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import Config from 'configuration';
import React from 'react';

const CustomTableRow = (props) => {
    const {rowData, selected = false, handleSelectRow, is_checked = false, handleIsChecked} = props;
    return (
        <TableRow>
            {Object.entries(rowData).map(([key, data]) => {
                if (key === 'id')
                    return (
                        <TableCell key={key} className='table-row--top' padding='checkbox'>
                            <CustomCheckbox
                                checked={selected}
                                onChange={(e) => handleSelectRow(e, data)}
                                color='primary'
                                inputProps={{
                                    'aria-labelledby': data
                                }}
                            />
                        </TableCell>
                    );
                else if (key === 'is_checked') {
                    return (
                        <TableCell key={key} className='table-row--top' padding='checkbox'>
                            <CustomCheckbox
                                checked={is_checked}
                                onChange={(e) => handleIsChecked(e, data)}
                                color='primary'
                                inputProps={{
                                    'aria-labelledby': data
                                }}
                            />
                        </TableCell>
                    );
                }
                return (
                    data?.isshow && (
                        <TableCell className='table-custom_cell' {...data} key={key}>
                            {!(key === 'image') ? (
                                <span className='cell-value'>{data.value}</span>
                            ) : (
                                <img src={`${Config.apiConfig.endPoint}${data.value}`} alt={`${data.value}`} />
                            )}
                        </TableCell>
                    )
                );
            })}
        </TableRow>
    );
};

export default CustomTableRow;
