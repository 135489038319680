import { authSelector } from 'app/selectors';
import { pagePath } from 'configuration/routeConfig';
import jwtDecode from 'jwt-decode';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router';

const CustomRoute = ({ isPrivate = false, ...rest }) => {
    const auth = useSelector(authSelector);
    const { token } = auth;

    const location = useLocation();

    return !isPrivate || (token && jwtDecode(token)) ? (
        <Route {...rest} />
    ) : (
        <Redirect to={{ pathname: pagePath.authLoginUrl, state: { from: location } }} />
    );
};

export default CustomRoute;
