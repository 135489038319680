import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import eventApi from 'api/eventApi';
import serviceRequest from 'app/serviceRequest';

export const getEventList = createAsyncThunk('program/getEventList', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: eventApi.getEventList,
        payload: data
    });
});

export const addEvent = createAsyncThunk('program/addEvent', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: eventApi.addEvent,
        payload: data
    });
});

export const updateEvent = createAsyncThunk('program/updateEvent', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: eventApi.updateEvent,
        payload: data
    });
});

export const deleteEvent = createAsyncThunk('program/deleteEvent', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: eventApi.deleteEvent,
        payload: id
    });
});

export const uploadProgramPdf = createAsyncThunk('program/uploadProgramPdf', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: eventApi.uploadFileEvent,
        payload: data
    });
});
export const uploadBannerProgram = createAsyncThunk('program/uploadBannerProgram', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: eventApi.uploadBannerProgram,
        payload: data
    });
});
const program = createSlice({
    name: 'program',
    initialState: {},
    reducers: {},
    extraReducers: (builders) => {
        builders.addCase(getEventList.fulfilled, (state, action) => {
            // console.log('action', action);
            state.programList = action?.payload?.data?.collection;
        });
    }
});

const {reducer, actions} = program;
export default reducer;
