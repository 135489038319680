import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl} from '@mui/material';
import {Close} from 'assets';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomTextEditor from 'components/CustomTextEditor';
import GroupInput from 'components/GroupInput/GroupInput';
import {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';
const defaultValues = {
    description: '',
    introduce: ''
};

const UpdateCompanyInfo = (props) => {
    const {
        handleSubmitInvalid,
        isOpenCompanyInfoModal,
        isCompanyInfoEdit,
        selectedCompanyInfo,
        handleEditCompanyInfo,
        closeCompanyModal,
        setDescriptionCompanyInfo,
        descriptionCompanyInfo,
        onUploadImage
    } = props;
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch,
        reset,
        setValue
    } = useForm({defaultValues});

    const companyInfoInputs = [
        {
            label: 'Giới thiệu',
            placeholder: 'Nhập giới thiệu',
            isRequired: true,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'introduce'
        }
        // {
        //     label: 'Chi tiết',
        //     placeholder: 'Nhập chi tiết',
        //     isRequired: true,
        //     rules: { required: { value: true, messages: 'trường này là bắt buộc' } },
        //     type: 'text',
        //     name: 'description'
        // },
    ];

    useEffect(() => {
        reset();
    }, [isOpenCompanyInfoModal]);

    useEffect(() => {
        if (isCompanyInfoEdit && selectedCompanyInfo) {
            setValue('introduce', selectedCompanyInfo?.introduce);
            setValue('description', selectedCompanyInfo?.description);
        }
    }, [isOpenCompanyInfoModal, selectedCompanyInfo]);

    return (
        <>
            <>
                <Dialog
                    maxWidth={isCompanyInfoEdit ? 'sm' : 'xs'}
                    fullWidth={true}
                    open={isOpenCompanyInfoModal}
                    onClose={closeCompanyModal}
                    aria-labelledby='voucher-modal'
                    aria-describedby='apply-voucher-to-get-discount'
                    className='dialog'>
                    <form
                        noValidate
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(handleEditCompanyInfo, handleSubmitInvalid)();
                        }}>
                        <DialogTitle className='dialog-title' id='voucher-modal'>
                            <span>{isCompanyInfoEdit ? 'Chỉnh sửa thông tin' : 'Xác nhận'}</span>
                            <button className='dialog-title__close'>
                                <Close
                                    className='cursor-pointer'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        closeCompanyModal();
                                    }}
                                />
                            </button>
                        </DialogTitle>

                        <DialogContent className='dialog-content' style={{padding: '32px 24px'}}>
                            <div className={`${isCompanyInfoEdit ? 'add-program' : ''} `}>
                                {!isCompanyInfoEdit
                                    ? 'Bạn có chắc muốn xóa chuyên gia này?'
                                    : companyInfoInputs.map((input, idx) => {
                                          const {type, rules, groupInputData, label, name, ...restProps} = input;

                                          return (
                                              <FormControl key={idx}>
                                                  {type !== 'group' ? (
                                                      <Controller
                                                          name={name}
                                                          control={control}
                                                          rules={rules}
                                                          key={idx}
                                                          render={({field: {value, ...restField}}) =>
                                                              type === 'date' ? (
                                                                  <CustomDatePicker
                                                                      {...restField}
                                                                      {...restProps}
                                                                      label={label}
                                                                      value={value}
                                                                  />
                                                              ) : (
                                                                  <CustomInput
                                                                      label={label}
                                                                      value={value}
                                                                      {...restField}
                                                                      {...restProps}
                                                                  />
                                                              )
                                                          }
                                                      />
                                                  ) : (
                                                      <GroupInput
                                                          control={control}
                                                          label={label}
                                                          key={idx}
                                                          groupInputData={groupInputData}
                                                      />
                                                  )}
                                              </FormControl>
                                          );
                                      })}
                                {isCompanyInfoEdit && (
                                    <CustomTextEditor
                                        value={descriptionCompanyInfo}
                                        setValue={setDescriptionCompanyInfo}
                                        onUploadImage={onUploadImage}
                                    />
                                )}
                            </div>
                        </DialogContent>
                        <DialogActions className='dialog-actions'>
                            <CustomButton
                                variant='text'
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeCompanyModal();
                                }}>
                                Hủy
                            </CustomButton>
                            <CustomButton
                                variant='contained'
                                type='submit'
                                color={'primary'}
                                // disabled={!isFormValid && isSponsorEdit}
                            >
                                Lưu thay đổi
                            </CustomButton>
                        </DialogActions>
                    </form>
                </Dialog>
            </>
        </>
    );
};

export default UpdateCompanyInfo;
