import { Box, InputAdornment, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { forwardRef, useState } from 'react';

const CustomInput = forwardRef((props, ref) => {
    const {
        startIcon = null,
        endIcon = null,
        valid = true,
        isHavingPasswordIcon = false,
        inputShrink = true,
        novalidate = false,
        size = 'medium',
        isGapEndIcon = false,
        inputClassName = '',
        minRows = 3,
        hideFieldset = false,
        isRequired = false,
        value,
        ...validTextAttributes
    } = props;
    const { type, className, label, disabled = false } = validTextAttributes;
    const [isShowPassword, setIsShowPassword] = useState(false);
    const requiredLabel = isRequired ? (
        <span>
            {label} <span className='text-required'>*</span>
        </span>
    ) : (
        label
    );

    return (
        <TextField
            {...Object.assign({}, validTextAttributes, { valid: undefined })}
            className={`customInput ${className} ${novalidate ? '' : valid ? 'customInput-valid' : 'customInput-invalid'
                } ${startIcon ? 'customInput-startIcon' : ''} ${inputClassName} ${disabled ? 'customInput-disabled' : ''} ${hideFieldset ? 'customInput--hideFieldset' : ''
                }`}
            ref={ref}
            value={value}
            variant='outlined'
            size={size}
            type={isShowPassword ? 'text' : type}
            fullWidth
            // error={!valid}
            minRows={minRows}
            disabled={disabled}
            multiline={type === 'textarea'}
            label={requiredLabel}
            InputLabelProps={{
                shrink: inputShrink
            }}
            InputProps={{
                startAdornment: startIcon && <InputAdornment position='start'>{startIcon}</InputAdornment>,
                endAdornment: (endIcon || isHavingPasswordIcon) && (
                    <InputAdornment position='end' sx={{ gap: isGapEndIcon ? '8px' : 0 }}>
                        {isHavingPasswordIcon ? (
                            <Box className="svg-gray" sx={{ cursor: 'pointer' }} onClick={() => setIsShowPassword(!isShowPassword)}>
                                {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </Box>
                        ) : (
                            endIcon
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
});

export default CustomInput;
