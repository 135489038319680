import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import {Close} from 'assets';
import {Controller, useForm} from 'react-hook-form';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomDatePicker from 'components/CustomDatePicker';
import GroupInput from 'components/GroupInput/GroupInput';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomTextEditor from 'components/CustomTextEditor';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import InputFiles from 'components/InputFiles/InputFiles';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import PreviewExcel from 'components/PreviewExcel';
const defaultValues = {
    tags: ''
};

const ImportCustomer = (props) => {
    const {
        handleSubmitInvalid,
        isOpenImportCustomerModal,
        handleUploadExcel,
        onDeleteExcel,
        importExcelData,
        onSubmitImportDataExcel,
        closeImportCustomer
    } = props;
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch,
        reset,
        setValue
    } = useForm({defaultValues});

    useEffect(() => {
        reset();
    }, []);

    return (
        <>
            <Dialog
                maxWidth={'xs'}
                fullWidth={true}
                open={isOpenImportCustomerModal}
                onClose={closeImportCustomer}
                aria-labelledby='voucher-modal'
                aria-describedby='apply-voucher-to-get-discount'
                className='dialog'>
                <form
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(onSubmitImportDataExcel, handleSubmitInvalid)();
                    }}>
                    <DialogTitle className='dialog-title' id='voucher-modal'>
                        <span>Thêm khách hàng</span>
                        <button className='dialog-title__close'>
                            <Close
                                className='cursor-pointer'
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeImportCustomer();
                                }}
                            />
                        </button>
                    </DialogTitle>

                    <DialogContent className='dialog-content' style={{padding: '32px 24px'}}>
                        <div className='add-program'>
                            {importExcelData ? (
                                <PreviewExcel onDeleteExcel={onDeleteExcel} />
                            ) : (
                                <InputFiles
                                    type='file'
                                    inputNotes={['Định dạng tệp excel']}
                                    accept={['.xlsx']}
                                    handleUpload={handleUploadExcel}
                                    name='file'
                                    control={control}
                                />
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions className='dialog-actions'>
                        <CustomButton
                            variant='text'
                            onClick={(e) => {
                                e.preventDefault();
                                closeImportCustomer();
                            }}>
                            Hủy
                        </CustomButton>
                        <CustomButton
                            variant='contained'
                            type='submit'
                            color={'primary'}
                            // disabled={!isFormValid && isSponsorEdit}
                        >
                            {'Đồng ý'}
                        </CustomButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default ImportCustomer;
