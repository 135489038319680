import {Logo, Notification} from 'assets';
import React from 'react';

const HeaderMainView = (props) => {
    const {} = props;

    return (
        <header className='header'>
            <div className='header-logo'>
                <img src={Logo} alt='' style={{width: '115px', height: '60px', objectFit: 'contain'}} />
            </div>
            <nav className='header-container'>
                <div className='header-container__noti' data-exist='true'>
                    <Notification className='cursor-pointer' />
                </div>
                <div className='header-container__avatar cursor-pointer'>
                    <img
                        src='https://plus.unsplash.com/premium_photo-1669725687136-f3441a5207ef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'
                        alt='avatar'
                    />
                </div>
            </nav>
        </header>
    );
};

export default HeaderMainView;
