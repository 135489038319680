import {pagePath} from 'configuration/routeConfig';
import Home from 'features/Home';
import AddCatalogue from './AddCatalogue';
import CatalogueList from './CatalogueList';
import EditCatalogue from './EditCatalogue';
import AddNewPressRelease from './AddRelease';
import AddNewDiagram from './AddDiagram';
const CatalogueMainView = (props) => {
    const {catalogueListProps, history, addCatalogueProps, editCatalogueProps, addDiagramProps, addReleaseProps} =
        props;
    let CatalogueComponent = null;
    switch (history.location.pathname) {
        case pagePath.catalogueUrl:
            CatalogueComponent = <CatalogueList {...catalogueListProps} />;
            break;
        case pagePath.catalogueCreateUrl:
            CatalogueComponent = <AddCatalogue {...addCatalogueProps} />;
            break;
        case pagePath.releaseCreateUrl:
            CatalogueComponent = <AddNewPressRelease {...addReleaseProps} />;
            break;
        case pagePath.diagramCreateUrl:
            CatalogueComponent = <AddNewDiagram {...addDiagramProps} />;
            break;
        default:
            CatalogueComponent = <CatalogueList {...catalogueListProps} />;
            break;
    }
    return (
        <Home>
            <EditCatalogue {...editCatalogueProps} />
            {CatalogueComponent}
        </Home>
    );
};

export default CatalogueMainView;
