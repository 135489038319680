import {pagePath} from 'configuration/routeConfig';
import Home from 'features/Home';
import BannerList from './BannerList';
import AddBanner from './AddBanner';
import EditBanner from './EditBanner';
const BannerMainView = (props) => {
    const {bannerListProps, history, addBannerProps, editBannerProps} = props;
    let BannerComponent = null;
    switch (history.location.pathname) {
        case pagePath.bannerUrl:
            BannerComponent = <BannerList {...bannerListProps} />;
            break;
        case pagePath.bannerCreateUrl:
            BannerComponent = <AddBanner {...addBannerProps} />;
            break;
        default:
            BannerComponent = <BannerList {...bannerListProps} />;
            break;
    }
    return (
        <Home>
            <EditBanner {...editBannerProps} />
            {BannerComponent}
        </Home>
    );
};

export default BannerMainView;
