import {authSelector, homeSelector, supplierSelector} from 'app/selectors';
import {useSelector} from 'react-redux';
import SupplierContainer from './supplierContainer';
const Supplier = (props) => {
    const home = useSelector(homeSelector);
    const auth = useSelector(authSelector);
    const supplierSelect = useSelector(supplierSelector);

    const {supplierList = []} = supplierSelect;
    const {userLoginInfo} = auth;
    const supplierContainerProps = {
        home,
        userLoginInfo,
        supplierList
    };

    return <SupplierContainer {...supplierContainerProps} />;
};

export default Supplier;
