import { pagePath } from 'configuration/routeConfig';
import React from 'react';
import { useLocation } from 'react-router-dom';
import AuthLayout from './subViews/AuthLayout';
import AuthLogin from './subViews/AuthLogin';
import AuthEnterEmail from './subViews/AuthEnterEmail';
import AuthSetPassword from './subViews/AuthSetPassword';
import DeleteAccount from './subViews/DeleteAccount';

const AuthMainView = (props) => {
    const { authLoginProps, setPasswordProps, enterEmailProps, deleteAccountProps } = props;
    const location = useLocation();
    const { pathname } = location;

    let authComponent = null;

    switch (pathname) {
        case pagePath.authLoginUrl:
            authComponent = <AuthLogin {...authLoginProps} />;
            break;
        case pagePath.authForgotPasswordUrl:
            authComponent = <AuthEnterEmail {...enterEmailProps} />;
            break;
        case pagePath.authSetPasswordUrl:
            authComponent = <AuthSetPassword {...setPasswordProps} />;
            break;
        case pagePath.deleteAccount:
            authComponent = <DeleteAccount {...deleteAccountProps} />;
            break;
        default:
            break;
    }

    return <AuthLayout>{authComponent}</AuthLayout>;
};

export default AuthMainView;
