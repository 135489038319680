import {authBottom, authFrame, authTopLeft, hospitalLogo} from 'assets';
import React from 'react';

const AuthLayout = (props) => {
    const {children} = props;

    return (
        <div className='auth'>
            <div className='auth-layout--topLeft'>
                <img src={authTopLeft} alt='' />
            </div>
            <div className='auth-layout--bottom'>
                <img src={authBottom} alt='' />
            </div>

            <main className='row m-0 h-100'>
                <div className='col-6 p-0 h-100 d-flex justify-content-center'>
                    <div className='auth-content'>
                        <div className='auth-content__intro'>
                            <img src={hospitalLogo} alt='' />
                            {/* <p className='auth-title mb-1'>HỘI NGHỊ KHOA HỌC CÔNG NGHỆ BỆNH VIỆN BÌNH DÂN LẦN THỨ 20</p> */}
                            {/* <p className='auth-subTitle m-0'>20 SCIENTIFIC CONGRESS OF BINH DAN HOSPITAL</p> */}
                        </div>
                        <section className='auth-form'>{children}</section>
                    </div>
                </div>
                <div className='col-6 p-0'>
                    <div className='auth-layout--right' style={{display: 'flex', alignItems: 'center'}}>
                        <img src={authFrame} alt='' />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AuthLayout;
