import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';
const consultantApi = {
    getListConsultant: (params) => {
        const url = '/api/consultant/getAll';
        return axiosClient.get(url, { params });
    },
    addConsultant: (data) => {
        const url = `/api/consultant/post`;
        return axiosClient.post(url, data);
    },

    deleteConsultant: (id) => {
        const url = `/api/consultant/delete/${id}`;
        return axiosClient.delete(url);
    },
    updateConsultant: (data) => {
        console.log('Updating', data)
        const { id, body } = data;
        const url = `/api/consultant/put/${id}`;
        return axiosClient.put(url, body);
    },
    uploadConsultantAvatar: (data) => {
        const { id, body } = data;
        const url = `/api/consultant/${id}/uploadConsultantAvatar`;
        return axiosFormData.put(url, body);
    }
};

export default consultantApi;
