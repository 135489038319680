import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {calcTotalPage} from 'utils/calcTotalPage';
import propsProvider from './consultantPropsProvider';
import {
    addConsultant,
    deleteConsultant,
    getListConsultant,
    updateConsultant,
    uploadConsultantAvatar
} from './consultantSlice';
import ConsultantMainView from './template/ConsultantMainView';
import {pagePath} from 'configuration/routeConfig';
import {logout} from 'features/Auth/authSlice';
import CustomToast from 'components/CustomToast';
import Config from 'configuration';
import {handleChangePage, uploadImage, handleChangePageSize, handleChangeTotalPage} from 'features/Home/homeSlice';
import {getBase64} from 'utils/getBase64';

const ConsultantContainer = (props) => {
    const {home, consultants, userLoginInfo} = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const {paginationData, activePage} = home;
    const [isConsultantEdit, setIsConsultantEdit] = useState(false);
    const [selectedConsultant, setSelectedConsultant] = useState({});
    const [searchConsultantValue, setSearchConsultantValue] = useState('');
    const [checkedData, setCheckedData] = useState([]);
    const [descriptionConsultant, setDescriptionConsultant] = useState();
    const [avatarConsultant, setAvatarConsultant] = useState();
    const [isOpenConsultantModal, setOpenConsultantModal] = useState();

    const navigateTo = (path) => {
        try {
            if (path === pagePath.logoutUrl) {
                dispatch(logout()).then((res) => history.push(pagePath.authLoginUrl));
            } else {
                history.push(path);
            }
        } catch (e) {
            CustomToast(false, 'Có lỗi xảy ra');
        }
    };

    const onLoadConsultant = async () => {
        const res = await dispatch(
            getListConsultant({
                Filters: searchConsultantValue ? `full_name@=${searchConsultantValue}` : '',
                Sorts: '-created_at',
                Page: paginationData[activePage]?.page ?? 1,
                PageSize: paginationData[activePage]?.pageSize ?? 10
            })
        ).unwrap();

        if (res?.success) {
            const {data} = res;

            setTotalPage(data?.total, data?.pageSize);
        }
    };
    const setPage = (value) => {
        dispatch(handleChangePage({activePage: activePage, page: value}));
    };

    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage: activePage, pageSize: value}));
        setPage(1);
    };

    const setTotalPage = (count, pageSize) => {
        const value = calcTotalPage(count, pageSize);
        // console.log('value', value);
        dispatch(handleChangeTotalPage({activePage: activePage, totalPage: value}));
    };
    const handleSearchConsultant = () => {
        onLoadConsultant();
    };
    const handleSelectRow = (event, id) => {};
    const handleDeleteSelectedRows = async () => {};
    const handleDeleteConsultant = async () => {
        const res = await dispatch(deleteConsultant(selectedConsultant?.id)).unwrap();

        if (res) {
            CustomToast(res, 'Xóa chuyên gia thành công');
            onLoadConsultant();
            closeConsultantModal();
        } else {
            CustomToast(false, 'Xóa chuyên gia thất bại ');
        }
    };
    const handleAddConsultant = async (data) => {
        const {full_name, degree, work_unit, cell_phone, email} = data;
        const payload = {
            full_name: full_name,
            degree: degree,
            work_unit: work_unit,
            cell_phone: cell_phone,
            email: email,
            capacity_at: descriptionConsultant ?? ''
        };
        const res = await dispatch(
            !isConsultantEdit ? addConsultant(payload) : updateConsultant({id: selectedConsultant?.id, body: payload})
        ).unwrap();
        const {success} = res;
        if (success) {
            if (avatarConsultant) {
                let fileFormData = new FormData();
                const file = avatarConsultant.file;
                fileFormData.append('file', file);
                dispatch(
                    uploadConsultantAvatar({
                        id: !isConsultantEdit ? res?.data?.id : selectedConsultant?.id,
                        body: fileFormData
                    })
                ).then((res) => console.log('uploadConsultantAvatar', res));

                // handleAddPdfProgram(res.data.id);
            }
            CustomToast('success', 'Thêm thành công');
            if (!isConsultantEdit) navigateTo(pagePath.consultantUrl);
            else {
                onLoadConsultant();
                closeConsultantModal();
            }
        } else {
            CustomToast(success, `${isConsultantEdit ? 'Chỉnh sửa' : 'Thêm'} chuyên gia không thành công`);
        }
    };
    const handleDeleteTempAvatarConsultant = () => {
        setAvatarConsultant();
    };
    const handleSubmitInvalid = (_, event) => {
        console.log('submit invalid');
        // event.target.classList.add('wasvalidated');
    };
    const handleUploadAvatarConsultant = async (e) => {
        const file = e.target.files[0];
        const {size} = file;

        if (size > 5 * 1024 * 1024) {
            CustomToast(false, 'Tệp vượt quá giới hạn dung lượng cho phép');
            return;
        }

        const previewUrl = await getBase64(file);
        setAvatarConsultant({url: previewUrl, file});
    };

    const openConsultantModal = (consultant, isEdit) => {
        setOpenConsultantModal(true);
        setIsConsultantEdit(isEdit);
        setSelectedConsultant(consultant);
        setDescriptionConsultant(consultant?.capacity_at ?? '');
        setAvatarConsultant(consultant?.avatar ? {url: Config.apiConfig.endPoint + consultant?.avatar} : null);
    };
    const closeConsultantModal = () => {
        setSelectedConsultant({});
        setOpenConsultantModal(false);
        setIsConsultantEdit(false);
    };
    const onUploadImage = async (image) => {
        if (userLoginInfo?.id) {
            const res = await dispatch(uploadImage({id: userLoginInfo?.id, body: image}));

            if (res?.payload?.success) {
                return res?.payload?.data?.physical_path;
            }
        }
    };

    useEffect(() => {
        onLoadConsultant();
    }, [paginationData]);

    const consultantContainerProps = {
        history,
        consultants,
        paginationData,
        activePage: activePage,
        navigateTo,
        setPage,
        setPageSize,
        searchConsultantValue,
        descriptionConsultant,
        avatarConsultant,
        isConsultantEdit,
        isOpenConsultantModal,
        selectedConsultant,
        closeConsultantModal,
        handleDeleteConsultant,
        setDescriptionConsultant,
        setSearchConsultantValue,
        handleSearchConsultant,
        handleSelectRow,
        checkedData,
        handleDeleteSelectedRows,
        openConsultantModal,
        handleAddConsultant,
        onUploadImage,
        handleDeleteTempAvatarConsultant,
        handleSubmitInvalid,
        handleUploadAvatarConsultant
    };

    return <ConsultantMainView {...propsProvider(consultantContainerProps)} />;
};

export default ConsultantContainer;
