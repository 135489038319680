import {authSelector, catalogueSelector, homeSelector} from 'app/selectors';
import {useSelector} from 'react-redux';
import CatalogueContainer from './catalogueContainer';
const Catalogue = (props) => {
    const home = useSelector(homeSelector);
    const auth = useSelector(authSelector);
    const catalogueSelect = useSelector(catalogueSelector);

    const {catalogueList = []} = catalogueSelect;
    const {userLoginInfo} = auth;
    const catalogueContainerProps = {
        home,
        userLoginInfo,
        catalogueList
    };

    return <CatalogueContainer {...catalogueContainerProps} />;
};

export default Catalogue;
