import {FormControl, FormHelperText} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomTextEditor from 'components/CustomTextEditor';
import GroupInput from 'components/GroupInput/GroupInput';
import InputFiles from 'components/InputFiles/InputFiles';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import {pagePath} from 'configuration/routeConfig';
import {useRef} from 'react';
import {Controller, useForm} from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';
const defaultValues = {
    title: '',
    description: '',
    location: '',
    email: '',
    cell_phone: ''
};

const AddNews = (props) => {
    const {
        handleSubmitInvalid,
        handleAddNews,
        navigateTo,
        handleUploadImageList,
        handleDeleteImageList,
        detailImageList,
        descriptionNews,
        setDescriptionNews,
        onUploadImage
    } = props;
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch
    } = useForm({defaultValues});
    const watchAllFields = watch();
    const editor = useRef(null);

    // const isFormValid = Object.values(watchAllFields).every((value) => {
    //     if (value && typeof value === 'object') {
    //         return new Date(value).toISOString().length > 0;
    //     }

    //     return value?.length > 0;
    // });

    const addNewsInputs = [
        {
            label: 'Tiêu đề tin tức',
            placeholder: 'Nhập tiêu đề tin tức',
            isRequired: false,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'title'
        },
        {
            label: 'Ngày',
            placeholder: 'Ngày',
            isRequired: true,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'date',
            name: 'fromTime'
        },
        // {
        //     label: (
        //         <span>
        //             Thời gian <span className='text-required'>*</span>
        //         </span>
        //     ),
        //     type: 'group',
        //     control,
        //     groupInputData: [
        //         {
        //             placeholder: 'Từ',
        //             isRequired: true,
        //             rules: { required: { value: true, messages: 'trường này là bắt buộc' } },
        //             type: 'date',
        //             name: 'fromTime'
        //         },
        //         // {
        //         //     placeholder: 'Đến',
        //         //     isRequired: true,
        //         //     rules: { required: { value: true, messages: 'trường này là bắt buộc' } },
        //         //     type: 'date',
        //         //     name: 'toTime'
        //         // }
        //     ]
        // },
        {
            label: 'Địa điểm',
            placeholder: 'Nhập địa điểm',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'location'
        }
        // {
        //     label: 'Email',
        //     placeholder: 'Nhập email',
        //     isRequired: false,
        //     rules: { required: { value: true, messages: 'trường này là bắt buộc' } },
        //     type: 'text',
        //     name: 'email'
        // },
        // {
        //     label: 'Điện thoại di động',
        //     placeholder: 'Nhập điện thoại',
        //     isRequired: false,
        //     rules: { required: { value: true, messages: 'trường này là bắt buộc' } },
        //     type: 'text',
        //     name: 'cell_phone'
        // },
    ];
    return (
        <CustomCard title='Thông tin tin tức'>
            <form
                className='mt-2 add-news'
                method='POST'
                action='#'
                noValidate
                onSubmit={handleSubmit(handleAddNews, handleSubmitInvalid)}>
                <div style={{width: '100%'}}>
                    <div className='d-flex align-items-center flex-wrap gap-3'>
                        {detailImageList.map((image, idx) => {
                            return (
                                <PreviewImage
                                    key={idx}
                                    image={image?.url}
                                    onDeleteImage={() => handleDeleteImageList(image)}
                                />
                            );
                        })}
                        {detailImageList && detailImageList.length !== 5 && (
                            <InputFiles
                                type='image'
                                inputNotes={['Thêm hình ảnh', 'Định dạng tệp PNG, JPEG, JPG', 'Kích thước: tối đa 5MB']}
                                accept={['.png', '.jpeg', '.jpg']}
                                handleUpload={handleUploadImageList}
                                name='image'
                                control={control}
                            />
                        )}
                    </div>
                    {errors['images']?.message && <FormHelperText error>{errors['images']?.message}</FormHelperText>}
                </div>
                {addNewsInputs.map((input, idx) => {
                    const {type, rules, groupInputData, label, name, ...restProps} = input;
                    return (
                        <FormControl key={idx}>
                            {type !== 'group' ? (
                                <Controller
                                    name={name}
                                    control={control}
                                    rules={rules}
                                    key={idx}
                                    render={({field}) =>
                                        type === 'date' ? (
                                            <CustomDatePicker {...field} {...restProps} label={label} />
                                        ) : (
                                            <CustomInput label={label} {...field} {...restProps} type={type} />
                                        )
                                    }
                                />
                            ) : (
                                <GroupInput control={control} label={label} key={idx} groupInputData={groupInputData} />
                            )}
                        </FormControl>
                    );
                })}
                {/* <ReactQuill theme="snow" value={descriptionNews} onChange={setDescriptionNews} style={{ width: '100%' }} /> */}
                <CustomTextEditor value={descriptionNews} setValue={setDescriptionNews} onUploadImage={onUploadImage} />

                <br />
                <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                    <CustomButton variant='outlined' onClick={() => navigateTo(pagePath.newsUrl)} type='button'>
                        Hủy
                    </CustomButton>
                    <CustomButton variant='contained' type='submit'>
                        Lưu
                    </CustomButton>
                </div>
            </form>
        </CustomCard>
    );
};

export default AddNews;
