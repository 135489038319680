import CustomToast from 'components/CustomToast';
import Config from 'configuration';
import {pagePath} from 'configuration/routeConfig';
import {logout} from 'features/Auth/authSlice';
import {handleChangePage, handleChangePageSize, handleChangeTotalPage, uploadImage} from 'features/Home/homeSlice';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {calcTotalPage} from 'utils/calcTotalPage';
import {getBase64} from 'utils/getBase64';
import propsProvider from './supplierPropsProvider';
import {
    addProduct,
    addSupplier,
    deleteProduct,
    deleteSupplier,
    getProductByOrganizationId,
    getSupplierList,
    updateProduct,
    updateSupplier,
    uploadProductImage,
    uploadSupplierBanner,
    uploadSupplierLogo
} from './supplierSlice';
import SupplierMainView from './template/SupplierMainView';

const initialFieldProductSupplier = {
    isEdit: true,
    description: '',
    imageProduct: null,
    url: null,
    info: [
        {
            label: 'Tên sản phẩm',
            placeholder: 'Nhập tên sản phẩm',
            isRequired: true,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'nameProduct0'
        },
        {
            label: 'Giá',
            placeholder: 'Nhập giá',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này không bắt buộc'}},
            type: 'text',
            name: 'priceProduct0'
        }
    ]
};

const SupplierContainer = (props) => {
    const {home, userLoginInfo, supplierList} = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const {paginationData, activePage} = home;

    const [supplierDetail, setSupplierDetail] = useState({});
    const [isOpenSupplierModal, setOpenSupplierModal] = useState(false);
    const [isSupplierEdit, setIsSupplierEdit] = useState(false);
    const [isProductEdit, setIsProductEdit] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState({});
    const [selectedProduct, setSelectedProduct] = useState({});
    const [searchSupplierValue, setSearchSupplierValue] = useState('');
    const [imageProduct, setImageProduct] = useState({});
    const [descriptionSupplier, setDescriptionSupplier] = useState('');
    const [descriptionProduct, setDescriptionProduct] = useState('');

    const [moreProduct, setMoreProduct] = useState([]);
    const [productSupplierInfo, setProductSupplierInfo] = useState([]);
    const [checkedData, setCheckedData] = useState([]);
    const [detailImageList, setDetailImageList] = useState([]);
    const [detailImage, setDetailImage] = useState({});

    const navigateTo = (path) => {
        try {
            if (path === pagePath.logoutUrl) {
                dispatch(logout()).then((res) => history.push(pagePath.authLoginUrl));
            } else {
                history.push(path);
            }
        } catch (e) {
            CustomToast(false, 'Có lỗi xảy ra');
        }
    };

    const setPage = (value) => {
        dispatch(handleChangePage({activePage: activePage, page: value}));
    };

    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage: activePage, pageSize: value}));
        setPage(1);
    };

    const setTotalPage = (count, pageSize) => {
        const value = calcTotalPage(count, pageSize);
        // console.log('value', value);
        dispatch(handleChangeTotalPage({activePage: activePage, totalPage: value}));
    };

    const handleSelectRow = (event, id) => {};
    const handleDeleteSelectedRows = async () => {};

    const handleSubmitInvalid = (_, event) => {
        console.log('submit invalid');
        // event.target.classList.add('wasvalidated');
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            let newSelected = [];
            // if (activePage === 0) {
            //     newSelected = programList.map((data) => data?.id);
            // } else {
            //     newSelected = sponsorList.map((data) => data?.id);
            // }
            setCheckedData(newSelected);
            return;
        }

        setCheckedData([]);
    };

    const handleUploadImageList = async (e) => {
        const file = e.target.files[0];
        const {size} = file;

        if (size > 5 * 1024 * 1024) {
            CustomToast(false, 'Tệp vượt quá giới hạn dung lượng cho phép');
            return;
        }

        const previewUrl = await getBase64(file);
        setDetailImageList([...detailImageList, {url: previewUrl, file}]);
    };

    const handleDeleteImageList = (e) => {
        const newFiles = detailImageList.filter((item) => item !== e);
        setDetailImageList(newFiles);
    };

    const onUploadImage = async (image) => {
        if (userLoginInfo?.id) {
            const res = await dispatch(uploadImage({id: userLoginInfo?.id, body: image}));

            if (res?.payload?.success) {
                return res?.payload?.data?.physical_path;
            }
        }
    };
    const openSupplierModal = async (supplier, isEdit) => {
        await handleGetProductBySupplierId(supplier.id);
        setIsSupplierEdit(isEdit);
        setIsProductEdit(isEdit);
        setSelectedSupplier(supplier);
        setDescriptionSupplier(supplier?.description);
        setOpenSupplierModal(true);
        if (isEdit) {
            setDetailImage(
                supplier?.avatar
                    ? {
                          url: Config.apiConfig.endPoint + supplier?.avatar
                      }
                    : null
            );
            setDetailImageList(
                supplier?.banner?.attachment?.physical_path
                    ? [
                          {
                              url: Config.apiConfig.endPoint + supplier?.banner?.attachment?.physical_path
                          }
                      ]
                    : []
            );
        } else {
            setDetailImage({});
        }
    };

    const closeSupplierModal = () => {
        setSelectedSupplier({});
        setMoreProduct([]);
        setOpenSupplierModal(false);
        setProductSupplierInfo([]);
    };

    const handleSubmitSupplier = async (data) => {
        const {
            name,
            main_customer,
            address,
            phone_number,
            video_link,
            website,
            email,
            founded_date,
            tax_code,
            link_3d_booth
        } = data;

        const payloadSupplier = {
            name: name,
            description: descriptionSupplier,
            main_customer: main_customer,
            address: address,
            phone_number: phone_number,
            video_link: video_link,
            website: website,
            email: email,
            founded_date: founded_date,
            tax_code: tax_code,
            link_3d_booth: link_3d_booth
        };
        const response = await dispatch(
            !isSupplierEdit
                ? addSupplier(payloadSupplier)
                : updateSupplier({id: selectedSupplier?.id, body: payloadSupplier})
        );
        if (response.payload.success) {
            await handleAddSupplierImage(response.payload.data.id);
            (await productSupplierInfo.length) > 0 &&
                productSupplierInfo.map((product, index) => {
                    let nameField = 'nameProduct' + index;
                    let priceField = 'priceProduct' + index;
                    const payloadProduct = {
                        name: data[nameField],
                        description: product.description,
                        price: Number.parseInt(data[priceField]) ? Number(data[priceField]) : 0,
                        organization_id: response.payload.data.id
                    };
                    // console.log(product?.isEdit, product);
                    dispatch(
                        product?.isEdit
                            ? updateProduct({id: moreProduct[index]?.id, body: payloadProduct})
                            : addProduct(payloadProduct)
                    ).then((res) => {
                        if (res?.payload?.success && product?.imageProduct) {
                            handleAddImageProduct(res?.payload?.data?.id, product?.imageProduct);
                        }
                    });
                });
            CustomToast(response.payload.success, `${isSupplierEdit ? 'Chỉnh sửa' : 'Thêm'} thành công`);
            closeSupplierModal();
            navigateTo(pagePath.supplierUrl);
            fetchSupplierList();
        } else {
            CustomToast(response.payload.success, `${isSupplierEdit ? 'Chỉnh sửa' : 'Thêm'} không thành công`);
        }
    };
    const handleAddSupplierImage = async (id) => {
        if (!id) {
            return;
        }
        let fileFormData = new FormData();
        if (detailImage && Object.keys(detailImage).length > 0) {
            fileFormData.append('file', detailImage.file);
            await dispatch(
                uploadSupplierLogo({
                    id: id,
                    file: fileFormData
                })
            );
        }
        if (detailImageList.length > 0) {
            fileFormData = new FormData();
            fileFormData.append('file', detailImageList[0].file);
            const res = await dispatch(
                uploadSupplierBanner({
                    id: id,
                    file: fileFormData
                })
            );
        }
    };
    const handleAddImageProduct = async (id, file) => {
        if (!file || !id) {
            return;
        }
        let fileFormData = new FormData();

        fileFormData.append('file', file);
        await dispatch(
            uploadProductImage({
                id: id,
                file: fileFormData
            })
        );
    };
    const handleDeleteSupplier = async () => {
        try {
            const res = await dispatch(getProductByOrganizationId(selectedSupplier?.id));
            const {collection} = res.payload.data;
            const productTmp = collection;
            productTmp.map(async (pro, idx) => {
                await dispatch(deleteProduct(pro.id));
            });
            const resp = await dispatch(deleteSupplier(selectedSupplier?.id)).unwrap();
            if (resp) {
                CustomToast(res, 'Xóa thành công');
            } else {
                CustomToast(res, 'Xóa thất bại');
            }
        } catch (error) {
            CustomToast(false, 'Xóa thất bại');
        }
        fetchSupplierList();
        closeSupplierModal();
    };
    const handleGetProductBySupplierId = async (id) => {
        try {
            const res = await dispatch(getProductByOrganizationId(id));
            if (res.payload.success) {
                const {collection} = res.payload.data;

                const productInfo = collection.map((pro, idx) => {
                    const newField = {
                        ...initialFieldProductSupplier,
                        description: pro?.description,
                        isEdit: true,
                        info: [
                            {
                                name: `nameProduct${idx}`
                            },
                            {
                                name: `priceProduct${idx}`
                            }
                        ],
                        url: Config.apiConfig.endPoint + pro?.physical_path,
                        imageProduct: null
                    };

                    return newField;
                });
                setMoreProduct(collection);
                setProductSupplierInfo(productInfo);
            }
        } catch (error) {
            console.error('Error fetching product by supplier ID:', error);
        }
    };

    const handleSearchSupplier = () => {
        fetchSupplierList();
    };
    const handleDeleteImage = (e) => {
        setDetailImage({});
    };
    const handleUploadImages = async (e) => {
        const file = e.target.files[0];
        const {size} = file;

        if (size > 5 * 1024 * 1024) {
            CustomToast(false, 'Tệp vượt quá giới hạn dung lượng cho phép');
            return;
        }

        const previewUrl = await getBase64(file);
        setDetailImage({url: previewUrl, file});
    };
    const setDescriptionProductSupplier = (index, value) => {
        const productInfo = JSON.parse(JSON.stringify([...productSupplierInfo]));
        productInfo[index].description = value;
        setProductSupplierInfo([...productInfo]);
    };
    const addMoreFieldProductSupplierInfo = () => {
        const newField = {...initialFieldProductSupplier};
        newField.isEdit = false;
        newField.info[0].name = 'nameProduct' + productSupplierInfo.length;
        newField.info[1].name = 'priceProduct' + productSupplierInfo.length;
        const maple = [...productSupplierInfo, newField];

        setProductSupplierInfo([...maple]);
    };

    const removeMoreFieldProductSupplierInfo = () => {
        setProductSupplierInfo((prevProductSupplierInfo) => prevProductSupplierInfo.slice(0, -1));
    };
    const handleUploadImageProductSupplierInfo = async (index, e) => {
        const productInfo = JSON.parse(JSON.stringify([...productSupplierInfo]));
        const file = e.target.files[0];
        const previewUrl = await getBase64(file);
        productInfo[index].imageProduct = file;
        productInfo[index].url = previewUrl;

        setProductSupplierInfo([...productInfo]);
    };
    const handleDeleteImageProductSupplierInfo = async (index, e) => {
        const productInfo = JSON.parse(JSON.stringify([...productSupplierInfo]));
        productInfo[index].imageProduct = null;
        productInfo[index].url = null;

        setProductSupplierInfo([...productInfo]);
    };
    const handleDeleteProduct = async (id) => {
        try {
            const res = await dispatch(deleteProduct(id)).unwrap();
            if (res) {
                CustomToast(res, 'Xóa thành công');
            } else {
                CustomToast(res, 'Xóa thất bại');
            }
        } catch (error) {
            CustomToast(false, 'Xóa thất bại');
        }
        fetchSupplierList();
        closeSupplierModal();
    };
    const fetchSupplierList = async () => {
        const res = await dispatch(
            getSupplierList({
                Filters: searchSupplierValue ? `title@=${searchSupplierValue}` : '',
                Sorts: '',
                Page: paginationData[activePage].page,
                PageSize: paginationData[activePage].pageSize
            })
        ).unwrap();
        const {success} = res;
        if (success) {
            const {data} = res;
            setTotalPage(data?.total, data?.pagesize);
        }
    };

    useEffect(() => {
        fetchSupplierList();
    }, [paginationData]);

    const supplierContainerProps = {
        history,
        navigateTo,
        setPage,
        setPageSize,
        checkedData,
        handleSelectAllClick,
        handleSelectRow,
        handleDeleteSelectedRows,
        paginationData,
        activePage,
        supplierList,
        openSupplierModal,
        searchSupplierValue,
        setSearchSupplierValue,
        handleSearchSupplier,
        handleSubmitSupplier,
        detailImageList,
        handleUploadImageList,
        handleDeleteImageList,
        detailImage,
        handleDeleteImage,
        handleUploadImages,
        descriptionSupplier,
        setDescriptionSupplier,
        onUploadImage,
        productSupplierInfo,
        setDescriptionProductSupplier,
        addMoreFieldProductSupplierInfo,
        handleUploadImageProductSupplierInfo,
        handleDeleteImageProductSupplierInfo,
        removeMoreFieldProductSupplierInfo,
        isOpenSupplierModal,
        closeSupplierModal,
        isSupplierEdit,
        handleSubmitInvalid,
        handleDeleteSupplier,
        selectedSupplier,
        handleDeleteProduct,
        moreProduct
    };

    return <SupplierMainView {...propsProvider(supplierContainerProps)} />;
};

export default SupplierContainer;
