import React from 'react';
import { useSelector } from 'react-redux';
import RiveComponent from '@rive-app/react-canvas';

import { loaderSelector } from 'app/selectors';

import haidangLoader from 'assets/loading.riv';

export default function LoaderSpinner() {
    const loader = useSelector(loaderSelector);
    const flag = loader.isLoading || false;
    return (
        <div className={flag ? 'loader-container' : ''}>
            {flag ? (
                <div className='loader-gif'>
                    <RiveComponent src={haidangLoader} />
                </div>
            ) : (
                ''
            )}
        </div>
    );
}
