import React from 'react';

const CustomCard = (props) => {
	const { title, children } = props;

	return (
		<div className='custom-card'>
			<div className="custom-card__header">
				{title}
			</div>

			<div className="custom-card__body">
				{children}
			</div>
		</div>
	);
};

export default CustomCard;