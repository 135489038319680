import {Delete, Edit} from 'assets';

export default function propsProvider(props) {
    const {
        history,
        paginationData,
        activePage,
        navigateTo,
        checkedData,
        handleDeleteSelectedRows,
        setPageSize,
        handleSelectRow,
        setPage,
        catalogueList,
        handleSelectAllClick,
        openCatalogueModal,
        handleSubmitInvalid,
        handleUploadPdf,
        pdfUpload,
        handleDeletePdf,
        handleAddPdf,
        isOpenCatalogueModal,
        closeCatalogueModal,
        fetchDeleteCatalogue
    } = props;

    const {page, pageSize, totalPage = 1} = paginationData[activePage] || {};
    const catalogueTableData =
        catalogueList &&
        catalogueList.map((el) => ({
            id: el?.id,
            // image: { value: el?.image_path, isshow: true, align: 'center' },
            name: {value: el?.file_name, isshow: true, align: 'center'},
            type: {
                value:
                    el?.type == 'DIAGRAM' ? 'Sơ đồ gian hàng' : el?.type == 'RELEASE' ? 'Thông cáo báo chí' : el?.type,
                isshow: true,
                align: 'center'
            },
            actions: {
                value: (
                    <div className='d-flex justify-content-center gap-3'>
                        <Delete className='cursor-pointer' onClick={() => openCatalogueModal(el, false)} />
                    </div>
                ),
                isshow: true
            }
        }));
    const catalogueHeadCells = [
        {
            id: 'fileName',
            align: 'center',
            padding: 'none',
            label: 'file name'
        },
        {
            id: 'Loại',
            align: 'center',
            padding: 'none',
            label: 'Loại'
        },
        {
            id: 'Thao tác',
            align: 'center',
            padding: 'normal',
            label: 'Thao tác'
        }
    ];
    return {
        history,
        catalogueListProps: {
            navigateTo,
            checkedData,
            handleDeleteSelectedRows,
            pageSize,
            headCells: catalogueHeadCells,
            setPageSize,
            handleSelectRow,
            setPage,
            totalPage,
            page,
            tableData: catalogueTableData,
            catalogueList,
            handleSelectAllClick
        },
        addCatalogueProps: {
            handleSubmitInvalid,
            navigateTo,
            handleUploadPdf,
            pdfUpload,
            handleDeletePdf,
            handleAddPdf
        },
        editCatalogueProps: {
            isOpenCatalogueModal,
            closeCatalogueModal,
            fetchDeleteCatalogue
        },
        addDiagramProps: {
            handleSubmitInvalid,
            navigateTo,
            handleUploadPdf,
            pdfUpload,
            handleDeletePdf,
            handleAddPdf
        },
        addReleaseProps: {
            handleSubmitInvalid,
            navigateTo,
            handleUploadPdf,
            pdfUpload,
            handleDeletePdf,
            handleAddPdf
        }
    };
}
