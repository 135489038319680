import {FormControl, FormHelperText} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomCard from 'components/CustomCard';
import CustomInput from 'components/CustomInput/CustomInput';
import InputFiles from 'components/InputFiles/InputFiles';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import {pagePath} from 'configuration/routeConfig';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';

const defaultValues = {
    image: {},
    fileName: '',
    typeImage: {}
};

const AddBanner = (props) => {
    const {handleAddBanner, handleSubmitInvalid, navigateTo, detailImage, handleUploadImages, handleDeleteImage} =
        props;
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch
    } = useForm({defaultValues});

    const addBannerInput = [
        {
            label: 'Tên hình ảnh',
            isRequired: true,
            placeholder: 'Nhập tên hình ảnh',
            type: 'text',
            name: 'fileName'
        },
        {
            label: 'Loại hình ảnh',
            isRequired: true,
            placeholder: 'Loại hình ảnh',
            type: 'select',
            name: 'typeImage',
            options: [
                {id: 'd3fc51f2-5917-4dfa-8243-bb1fbd42a013', value: 'Banner'},
                {id: 'd3fc51f2-5917-4dfa-8243-bb1fbd42a012', value: 'Slide'}
            ]
        }
    ];

    const isFormValid = watch(['fileName', 'image']).every((value) => value.length > 0);
    return (
        <CustomCard title='Thông tin banner'>
            <form
                className='mt-2 add-sponsor'
                method='POST'
                action='#'
                noValidate
                onSubmit={handleSubmit(handleAddBanner, handleSubmitInvalid)}>
                <div className='mb-3'>
                    <div className='d-flex align-items-center flex-wrap gap-3'>
                        {detailImage && detailImage?.url?.length > 0 ? (
                            <PreviewImage image={detailImage?.url} onDeleteImage={handleDeleteImage} />
                        ) : (
                            <InputFiles
                                type='image'
                                inputNotes={['Định dạng tệp PNG, JPEG, JPG', 'Kích thước: tối đa 5MB']}
                                accept={['.png', '.jpeg', '.jpg']}
                                handleUpload={handleUploadImages}
                                name='image'
                                control={control}
                            />
                        )}
                    </div>

                    {errors['images']?.message && <FormHelperText error>{errors['images']?.message}</FormHelperText>}
                </div>

                <div className='mb-3'>
                    <div className='d-flex justify-content-space-around add-sponsor__row'>
                        {addBannerInput.map((input) => {
                            const {name, type, ...restProps} = input;

                            return (
                                <FormControl key={name}>
                                    <Controller
                                        name={name}
                                        control={control}
                                        render={({field}) =>
                                            type !== 'select' ? (
                                                <CustomInput {...restProps} {...field} />
                                            ) : (
                                                <CustomSelect {...restProps} {...field} />
                                            )
                                        }
                                    />
                                </FormControl>
                            );
                        })}
                    </div>
                </div>

                <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                    <CustomButton variant='outlined' onClick={() => navigateTo(pagePath.sponsorUrl)} type='button'>
                        Hủy
                    </CustomButton>
                    <CustomButton variant='contained' type='submit' disabled={!isFormValid}>
                        Lưu
                    </CustomButton>
                </div>
            </form>
        </CustomCard>
    );
};

export default AddBanner;
