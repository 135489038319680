import {pagePath} from 'configuration/routeConfig';
import Home from 'features/Home';
import ProgramList from './ProgramList';
import AddProgram from './AddProgram';
import EditProgram from './EditProgram';

const ProgramMainView = (props) => {
    const {history, programListProps, addProgramProps, editProgramProps} = props;
    let ProgramComponent = null;
    switch (history.location.pathname) {
        case pagePath.programUrl:
            ProgramComponent = <ProgramList {...programListProps} />;
            break;
        case pagePath.programCreateUrl:
            ProgramComponent = <AddProgram {...addProgramProps} />;
            break;
        default:
            ProgramComponent = <ProgramList {...programListProps} />;
            break;
    }
    return (
        <Home>
            <EditProgram {...editProgramProps} />
            {ProgramComponent}
        </Home>
    );
};

export default ProgramMainView;
