import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';
const newsApi = {
    getNews: (params) => {
        const url = '/api/news';
        return axiosClient.get(url, { params });
    },
    addNews: (data) => {
        const url = `/api/news`;
        return axiosClient.post(url, data);
    },

    deleteNews: (id) => {
        const url = `/api/news/${id}`;
        return axiosClient.delete(url);
    },
    updateNews: (data) => {
        const { id, body } = data;
        const url = `/api/news/${id}`;
        return axiosClient.put(url, body);
    },
    uploadNewsImage: (data) => {
        const { id, body } = data;
        const url = `/api/newsAttachment/${id}/uploadImage`;
        return axiosFormData.post(url, body);
    },
    uploadImage: (data) => {
        const { id, body } = data;
        const url = `/api/attachments/${id}/uploadImage`;
        return axiosFormData.put(url, body);
    },
    deleteImageNews: (attachment_id) => {
        const url = `/api/deleteNewsImage/${attachment_id}`;
        return axiosClient.delete(url);
    },
};

export default newsApi;
