import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import companyInfoApi from 'api/companyInfoApi';
import serviceRequest from 'app/serviceRequest';

export const getCompanyInfo = createAsyncThunk('company/getCompanyInfo', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: companyInfoApi.getAllCompanyInfo,
        payload: data
    });
});
export const updateCompanyInfo = createAsyncThunk('company/updateCompanyInfo', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: companyInfoApi.updateCompanyInfo,
        payload: data
    });
});
const company = createSlice({
    name: 'company',
    initialState: {},
    reducers: {},
    extraReducers: (builders) => {
        builders.addCase(getCompanyInfo.fulfilled, (state, action) => {
            state.companyInfo = action?.payload?.data?.collection;
        });
    }
});

const {reducer, actions} = company;
export default reducer;
