import {pagePath} from 'configuration/routeConfig';
import Home from 'features/Home';
import NewsList from './NewsList';
import AddNews from './AddNews';
import EditNews from './EditNews';

const NewsMainView = (props) => {
    const {newsListProps, history, addNewsProps, editNewsProps} = props;
    let NewsComponent = null;
    switch (history.location.pathname) {
        case pagePath.newsUrl:
            NewsComponent = <NewsList {...newsListProps} />;
            break;
        case pagePath.newsCreateUrl:
            NewsComponent = <AddNews {...addNewsProps} />;
            break;
        default:
            NewsComponent = <NewsList {...newsListProps} />;
            break;
    }
    return (
        <Home>
            <EditNews {...editNewsProps} />
            {NewsComponent}
        </Home>
    );
};

export default NewsMainView;
