import { authSelector } from 'app/selectors';
import { getMyInfo } from 'features/Auth/authSlice';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkObjectEmpty } from 'utils/checkObjectEmpty';
import propsProvider from './headerPropsProvider';
import HeaderMainView from './template/HeaderMainView';

const HeaderContainer = (props) => {
	const dispatch = useDispatch();
	const auth = useSelector(authSelector);
	const { userLoginInfo } = auth;
	const headerContainerProps = {
		...props
	};

	useEffect(() => {
		if (checkObjectEmpty(userLoginInfo)) {
			dispatch(getMyInfo());
		}
	}, [userLoginInfo]);

	return (
		<HeaderMainView {...propsProvider(headerContainerProps)} />
	);
};

export default HeaderContainer;