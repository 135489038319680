import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import newsApi from 'api/newsApi';
import serviceRequest from 'app/serviceRequest';

export const getNews = createAsyncThunk('news/getNews', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: newsApi.getNews,
        payload: data
    });
});
export const addNews = createAsyncThunk('news/addNews', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: newsApi.addNews,
        payload: data
    });
});
export const deleteNews = createAsyncThunk('news/deleteNews', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: newsApi.deleteNews,
        payload: id
    });
});
export const updateNews = createAsyncThunk('news/updateNews', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: newsApi.updateNews,
        payload: data
    });
});
export const uploadNewsImage = createAsyncThunk('news/uploadNewsImage', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: newsApi.uploadNewsImage,
        payload: data
    });
});
export const deleteNewsImage = createAsyncThunk('news/deleteNewsImage', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: newsApi.deleteImageNews,
        payload: data
    });
});

const news = createSlice({
    name: 'news',
    initialState: {},
    reducers: {},
    extraReducers: (builders) => {
        builders.addCase(getNews.fulfilled, (state, action) => {
            // console.log('action', action);
            state.newsList = action?.payload?.data?.collection;
        });
    }
});

const {reducer, actions} = news;
export default reducer;
