import {FormControl} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomInput from 'components/CustomInput/CustomInput';
import GroupInput from 'components/GroupInput/GroupInput';
import {pagePath} from 'configuration/routeConfig';
import React from 'react';
import InputFiles from 'components/InputFiles/InputFiles';
import {Controller, useForm} from 'react-hook-form';
import {pdfIcon, Trash} from 'assets';
import CustomTextEditor from 'components/CustomTextEditor';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import PreviewPdf from 'components/PreviewPdf';
const defaultValues = {
    name: '',
    start_date: '',
    address: '',
    end_time: '',
    description: '',
    speaker: '',
    live_stream: '',
    document: {},
    join_link: ''
};

const AddProgram = (props) => {
    const {
        handleSubmitInvalid,
        handleAddProgram,
        navigateTo,
        handleUploadPdf,
        pdfUpload,
        handleDeletePdf,
        descriptionProgram,
        setDescriptionProgram,
        onUploadImage,
        programBanner,
        handleDeleteBannerProgram,
        handleUploadBannerProgram
    } = props;
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch
    } = useForm({defaultValues});
    const watchAllFields = watch();
    // const isFormValid = Object.values(watchAllFields).every((value) => {
    //     if (value && typeof value === 'object') {
    //         return new Date(value).toISOString().length > 0;
    //     }

    //     return value?.length > 0;
    // });

    const addProgramInputs = [
        {
            label: 'Tệp tin',
            placeholder: 'Thêm tài liệu',
            isRequired: true,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'file',
            name: 'document'
        },
        {
            label: 'Tên chương trình',
            placeholder: 'Nhập tên chương trình',
            isRequired: true,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'name'
        },
        {
            label: 'Địa điểm',
            placeholder: 'Nhập địa điểm',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'address'
        },
        {
            label: 'Link đăng ký chương trình',
            placeholder: 'Nhập link đăng ký chương trình',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'join_link'
        },
        {
            label: (
                <span>
                    Thời gian <span className='text-required'>*</span>
                </span>
            ),
            type: 'group',
            control,
            groupInputData: [
                {
                    placeholder: 'Từ',
                    isRequired: true,
                    rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
                    type: 'date',
                    name: 'start_date'
                },
                {
                    placeholder: 'Đến',
                    isRequired: true,
                    rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
                    type: 'date',
                    name: 'end_time'
                }
            ]
        },
        {
            label: 'Đường dẫn live stream',
            placeholder: 'Nhập đường dẫn',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'url',
            name: 'live_stream'
        },
        {
            label: 'Speaker',
            placeholder: 'Nhập speaker',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'speaker'
        }
    ];

    return (
        <CustomCard title='Thông tin chương trình'>
            <form
                className='mt-2 add-program'
                method='POST'
                action='#'
                noValidate
                onSubmit={handleSubmit(handleAddProgram, handleSubmitInvalid)}>
                <div style={{width: '100%'}}>
                    <div className='d-flex align-items-center flex-wrap gap-3'>
                        {programBanner && (
                            <PreviewImage
                                image={programBanner?.url}
                                onDeleteImage={() => handleDeleteBannerProgram()}
                            />
                        )}

                        {!programBanner && (
                            <InputFiles
                                type='image'
                                inputNotes={['Thêm banner', 'Định dạng tệp PNG, JPEG, JPG']}
                                accept={['.png', '.jpeg', '.jpg']}
                                handleUpload={handleUploadBannerProgram}
                                name='image'
                                control={control}
                            />
                        )}
                    </div>
                    {/* {errors['images']?.message && <FormHelperText error>{errors['images']?.message}</FormHelperText>} */}
                </div>
                {addProgramInputs.map((input, idx) => {
                    const {type, rules, groupInputData, label, name, ...restProps} = input;

                    return (
                        <FormControl key={idx}>
                            {type !== 'group' ? (
                                <Controller
                                    name={name}
                                    control={control}
                                    rules={rules}
                                    key={idx}
                                    render={({field}) =>
                                        type === 'date' ? (
                                            <CustomDatePicker {...field} {...restProps} label={label} showTime={true} />
                                        ) : (
                                            <>
                                                {type === 'file' ? (
                                                    <div style={{marginBottom: 10}}>
                                                        {pdfUpload ? (
                                                            <PreviewPdf
                                                                fileName={pdfUpload.name}
                                                                onDeletePdf={handleDeletePdf}
                                                            />
                                                        ) : (
                                                            <InputFiles
                                                                type='file'
                                                                inputNotes={[
                                                                    'Thêm tài liệu PDF',
                                                                    'Kích thước: tối đa 100MB'
                                                                ]}
                                                                accept={['.pdf']}
                                                                handleUpload={handleUploadPdf}
                                                                name='pdf'
                                                                control={control}
                                                            />
                                                        )}
                                                    </div>
                                                ) : (
                                                    <CustomInput label={label} {...field} {...restProps} />
                                                )}
                                            </>
                                        )
                                    }
                                />
                            ) : (
                                <GroupInput control={control} label={label} key={idx} groupInputData={groupInputData} />
                            )}
                        </FormControl>
                    );
                })}
                <CustomTextEditor
                    value={descriptionProgram}
                    setValue={setDescriptionProgram}
                    onUploadImage={onUploadImage}
                />
                <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                    <CustomButton variant='outlined' onClick={() => navigateTo(pagePath.programUrl)} type='button'>
                        Hủy
                    </CustomButton>
                    <CustomButton variant='contained' type='submit'>
                        Lưu
                    </CustomButton>
                </div>
            </form>
        </CustomCard>
    );
};

export default AddProgram;
