import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import newsApi from 'api/newsApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {
        paginationData: Array(9).fill({page: 1, pageSize: 10, totalPage: 1}),
        activePage: 0
    };
};

export const uploadImage = createAsyncThunk('home/uploadImage', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: newsApi.uploadImage,
        payload: data
    });
});

const home = createSlice({
    name: 'home',
    initialState,
    reducers: {
        handleChangePage: (state, action) => {
            const {activePage, page} = action.payload;
            state.paginationData[activePage].page = page;
        },
        handleChangePageSize: (state, action) => {
            const {activePage, pageSize} = action.payload;
            state.paginationData[activePage].pageSize = pageSize;
        },
        handleChangeTotalPage: (state, action) => {
            const {activePage, totalPage = 1} = action.payload;
            state.paginationData[activePage].totalPage = totalPage;
        },
        handleChangActivePage: (state, action) => {
            const {activePage} = action.payload;
            state.activePage = activePage;
            // console.log(' state.activePage ', state.activePage);
        }
    },
    extraReducers: (builders) => {}
});

const {reducer, actions} = home;
export const {handleChangePage, handleChangePageSize, handleChangeTotalPage, handleChangActivePage} = actions;
export default reducer;
