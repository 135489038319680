import React, {useEffect} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import {Close, Delete} from 'assets';
import {Controller, useForm} from 'react-hook-form';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomDatePicker from 'components/CustomDatePicker';
import InputFiles from 'components/InputFiles/InputFiles';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import ReactQuill from 'react-quill';
import CustomTextEditor from 'components/CustomTextEditor';

const defaultValues = {
    name: '',
    address: '',
    phone_number: '',
    video_link: '',
    website: '',
    email: '',
    founded_date: '',
    tax_code: '',
    link_3d_booth: ''
};

const EditSupplier = (props) => {
    const {
        isOpenSupplierModal,
        closeSupplierModal,
        isSupplierEdit,
        handleSubmitSupplier,
        handleSubmitInvalid,
        handleDeleteSupplier,
        selectedSupplier,
        detailImageList,
        handleDeleteImageList,
        handleUploadImageList,
        detailImage,
        handleUploadImages,
        handleDeleteImage,
        descriptionSupplier,
        setDescriptionSupplier,
        moreProduct,
        productSupplierInfo,
        setDescriptionProductSupplier,
        addMoreFieldProductSupplierInfo,
        onUploadImage,
        removeMoreFieldProductSupplierInfo,
        handleDeleteProduct,
        handleUploadImageProductSupplierInfo,
        handleDeleteImageProductSupplierInfo
    } = props;
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch,
        reset,
        setValue
    } = useForm({defaultValues});

    const addSupplierInputs = [
        {
            label: 'Tên nhà cung cấp',
            placeholder: 'Nhập tên nhà cung cấp',
            isRequired: true,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'name'
        },
        {
            label: 'Khách hàng chính',
            placeholder: 'Nhập mã khách hàng chính',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'main_customer'
        },
        {
            label: 'Tên địa chỉ',
            placeholder: 'Nhập địa chỉ',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'address'
        },
        {
            label: 'Số điện thoại',
            placeholder: 'Nhập số điện thoại',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'phone_number'
        },
        {
            label: 'Đường dẫn video',
            placeholder: 'Nhập đường dẫn video',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'video_link'
        },
        {
            label: 'Địa chỉ web',
            placeholder: 'Nhập địa chỉ web',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'website'
        },
        {
            label: 'Email',
            placeholder: 'Nhập địa chỉ email',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'email'
        },
        {
            label: 'Founded Date',
            placeholder: 'Nhập ',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'date',
            name: 'founded_date'
        },
        {
            label: 'Mã số thuế',
            placeholder: 'Nhập mã số thuế',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'tax_code'
        },
        {
            label: 'Link gian hàng 3d',
            placeholder: 'Link gian hàng 3d',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'link_3d_booth'
        }
    ];

    useEffect(() => {
        reset();
    }, [isOpenSupplierModal]);

    useEffect(() => {
        if (isSupplierEdit && selectedSupplier) {
            setValue('name', selectedSupplier?.name);
            setValue('description', selectedSupplier?.description);
            setValue('main_customer', selectedSupplier?.main_customer);
            setValue('address', selectedSupplier?.address);
            setValue('phone_number', selectedSupplier?.phone_number);
            setValue('video_link', selectedSupplier?.video_link);
            setValue('website', selectedSupplier?.website);
            setValue('email', selectedSupplier?.email);
            setValue('founded_date', selectedSupplier?.founded_date);
            setValue('tax_code', selectedSupplier?.tax_code);
            setValue('link_3d_booth', selectedSupplier?.link_3d_booth);
        }

        if (moreProduct) {
            moreProduct.map((pro, idx) => {
                setValue('nameProduct' + idx, pro?.name);
                setValue('descriptionProduct' + idx, pro?.description);
                setValue('priceProduct' + idx, pro?.price);
            });
        }
    }, [isOpenSupplierModal, selectedSupplier]);
    return (
        <>
            <Dialog
                maxWidth={isSupplierEdit ? 'sm' : 'xs'}
                fullWidth={true}
                open={isOpenSupplierModal}
                onClose={closeSupplierModal}
                aria-labelledby='voucher-modal'
                aria-describedby='apply-voucher-to-get-discount'
                className='dialog mt-2'>
                <form
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (isSupplierEdit) {
                            handleSubmit(handleSubmitSupplier, handleSubmitInvalid)();
                        } else {
                            handleDeleteSupplier();
                        }
                    }}>
                    <DialogTitle className='dialog-title' id='voucher-modal'>
                        <span>{isSupplierEdit ? 'Chỉnh sửa nhà cung cấp' : 'Xác nhận'}</span>
                        <button className='dialog-title__close'>
                            <Close
                                className='cursor-pointer'
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeSupplierModal();
                                }}
                            />
                        </button>
                    </DialogTitle>
                    <DialogContent className='dialog-content' style={{padding: '32px 24px'}}>
                        <div className='add-program'>
                            {!isSupplierEdit ? (
                                'Bạn có chắc muốn xóa nhà cung cấp này?'
                            ) : (
                                <>
                                    <div className='custom-card__header' style={{width: '100%'}}>
                                        Thông tin nhà cung cấp
                                    </div>
                                    <div
                                        className='MuiFormControl-root css-MuiFormControl-root'
                                        style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
                                        {detailImageList.map((image, idx) => {
                                            return (
                                                <PreviewImage
                                                    key={idx}
                                                    image={image?.url}
                                                    onDeleteImage={() => handleDeleteImageList(image)}
                                                />
                                            );
                                        })}
                                        {detailImageList && detailImageList.length !== 1 && (
                                            <InputFiles
                                                type='image'
                                                inputNotes={[
                                                    'Thêm hình ảnh banner',
                                                    'Định dạng tệp PNG, JPEG, JPG',
                                                    'Kích thước: tối đa 5MB'
                                                ]}
                                                accept={['.png', '.jpeg', '.jpg']}
                                                handleUpload={handleUploadImageList}
                                                name='image'
                                                control={control}
                                            />
                                        )}
                                    </div>
                                    <div className='MuiFormControl-root css-1nrlq1o-MuiFormControl-root'>
                                        {detailImage && detailImage?.url?.length > 0 ? (
                                            <PreviewImage image={detailImage?.url} onDeleteImage={handleDeleteImage} />
                                        ) : (
                                            <InputFiles
                                                type='image'
                                                inputNotes={[
                                                    'Thêm logo',
                                                    'Định dạng tệp PNG, JPEG, JPG',
                                                    'Kích thước: tối đa 5MB'
                                                ]}
                                                accept={['.png', '.jpeg', '.jpg']}
                                                handleUpload={handleUploadImages}
                                                name='image'
                                                control={control}
                                            />
                                        )}
                                    </div>
                                    {addSupplierInputs.map((input, idx) => {
                                        const {type, rules, groupInputData, label, name, ...restProps} = input;

                                        return (
                                            <FormControl key={idx}>
                                                <Controller
                                                    name={name}
                                                    control={control}
                                                    rules={rules}
                                                    key={idx}
                                                    render={({field: {value, ...restField}}) =>
                                                        type === 'date' ? (
                                                            <CustomDatePicker
                                                                {...restField}
                                                                {...restProps}
                                                                label={label}
                                                                value={value}
                                                            />
                                                        ) : (
                                                            <CustomInput
                                                                label={label}
                                                                value={value}
                                                                {...restField}
                                                                {...restProps}
                                                            />
                                                        )
                                                    }
                                                />
                                            </FormControl>
                                        );
                                    })}

                                    {isSupplierEdit && (
                                        <div style={{width: '100%'}}>
                                            <br />
                                            <span>Mô tả thông tin nhà cung cấp</span>
                                            <ReactQuill
                                                theme='snow'
                                                value={descriptionSupplier}
                                                onChange={setDescriptionSupplier}
                                                style={{width: '100%'}}
                                            />
                                            <br />
                                        </div>
                                    )}
                                    {productSupplierInfo.map((input, idx) => {
                                        return (
                                            <div key={idx}>
                                                <div
                                                    className='custom-card__header'
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}>
                                                    <span> Nhập thông tin sản phẩm</span>
                                                    <br />
                                                    {!moreProduct[idx]?.id && (
                                                        <Delete
                                                            onClick={removeMoreFieldProductSupplierInfo}
                                                            style={{cursor: 'pointer'}}
                                                        />
                                                    )}
                                                </div>
                                                <br />
                                                {input.url && input?.url?.length > 0 ? (
                                                    <PreviewImage
                                                        image={input?.url}
                                                        onDeleteImage={(e) =>
                                                            handleDeleteImageProductSupplierInfo(idx, e)
                                                        }
                                                    />
                                                ) : (
                                                    <InputFiles
                                                        type='image'
                                                        inputNotes={[
                                                            'Thêm logo',
                                                            'Định dạng tệp PNG, JPEG, JPG',
                                                            'Kích thước: tối đa 5MB'
                                                        ]}
                                                        accept={['.png', '.jpeg', '.jpg']}
                                                        handleUpload={(e) =>
                                                            handleUploadImageProductSupplierInfo(idx, e)
                                                        }
                                                        name='image'
                                                        control={control}
                                                    />
                                                )}
                                                <br />
                                                {input.info.map((item, index) => {
                                                    const {
                                                        type,
                                                        rules,
                                                        groupInputData,
                                                        label,
                                                        name,
                                                        line,
                                                        ...restProps
                                                    } = item;
                                                    return (
                                                        <FormControl key={index}>
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={rules}
                                                                key={idx}
                                                                render={({field}) => (
                                                                    <CustomInput
                                                                        label={label}
                                                                        {...field}
                                                                        {...restProps}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                    );
                                                })}
                                                <div>
                                                    <br />
                                                    <span>Mô tả sản phẩm</span>
                                                    <CustomTextEditor
                                                        value={input.description}
                                                        setValue={(text) => setDescriptionProductSupplier(idx, text)}
                                                        onUploadImage={onUploadImage}
                                                    />
                                                    <br />
                                                </div>
                                                {moreProduct[idx]?.id && (
                                                    <div className='w-100 d-flex align-items-center justify-content-start gap-3 form-buttons'>
                                                        <CustomButton
                                                            variant='outlined'
                                                            onClick={() => handleDeleteProduct(moreProduct[idx].id)}
                                                            type='button'
                                                            color='danger'>
                                                            Xoá sản phẩm {moreProduct[idx]?.name}
                                                        </CustomButton>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}

                                    <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                                        <CustomButton
                                            variant='outlined'
                                            onClick={addMoreFieldProductSupplierInfo}
                                            type='button'>
                                            Thêm sản phẩm
                                        </CustomButton>
                                    </div>
                                </>
                            )}
                        </div>
                    </DialogContent>

                    <DialogActions className='dialog-actions'>
                        <CustomButton
                            variant='text'
                            onClick={(e) => {
                                e.preventDefault();
                                closeSupplierModal();
                            }}>
                            Hủy
                        </CustomButton>
                        <CustomButton
                            variant='contained'
                            type='submit'
                            color={!isSupplierEdit ? 'danger' : 'primary'}
                            // disabled={!isFormValid && isSponsorEdit}
                        >
                            {isSupplierEdit ? 'Lưu thay đổi' : 'Đồng ý'}
                        </CustomButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default EditSupplier;
