import {FormControl, FormHelperText} from '@mui/material';
import {Delete} from 'assets';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomTextEditor from 'components/CustomTextEditor';
import InputFiles from 'components/InputFiles/InputFiles';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import {pagePath} from 'configuration/routeConfig';
import {Controller, useForm} from 'react-hook-form';
const defaultValues = {
    name: '',
    // description: '',
    address: '',
    phone_number: '',
    video_link: '',
    website: '',
    email: '',
    founded_date: '',
    tax_code: '',
    nameProduct: '',
    // descriptionProduct: '',
    priceProduct: ''
};

const AddSupplier = (props) => {
    const {
        handleSubmitSupplier,
        navigateTo,
        detailImageList,
        handleUploadImageList,
        handleDeleteImageList,
        detailImage,
        handleDeleteImage,
        handleUploadImages,
        descriptionSupplier,
        setDescriptionSupplier,
        onUploadImage,
        productSupplierInfo,
        setDescriptionProductSupplier,
        addMoreFieldProductSupplierInfo,
        handleUploadImageProductSupplierInfo,
        handleDeleteImageProductSupplierInfo,
        removeMoreFieldProductSupplierInfo
    } = props;
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch,
        setValue
    } = useForm({defaultValues});

    const addSupplierInputs = [
        {
            label: 'Tên nhà cung cấp',
            placeholder: 'Nhập tên nhà cung cấp',
            isRequired: true,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'name'
        },
        {
            label: 'Khách hàng chính',
            placeholder: 'Nhập mã khách hàng chính',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'main_customer'
        },
        {
            label: 'Tên địa chỉ',
            placeholder: 'Nhập địa chỉ',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'address'
        },
        {
            label: 'Số điện thoại',
            placeholder: 'Nhập số điện thoại',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'phone_number'
        },
        {
            label: 'Đường dẫn video',
            placeholder: 'Nhập đường dẫn video',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'video_link'
        },
        {
            label: 'Địa chỉ web',
            placeholder: 'Nhập địa chỉ web',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'website'
        },
        {
            label: 'Email',
            placeholder: 'Nhập địa chỉ email',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'email'
        },
        {
            label: 'Founded Date',
            placeholder: 'Nhập ',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'date',
            name: 'founded_date'
        },
        {
            label: 'Mã số thuế',
            placeholder: 'Nhập mã số thuế',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'tax_code'
        },
        {
            label: 'Link gian hàng 3d',
            placeholder: 'Link gian hàng 3d',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'link_3d_booth'
        }
    ];

    return (
        <CustomCard>
            <form
                className='mt-2 add-supplier'
                method='POST'
                action='#'
                noValidate
                onSubmit={handleSubmit(handleSubmitSupplier)}>
                <div className='custom-card__header' style={{width: '100%'}}>
                    Nhập thông tin nhà cung cấp
                </div>
                <div
                    className='MuiFormControl-root css-MuiFormControl-root'
                    style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
                    {detailImageList.map((image, idx) => {
                        return (
                            <PreviewImage
                                key={idx}
                                image={image?.url}
                                onDeleteImage={() => handleDeleteImageList(image)}
                            />
                        );
                    })}
                    {detailImageList && detailImageList.length !== 1 && (
                        <InputFiles
                            type='image'
                            inputNotes={[
                                'Thêm hình ảnh banner',
                                'Định dạng tệp PNG, JPEG, JPG',
                                'Kích thước: tối đa 5MB'
                            ]}
                            accept={['.png', '.jpeg', '.jpg']}
                            handleUpload={handleUploadImageList}
                            name='image'
                            control={control}
                        />
                    )}
                </div>
                {errors['images']?.message && <FormHelperText error>{errors['images']?.message}</FormHelperText>}
                <div className='MuiFormControl-root css-1nrlq1o-MuiFormControl-root'>
                    {detailImage && detailImage?.url?.length > 0 ? (
                        <PreviewImage image={detailImage?.url} onDeleteImage={handleDeleteImage} />
                    ) : (
                        <InputFiles
                            type='image'
                            inputNotes={['Thêm logo', 'Định dạng tệp PNG, JPEG, JPG', 'Kích thước: tối đa 5MB']}
                            accept={['.png', '.jpeg', '.jpg']}
                            handleUpload={handleUploadImages}
                            name='image'
                            control={control}
                        />
                    )}
                </div>
                {errors['images']?.message && <FormHelperText error>{errors['images']?.message}</FormHelperText>}
                {addSupplierInputs.map((input, idx) => {
                    const {type, rules, groupInputData, label, name, line, ...restProps} = input;
                    return (
                        <FormControl key={idx}>
                            <Controller
                                name={name}
                                control={control}
                                rules={rules}
                                key={idx}
                                render={({field}) =>
                                    type === 'date' ? (
                                        <CustomDatePicker {...field} {...restProps} label={label} />
                                    ) : (
                                        <CustomInput label={label} {...field} {...restProps} type={type} />
                                    )
                                }
                            />
                        </FormControl>
                    );
                })}
                <div style={{width: '100%'}}>
                    <span>Mô tả thông tin nhà cung cấp</span>
                    <CustomTextEditor
                        value={descriptionSupplier}
                        setValue={setDescriptionSupplier}
                        onUploadImage={onUploadImage}
                    />

                    <br />
                </div>

                {productSupplierInfo.length > 0 && (
                    <div style={{width: '100%'}}>
                        <div
                            className='MuiFormControl-root css-MuiFormControl-root'
                            style={{gap: '1rem', width: '100%', gap: '1rem', display: 'flex', flexDirection: 'column'}}>
                            {productSupplierInfo.map((input, idx) => {
                                return (
                                    <div key={idx}>
                                        <div
                                            className='custom-card__header'
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                            <span> Nhập thông tin sản phẩm</span>
                                            <Delete
                                                onClick={removeMoreFieldProductSupplierInfo}
                                                style={{cursor: 'pointer'}}
                                            />
                                        </div>
                                        <br />
                                        {input.url && input?.url?.length > 0 ? (
                                            <PreviewImage
                                                image={input?.url}
                                                onDeleteImage={(e) => handleDeleteImageProductSupplierInfo(idx, e)}
                                            />
                                        ) : (
                                            <InputFiles
                                                type='image'
                                                inputNotes={[
                                                    'Thêm logo',
                                                    'Định dạng tệp PNG, JPEG, JPG',
                                                    'Kích thước: tối đa 5MB'
                                                ]}
                                                accept={['.png', '.jpeg', '.jpg']}
                                                handleUpload={(e) => handleUploadImageProductSupplierInfo(idx, e)}
                                                name='image'
                                                control={control}
                                            />
                                        )}
                                        <br />
                                        {input.info.map((item, index) => {
                                            const {type, rules, groupInputData, label, name, line, ...restProps} = item;
                                            return (
                                                <FormControl key={index}>
                                                    <Controller
                                                        name={name}
                                                        control={control}
                                                        rules={rules}
                                                        key={idx}
                                                        render={({field}) => (
                                                            <CustomInput label={label} {...field} {...restProps} />
                                                        )}
                                                    />
                                                </FormControl>
                                            );
                                        })}

                                        <div>
                                            <br />
                                            <span>Mô tả sản phẩm</span>
                                            <CustomTextEditor
                                                value={input.description}
                                                setValue={(text) => setDescriptionProductSupplier(idx, text)}
                                                onUploadImage={onUploadImage}
                                            />
                                            <br />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                    <CustomButton variant='outlined' onClick={addMoreFieldProductSupplierInfo} type='button'>
                        Thêm sản phẩm
                    </CustomButton>
                </div>
                <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                    <CustomButton variant='outlined' onClick={() => navigateTo(pagePath.supplierUrl)} type='button'>
                        Hủy
                    </CustomButton>
                    <CustomButton variant='contained' type='submit'>
                        Lưu
                    </CustomButton>
                </div>
            </form>
        </CustomCard>
    );
};

export default AddSupplier;
