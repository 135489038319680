import React from 'react';
import AuthContainer from './AuthContainer';

const Auth = (props) => {
	const authProps = {
		...props
	};
	return (
		<AuthContainer {...authProps} />
	);
};

export default Auth;