import NotFound from 'components/NotFound';
import Auth from 'features/Auth';
import Home from 'features/Home';
import Consultant from 'features/Consultant';
import Company from 'features/Company';
import Program from 'features/Program';
import News from 'features/News';
import Supplier from 'features/Supplier';
import Banner from 'features/Banner';
import Catalogue from 'features/Catalogue';
import CheckIn from 'features/CheckIn';
import Customer from 'features/Customer';
const rootUrl = '/';
const programUrl = '/program';
const programCreateUrl = '/program/create';
const newsUrl = '/news';
const newsCreateUrl = '/news/create';
const supplierUrl = '/supplier';
const supplierCreateUrl = '/supplier/create';
const companyInfoUrl = '/company';
const updateCompanyInfoUrl = '/company/update';
const logoutUrl = '/logout';
const authUrl = '/auth';
const authLoginUrl = '/auth/login';
const authForgotPasswordUrl = '/auth/forgot-password';
const authSetPasswordUrl = '/auth/set-password';
const notFoundUrl = '/*';
const deleteAccount = '/auth/deleteAccount';
const bannerUrl = '/banner';
const bannerCreateUrl = '/banner/create';
const catalogueUrl = '/catalogue';
const catalogueCreateUrl = '/catalogue/create';
const consultantUrl = '/consultant';
const consultantCreateUrl = '/consultant/create';

const diagramUrl = '/diagram';
const diagramCreateUrl = '/diagram/create';
const releaseUrl = '/release';
const releaseCreateUrl = '/release/create';
const checkInURL = '/checkin';
const checkInCreateURL = '/checkin/create';
const customerURL = '/customer';
const customerCreateURL = '/customer/create';
const tagCreateUrl = '/tag/create';
export const routeList = [
    // Keep "Not found page" the last one
    {path: rootUrl, component: Home, isPrivate: false},
    {path: programUrl, component: Program, isPrivate: false},
    {path: programCreateUrl, component: Program, isPrivate: false},
    {path: newsUrl, component: News, isPrivate: false},
    {path: newsCreateUrl, component: News, isPrivate: false},
    {path: supplierUrl, component: Supplier, isPrivate: false},
    {path: supplierCreateUrl, component: Supplier, isPrivate: false},
    {path: catalogueUrl, component: Catalogue, isPrivate: false},
    {path: catalogueCreateUrl, component: Catalogue, isPrivate: false},
    {path: consultantUrl, component: Consultant, isPrivate: false},
    {path: consultantCreateUrl, component: Consultant, isPrivate: false},
    {path: diagramUrl, component: Home, isPrivate: false},
    {path: diagramCreateUrl, component: Catalogue, isPrivate: false},
    {path: releaseUrl, component: Home, isPrivate: false},
    {path: releaseCreateUrl, component: Catalogue, isPrivate: false},
    {path: companyInfoUrl, component: Company, isPrivate: false},
    {path: updateCompanyInfoUrl, component: Company, isPrivate: false},
    {path: bannerUrl, component: Banner, isPrivate: false},
    {path: bannerCreateUrl, component: Banner, isPrivate: false},
    {path: authUrl, component: Auth},
    {path: authLoginUrl, component: Auth},
    {path: authForgotPasswordUrl, component: Auth},
    {path: authSetPasswordUrl, component: Auth},
    {path: deleteAccount, component: Auth},
    {path: checkInURL, component: CheckIn, isPrivate: false},
    {path: checkInCreateURL, component: CheckIn, isPrivate: false},
    {path: customerURL, component: Customer, isPrivate: false},
    {path: customerCreateURL, component: Customer, isPrivate: false},
    {path: tagCreateUrl, component: Customer, isPrivate: false},
    {path: notFoundUrl, component: NotFound}
];

export const redirectList = [{from: authUrl, to: authLoginUrl}];

export const pagePath = {
    rootUrl,
    programUrl,
    programCreateUrl,
    newsUrl,
    newsCreateUrl,
    supplierUrl,
    supplierCreateUrl,
    // sponsorUrl,
    // sponsorCreateUrl,
    logoutUrl,
    authUrl,
    authLoginUrl,
    authForgotPasswordUrl,
    authSetPasswordUrl,
    deleteAccount,
    bannerCreateUrl,
    bannerUrl,
    catalogueUrl,
    catalogueCreateUrl,
    releaseUrl,
    releaseCreateUrl,
    diagramCreateUrl,
    diagramUrl,
    consultantUrl,
    consultantCreateUrl,
    companyInfoUrl,
    updateCompanyInfoUrl,
    checkInURL,
    customerURL,
    customerCreateURL,
    tagCreateUrl,
    checkInCreateURL
};
