import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';
const newsApi = {
    getSuppliers: (params) => {
        const url = '/api/organization/getAll';
        return axiosClient.get(url, { params });
    },
    addSupplier: (data) => {
        const url = `/api/organization/post`;
        return axiosClient.post(url, data);
    },
    updateSupplier: (data) => {
        const { id, body } = data;
        const url = `/api/organization/put/${id}`;
        return axiosClient.put(url, body);
    },
    deleteSupplier: (id) => {
        const url = `/api/organization/delete/${id}`;
        return axiosClient.delete(url);
    },
    getProduct: (id) => {
        const url = `/api/product/${id}`;
        return axiosClient.get(url);
    },
    addProduct: (data) => {
        const url = `/api/product`;
        return axiosClient.post(url, data);
    },
    updateProduct: (data) => {
        const { id, body } = data;
        const url = `/api/product/${id}`;
        return axiosClient.put(url, body);
    },
    deleteProduct: (id) => {
        const url = `/api/product/${id}`;
        return axiosClient.delete(url);
    },
    uploadProductImage: (data) => {
        const { id, file } = data;
        const url = `/api/product/${id}/uploadProductImage`;
        return axiosFormData.put(url, file);
    },
    uploadSupplierLogo: (data) => {
        const { id, file } = data;
        const url = `/api/organization/uploadLogo/${id}`;
        return axiosFormData.put(url, file);
    },
    uploadSupplierBanner: (data) => {
        const { id, file } = data;
        const url = `/api/organization/uploadBanner/${id}`;
        return axiosFormData.put(url, file);
    },
    getProductByOrganizationId: (organizationId) => {
        const url = `/api/product/supplier/${organizationId}`;
        return axiosClient.get(url);
    },
};

export default newsApi;
