import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import {Close} from 'assets';
import {Controller, useForm} from 'react-hook-form';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomDatePicker from 'components/CustomDatePicker';
import GroupInput from 'components/GroupInput/GroupInput';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomTextEditor from 'components/CustomTextEditor';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import InputFiles from 'components/InputFiles/InputFiles';
const defaultValues = {
    full_name: '',
    degree: '',
    work_unit: '',
    cell_phone: '',
    email: '',
    capacity_at: ''
};

const EditConsultant = (props) => {
    const {
        handleSubmitInvalid,
        isOpenConsultantModal,
        isConsultantEdit,
        selectedConsultant,
        handleAddConsultant,
        closeConsultantModal,
        handleDeleteConsultant,
        onUploadImage,
        setDescriptionConsultant,
        descriptionConsultant,
        handleDeleteTempAvatarConsultant,
        handleUploadAvatarConsultant,
        avatarConsultant
    } = props;
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch,
        reset,
        setValue
    } = useForm({defaultValues});
    const watchAllFields = watch();
    // const isFormValid = Object.values(watchAllFields).every((value) => {
    //     if (value && typeof value === 'object') {
    //         return new Date(value).toISOString().length > 0;
    //     }

    //     return value?.length > 0;
    // });

    const addConsultantInputs = [
        {
            label: 'Họ tên',
            placeholder: 'Nhập họ tên',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'full_name'
        },
        {
            label: 'Bằng cấp',
            placeholder: 'Nhập bằng cấp',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'degree'
        },
        {
            label: 'Nơi làm việc',
            placeholder: 'Nhập nơi làm việc',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'work_unit'
        },
        {
            label: 'Số điện thoại',
            placeholder: 'Nhập số điện thoại',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'cell_phone'
        },
        {
            label: 'Địa chỉ email',
            placeholder: 'Nhập địa chỉ email',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'email'
        }
        // {
        //     label: 'Năng lực',
        //     placeholder: 'Nhập năng lực',
        //     isRequired: false,
        //     rules: { required: { value: false, messages: 'trường này là bắt buộc' } },
        //     type: 'text',
        //     name: 'capacity_at'
        // }
    ];

    useEffect(() => {
        reset();
    }, [isOpenConsultantModal]);

    useEffect(() => {
        if (isConsultantEdit && selectedConsultant) {
            setValue('full_name', selectedConsultant?.full_name);
            setValue('degree', selectedConsultant?.degree);
            setValue('work_unit', selectedConsultant?.work_unit);
            setValue('email', selectedConsultant?.email);
            // setValue('capacity_at', selectedConsultant?.capacity_at);
            setValue('cell_phone', selectedConsultant?.cell_phone);
        }
    }, [isOpenConsultantModal, selectedConsultant]);
   
    return (
        <>
            <Dialog
                maxWidth={isConsultantEdit ? 'sm' : 'xs'}
                fullWidth={true}
                open={isOpenConsultantModal}
                onClose={closeConsultantModal}
                aria-labelledby='voucher-modal'
                aria-describedby='apply-voucher-to-get-discount'
                className='dialog'>
                <form
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (isConsultantEdit) {
                            handleSubmit(handleAddConsultant, handleSubmitInvalid)();
                        } else {
                            handleDeleteConsultant();
                        }
                    }}>
                    <DialogTitle className='dialog-title' id='voucher-modal'>
                        <span>{isConsultantEdit ? 'Chỉnh sửa chuyên gia' : 'Xác nhận'}</span>
                        <button className='dialog-title__close'>
                            <Close
                                className='cursor-pointer'
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeConsultantModal();
                                }}
                            />
                        </button>
                    </DialogTitle>

                    <DialogContent className='dialog-content' style={{padding: '32px 24px'}}>
                        {isConsultantEdit && (
                            <div>
                                {avatarConsultant && (
                                    <PreviewImage
                                        image={avatarConsultant?.url}
                                        onDeleteImage={handleDeleteTempAvatarConsultant}
                                    />
                                )}
                                {!avatarConsultant && (
                                    <InputFiles
                                        type='image'
                                        inputNotes={[
                                            'Thêm hình ảnh',
                                            'Định dạng tệp PNG, JPEG, JPG',
                                            'Kích thước: tối đa 5MB'
                                        ]}
                                        accept={['.png', '.jpeg', '.jpg']}
                                        handleUpload={handleUploadAvatarConsultant}
                                        name='image'
                                        control={control}
                                    />
                                )}
                            </div>
                        )}
                        <br />
                        <div className={`${isConsultantEdit ? 'add-program' : ''} `}>
                            {!isConsultantEdit
                                ? 'Bạn có chắc muốn xóa chuyên gia này?'
                                : addConsultantInputs.map((input, idx) => {
                                      const {type, rules, groupInputData, label, name, ...restProps} = input;

                                      return (
                                          <FormControl key={idx}>
                                              {type !== 'group' ? (
                                                  <Controller
                                                      name={name}
                                                      control={control}
                                                      rules={rules}
                                                      key={idx}
                                                      render={({field: {value, ...restField}}) =>
                                                          type === 'date' ? (
                                                              <CustomDatePicker
                                                                  {...restField}
                                                                  {...restProps}
                                                                  label={label}
                                                                  value={value}
                                                              />
                                                          ) : (
                                                              <CustomInput
                                                                  label={label}
                                                                  value={value}
                                                                  {...restField}
                                                                  {...restProps}
                                                              />
                                                          )
                                                      }
                                                  />
                                              ) : (
                                                  <GroupInput
                                                      control={control}
                                                      label={label}
                                                      key={idx}
                                                      groupInputData={groupInputData}
                                                  />
                                              )}
                                          </FormControl>
                                      );
                                  })}
                            {isConsultantEdit && (
                                <CustomTextEditor
                                    value={descriptionConsultant}
                                    setValue={setDescriptionConsultant}
                                    onUploadImage={onUploadImage}
                                />
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions className='dialog-actions'>
                        <CustomButton
                            variant='text'
                            onClick={(e) => {
                                e.preventDefault();
                                closeConsultantModal();
                            }}>
                            Hủy
                        </CustomButton>
                        <CustomButton
                            variant='contained'
                            type='submit'
                            color={!isConsultantEdit ? 'danger' : 'primary'}
                            // disabled={!isFormValid && isSponsorEdit}
                        >
                            {isConsultantEdit ? 'Lưu thay đổi' : 'Đồng ý'}
                        </CustomButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default EditConsultant;
