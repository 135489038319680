import {Delete, Print} from 'assets';

export default function propsProvider(props) {
    const {
        history,
        navigateTo,
        checkInList,
        checkedData,
        handleDeleteSelectedRows,
        setPageSize,
        handleSelectRow,
        setPage,
        paginationData,
        activePage,
        onDeleteCheckInUser,
        printRef,
        onSubmitPrintQRCode,
        modalContent,
        exportToCSV,
        onOpenModalExport,
        filterEventData,
        handleChangeFilterEvent,
        setSearchValue,
        searchValue,
        fetchCheckInList,
        handleCheckInForUser,
        handleSubmitCreateCustomerCheckIn,
        handleSubmitInvalid,
        isOpenImportUserModal,
        handleUploadExcel,
        onDeleteExcel,
        importExcelData,
        onSubmitImportDataExcel,
        closeImportUser,
        setIsOpenImportUserModal
    } = props;

    const {page, pageSize, totalPage = 1} = paginationData[activePage] || {};

    const headCells = [
        {
            id: 'STT',
            align: 'left',
            padding: 'normal',
            label: 'STT'
        },
        {
            id: 'Họ và tên',
            align: 'left',
            padding: 'normal',
            label: 'Họ và tên'
        },
        {
            id: 'Đơn vị',
            align: 'left',
            padding: 'normal',
            label: 'Đơn vị'
        },
        {
            id: 'Số điện thoại',
            align: 'left',
            padding: 'normal',
            label: 'Số điện thoại'
        },
        {
            id: 'Email',
            align: 'left',
            padding: 'normal',
            label: 'Email'
        },
        {
            id: 'Thời gian check in',
            align: 'left',
            padding: 'normal',
            label: 'Thời gian check in'
        },
        {
            id: 'Check in',
            align: 'left',
            padding: 'normal',
            label: 'Check in'
        },
        {
            id: 'Sự kiện',
            align: 'left',
            padding: 'normal',
            label: 'Sự kiện'
        },
        {
            id: 'Thao tác',
            align: 'center',
            padding: 'normal',
            label: 'Thao tác'
        }
    ];
    const checkInTableData =
        filterEventData &&
        filterEventData.map((el, index) => ({
            id: el?.id,
            stt: {value: index + 1, isshow: true},
            name: {value: el?.user_full_name, isshow: true},
            company: {value: el?.user_company_name, isshow: true},
            phone_number: {value: el?.user_phone_number, isshow: true},
            email: {value: el?.user_email, isshow: true},
            time_checked: {
                value: el?.created_at ? new Date(el?.created_at).toLocaleDateString('vi-VI').split('T')[0] : '',
                isshow: true
            },
            is_checked: {value: el?.is_checked, isshow: true, position: index},
            program_name: {value: el?.program_name, isshow: true},
            actions: {
                value: (
                    <div className='d-flex justify-content-center gap-3'>
                        <Print className='cursor-pointer' onClick={() => onSubmitPrintQRCode(el)} />
                        <Delete className='cursor-pointer' onClick={() => onDeleteCheckInUser(el)} />
                    </div>
                ),
                isshow: true
            },
            bannerObj: {
                ...el
            }
        }));
    return {
        history,
        printRef,
        modalContent,
        exportToCSV,
        checkInListProps: {
            navigateTo,
            checkInList,
            checkedData,
            handleDeleteSelectedRows,
            pageSize,
            setPageSize,
            handleSelectRow,
            setPage,
            totalPage,
            page,
            tableData: checkInTableData,
            headCells: headCells,
            exportToCSV,
            onOpenModalExport,
            handleChangeFilterEvent,
            setSearchValue,
            searchValue,
            fetchCheckInList,
            handleCheckInForUser,
            setIsOpenImportUserModal
        },
        addNewProps: {
            navigateTo,
            checkInList: [...new Set([...checkInList.map((item) => item.program_name)])],
            handleSubmitCreateCustomerCheckIn
        },
        importUserProps: {
            handleSubmitInvalid,
            isOpenImportUserModal,
            handleUploadExcel,
            onDeleteExcel,
            importExcelData,
            onSubmitImportDataExcel,
            closeImportUser
        }
    };
}
