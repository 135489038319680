import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {calcTotalPage} from 'utils/calcTotalPage';
import propsProvider from './companyPropsProvider';

import CustomToast from 'components/CustomToast';
import {pagePath} from 'configuration/routeConfig';
import {logout} from 'features/Auth/authSlice';
import {handleChangePage, handleChangePageSize, handleChangeTotalPage, uploadImage} from 'features/Home/homeSlice';
import {getCompanyInfo, updateCompanyInfo} from './companySlice';
import CompanyMainView from './template/CompanyMainView';

const CompanyContainer = (props) => {
    const {home, userLoginInfo, companyInfo} = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const {paginationData, activePage} = home;
    const [checkedData, setCheckedData] = useState([]);
    const [isOpenCompanyInfoModal, setOpenCompanyInfoModal] = useState(false);
    const [selectedCompanyInfo, setSelectedCompanyInfo] = useState({});
    const [isCompanyInfoEdit, setIsCompanyInfoEdit] = useState(false);
    const [descriptionCompanyInfo, setDescriptionCompanyInfo] = useState('');

    const navigateTo = (path) => {
        try {
            if (path === pagePath.logoutUrl) {
                dispatch(logout()).then((res) => history.push(pagePath.authLoginUrl));
            } else {
                history.push(path);
            }
        } catch (e) {
            CustomToast(false, 'Có lỗi xảy ra');
        }
    };

    const setPage = (value) => {
        dispatch(handleChangePage({activePage: activePage, page: value}));
    };

    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage: activePage, pageSize: value}));
        setPage(1);
    };

    const setTotalPage = (count, pageSize) => {
        const value = calcTotalPage(count, pageSize);
        // console.log('value', value);
        dispatch(handleChangeTotalPage({activePage: activePage, totalPage: value}));
    };

    const handleSelectRow = (event, id) => {};
    const handleDeleteSelectedRows = async () => {};
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            let newSelected = [];
            // if (activePage === 0) {
            //     newSelected = programList.map((data) => data?.id);
            // } else {
            //     newSelected = sponsorList.map((data) => data?.id);
            // }
            setCheckedData(newSelected);
            return;
        }

        setCheckedData([]);
    };
    const handleSubmitInvalid = (_, event) => {
        console.log('submit invalid');
        // event.target.classList.add('wasvalidated');
    };
    const handleEditCompanyInfo = async (data) => {
        const {introduce} = data;
        const payload = {
            description: descriptionCompanyInfo,
            introduce: introduce
        };
        const res = await dispatch(updateCompanyInfo({id: selectedCompanyInfo?.id, body: payload})).unwrap();
        const {success} = res;
        if (success) {
            CustomToast('success', 'Chỉnh sửa thành công');
            // if (!isConsultantEdit) navigateTo(pagePath.consultantUrl);
            fetchCompanyInfo();
            closeCompanyModal();
        }
    };
    const openCompanyInfoModal = (companyInfo, isEdit) => {
        history.push(pagePath.updateCompanyInfoUrl);
        setSelectedCompanyInfo(companyInfo);
        setOpenCompanyInfoModal(true);
        setIsCompanyInfoEdit(true);
        setDescriptionCompanyInfo(companyInfo.description);
    };
    const closeCompanyModal = () => {
        setSelectedCompanyInfo({});
        setOpenCompanyInfoModal(false);
    };
    const onUploadImage = async (image) => {
        if (userLoginInfo?.id) {
            const res = await dispatch(uploadImage({id: userLoginInfo?.id, body: image}));

            if (res?.payload?.success) {
                return res?.payload?.data?.physical_path;
            }
        }
    };
    const fetchCompanyInfo = async () => {
        const res = await dispatch(getCompanyInfo()).unwrap();

        if (res?.success) {
            const {data} = res;
            setTotalPage(data?.total, data?.pageSize);
        }
    };

    useEffect(() => {
        fetchCompanyInfo();
    }, []);

    const companyContainerProps = {
        paginationData,
        activePage,
        navigateTo,
        setPage,
        setPageSize,
        handleSelectAllClick,
        handleSelectRow,
        checkedData,
        handleDeleteSelectedRows,
        history,
        companyInfo,
        openCompanyInfoModal,
        handleSubmitInvalid,
        isOpenCompanyInfoModal,
        isCompanyInfoEdit,
        selectedCompanyInfo,
        handleEditCompanyInfo,
        closeCompanyModal,
        setDescriptionCompanyInfo,
        descriptionCompanyInfo,
        onUploadImage
    };

    return <CompanyMainView {...propsProvider(companyContainerProps)} />;
};

export default CompanyContainer;
