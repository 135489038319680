import CustomToast from 'components/CustomToast';
import Config from 'configuration';
import {pagePath} from 'configuration/routeConfig';
import {logout} from 'features/Auth/authSlice';
import {handleChangePage, handleChangePageSize, handleChangeTotalPage, uploadImage} from 'features/Home/homeSlice';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {calcTotalPage} from 'utils/calcTotalPage';
import propsProvider from './newsPropsProvider';
import {addNews, deleteNews, deleteNewsImage, getNews, updateNews, uploadNewsImage} from './newsSlice';
import NewsMainView from './template/NewsMainView';
import {getBase64} from 'utils/getBase64';
const NewsContainer = (props) => {
    const {home, userLoginInfo, newsList} = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const {paginationData, activePage} = home;

    const [isOpenNewsModal, setOpenNewsModal] = useState(false);
    const [isNewsEdit, setIsNewsEdit] = useState(false);
    const [selectedNews, setSelectedNews] = useState({});
    const [searchNewsValue, setSearchNewsValue] = useState('');
    const [detailImageList, setDetailImageList] = useState([]);
    const [descriptionNews, setDescriptionNews] = useState('');
    const [checkedData, setCheckedData] = useState([]);

    const navigateTo = (path) => {
        try {
            if (path === pagePath.logoutUrl) {
                dispatch(logout()).then((res) => history.push(pagePath.authLoginUrl));
            } else {
                history.push(path);
            }
        } catch (e) {
            CustomToast(false, 'Có lỗi xảy ra');
        }
    };

    const setPage = (value) => {
        dispatch(handleChangePage({activePage: activePage, page: value}));
    };

    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage: activePage, pageSize: value}));
        setPage(1);
    };

    const setTotalPage = (count, pageSize) => {
        const value = calcTotalPage(count, pageSize);
        // console.log('value', value);
        dispatch(handleChangeTotalPage({activePage: activePage, totalPage: value}));
    };
    const handleAddNews = async (data) => {
        const {title, location, email, cell_phone, fromTime, toTime} = data;

        const payload = {
            title: title,
            description: descriptionNews,
            location: location,
            email: email,
            cell_phone: cell_phone,
            start_date: fromTime,
            end_date: toTime
        };
        dispatch(!isNewsEdit ? addNews(payload) : updateNews({id: selectedNews?.id, body: payload})).then((res) => {
            if (res?.payload?.success) {
                if (detailImageList.length > 0) {
                    let fileFormData = new FormData();
                    const file = detailImageList[0].file;
                    fileFormData.append('file', file);
                    dispatch(
                        uploadNewsImage({
                            id: !isNewsEdit ? res?.payload?.data?.id : selectedNews?.id,
                            body: fileFormData
                        })
                    ).then((res) => console.log('uploadNewsImage', res));
                    // navigateTo(pagePath.newsUrl);
                    fetchNews();
                    // closeNewsModal();
                } else {
                    if (selectedNews?.attachment_id) {
                        dispatch(deleteNewsImage(selectedNews?.id)).then((res) => fetchNews());
                    }
                }
                CustomToast(res?.payload?.success, `${!isNewsEdit ? 'Thêm' : 'Chỉnh sửa'} tin tức thành công`);
                fetchNews();
                navigateTo(pagePath.newsUrl);
                closeNewsModal();
            }
        });
    };

    const handleDeleteNews = async () => {
        const res = await dispatch(deleteNews(selectedNews?.id)).unwrap();
        console.log(res, selectedNews?.id);

        if (res?.success) {
            CustomToast(res?.success, 'Xóa tin tức thành công');
            fetchNews();
        } else {
            CustomToast(false, 'Xóa tin tức thất bại');
        }
        closeNewsModal();
    };

    const handleSelectRow = (event, id) => {};
    const handleDeleteSelectedRows = async () => {};

    const handleSubmitInvalid = (_, event) => {
        console.log('submit invalid');
        // event.target.classList.add('wasvalidated');
    };
    const handleSearchNews = () => {
        fetchNews();
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            let newSelected = [];
            // if (activePage === 0) {
            //     newSelected = programList.map((data) => data?.id);
            // } else {
            //     newSelected = sponsorList.map((data) => data?.id);
            // }
            setCheckedData(newSelected);
            return;
        }

        setCheckedData([]);
    };

    const handleUploadImageList = async (e) => {
        const file = e.target.files[0];
        const {size} = file;

        if (size > 5 * 1024 * 1024) {
            CustomToast(false, 'Tệp vượt quá giới hạn dung lượng cho phép');
            return;
        }

        const previewUrl = await getBase64(file);
        setDetailImageList([...detailImageList, {url: previewUrl, file}]);
    };

    const handleDeleteImageList = (e) => {
        const newFiles = detailImageList.filter((item) => item !== e);
        setDetailImageList(newFiles);
    };

    const onUploadImage = async (image) => {
        if (userLoginInfo?.id) {
            const res = await dispatch(uploadImage({id: userLoginInfo?.id, body: image}));

            if (res?.payload?.success) {
                return res?.payload?.data?.physical_path;
            }
        }
    };
    const openNewsModal = (program, isEdit) => {
        setIsNewsEdit(isEdit);
        setSelectedNews(program);
        setOpenNewsModal(true);
        setDescriptionNews(program?.description);

        isEdit &&
            setDetailImageList(
                program?.image_path
                    ? [
                          {
                              url: Config.apiConfig.endPoint + program?.image_path
                          }
                      ]
                    : []
            );
    };

    const closeNewsModal = () => {
        setSelectedNews({});
        setOpenNewsModal(false);
        setDescriptionNews();
        setIsNewsEdit(false);
    };
    const fetchNews = async () => {
        const resFetch = await dispatch(
            getNews({
                Filters: searchNewsValue ? `title@=${searchNewsValue}` : '',
                Sorts: '',
                Page: paginationData[activePage]?.page ?? 1,
                PageSize: paginationData[activePage]?.pageSize ?? 10
            })
        ).unwrap();
        if (resFetch?.success) {
            const {data} = resFetch;
            setTotalPage(data?.total, data?.pageSize);
        }
    };

    useEffect(() => {
        fetchNews();
    }, [paginationData]);

    const consultantContainerProps = {
        history,
        navigateTo,
        searchNewsValue,
        setSearchNewsValue,
        handleSearchNews,
        setPage,
        setPageSize,
        checkedData,
        handleSelectAllClick,
        handleSelectRow,
        handleDeleteSelectedRows,
        paginationData,
        activePage,
        newsList,
        openNewsModal,
        handleSubmitInvalid,
        handleAddNews,
        handleUploadImageList,
        handleDeleteImageList,
        detailImageList,
        descriptionNews,
        setDescriptionNews,
        onUploadImage,
        isOpenNewsModal,
        closeNewsModal,
        isNewsEdit,
        handleDeleteNews,
        selectedNews
    };

    return <NewsMainView {...propsProvider(consultantContainerProps)} />;
};

export default NewsContainer;
