import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import React, { Fragment, useState } from 'react';
import { Controller } from 'react-hook-form';
const { RangePicker } = DatePicker;
const GroupInput = (props) => {
    const dateFormat = 'DD/MM/YYYY HH:mm';
    const timeFormat = 'HH:mm';

    const { label, control, groupInputData = [] } = props;
    const [borderStatus, setBorderStatus] = useState('none');

    const mouseOverGroupInput = () => {
        if (borderStatus !== 'focus') {
            setBorderStatus('hover');
        }
    };
    const mouseOutGroupInput = () => {
        if (borderStatus !== 'focus') {
            setBorderStatus('none');
        }
    };

    const focusGroupInput = () => setBorderStatus('focus');
    const blurGroupInput = () => setBorderStatus('none');

    return (
        <div className='group-input' onMouseEnter={mouseOverGroupInput} onMouseLeave={mouseOutGroupInput}>
            <fieldset className={borderStatus}>
                <legend>
                    <span>{label}</span>
                </legend>
                <div className='group-input__container'>
                    {groupInputData.map((inputData, idx) => (
                        <Fragment key={inputData.name}>
                            <Controller
                                control={control}
                                name={inputData.name}
                                render={({ field: { value, onBlur, ...restField } }) => {
                                    const blurHandle = (e) => {
                                        blurGroupInput();
                                        onBlur(e);
                                    };
                                    const baseInputProps = {
                                        className: 'input-item',
                                        onClick: focusGroupInput,
                                        onBlur: blurHandle
                                    };

                                    return inputData.type === 'date' ? (
                                        <DatePicker
                                            {...restField}
                                            {...baseInputProps}
                                            placeholder={inputData.placeholder}
                                            format={dateFormat}
                                            value={value ? dayjs(value) : null}
                                            getPopupContainer={(triggerNode) => triggerNode}
                                            showTime={true}
                                        />
                                    ) : inputData.type === 'time' ? <TimePicker
                                        {...restField}
                                        {...baseInputProps}
                                        placeholder={inputData.placeholder}
                                        format={timeFormat}
                                        value={value ? dayjs(value) : null}
                                        getPopupContainer={(triggerNode) => triggerNode}
                                    /> : (
                                        <input
                                            {...restField}
                                            {...inputData}
                                            {...baseInputProps}
                                        />
                                    );
                                }}
                            />
                            {idx < groupInputData.length - 1 && <div className='input-divider'></div>}
                        </Fragment>
                    ))}
                </div>
            </fieldset>
        </div>
    );
};

export default GroupInput;
