import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';
const catalogueApi = {
    getCatalogue: (params) => {
        const url = '/api/catalogue';
        return axiosClient.get(url, { params });
    },
    addCatalogue: (data) => {
        const { file, type } = data;
        const url = `/api/catalogue?type=${type}`;
        return axiosFormData.post(url, file);
    },

    deleteCatalogue: (id) => {

        const url = `/api/catalogue/${id}`;
        return axiosClient.delete(url);
    }
};

export default catalogueApi;
