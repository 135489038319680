import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import consultantApi from 'api/consultantApi';
import serviceRequest from 'app/serviceRequest';

export const getListConsultant = createAsyncThunk('consultant/getListConsultant', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: consultantApi.getListConsultant,
        payload: data
    });
});

export const addConsultant = createAsyncThunk('consultant/addConsultant', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: consultantApi.addConsultant,
        payload: data
    });
});

export const updateConsultant = createAsyncThunk('consultant/updateConsultant', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: consultantApi.updateConsultant,
        payload: data
    });
});

export const deleteConsultant = createAsyncThunk('consultant/deleteConsultant', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: consultantApi.deleteConsultant,
        payload: data
    });
});

export const uploadConsultantAvatar = createAsyncThunk('consultant/uploadConsultantAvatar', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: consultantApi.uploadConsultantAvatar,
        payload: data
    });
});

const consultant = createSlice({
    name: 'consultant',
    initialState: {},
    reducers: {},
    extraReducers: (builders) => {
        builders.addCase(getListConsultant.fulfilled, (state, action) => {
            // console.log('action', action);
            state.consultants = action?.payload?.data?.collection;
        });
    }
});

const {reducer, actions} = consultant;
export default reducer;
