import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import checkInAPI from 'api/checkInApi';
import serviceRequest from 'app/serviceRequest';

export const getCheckInList = createAsyncThunk('checkIn/getCheckInList', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: checkInAPI.requestGetCheckInList,
        payload: data
    });
});
export const deleteUserCheckIn = createAsyncThunk('checkIn/deleteUserCheckIn', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: checkInAPI.requestDeleteCheckIn,
        payload: data
    });
});
export const changeCheckInUser = createAsyncThunk('checkIn/changeCheckInUser', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: checkInAPI.requestChangeCheckInUser,
        payload: data
    });
});
export const addNewCheckInCustomer = createAsyncThunk('checkIn/addNewCheckInCustomer', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: checkInAPI.requestAddNewCheckInCustomer,
        payload: data
    });
});
export const checkExistEmail = createAsyncThunk('checkIn/checkExistEmail', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: checkInAPI.requestCheckExistEmail,
        payload: data
    });
});
export const registerProgram = createAsyncThunk('checkIn/addNewCheckInCustomer', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: checkInAPI.requestProgramRegister,
        payload: data
    });
});
export const importUserCheckIn = createAsyncThunk('checkIn/importUserCheckIn', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: checkInAPI.requestImportUserCheckIn,
        payload: data
    });
});
const checkIn = createSlice({
    name: 'checkIn',
    initialState: {},
    reducers: {
        handleUpdateCheckInStatus: (state, payload) => {
            state.checkInList[payload.payload.position].is_checked =
                !state.checkInList[payload.payload.position].is_checked;
        }
    },
    extraReducers: (builders) => {
        builders.addCase(getCheckInList.fulfilled, (state, action) => {
            state.checkInList = action?.payload?.data?.collection;
        });
    }
});

const {reducer, actions} = checkIn;
export const {handleUpdateCheckInStatus} = actions;
export default reducer;
