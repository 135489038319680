import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import store from 'app/store';
import { StyledEngineProvider } from '@mui/material';
import Routers from 'routers';
import './_styles.scss';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StyledEngineProvider injectFirst>
    <Provider store={store}>
      <Routers />
    </Provider>
  </StyledEngineProvider>
);