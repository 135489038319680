import {Table, TableBody, TableContainer} from '@mui/material';
import {Add} from 'assets';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomPageSize from 'components/CustomPageSize/CustomPageSize';
import CustomTableRow from 'components/CustomTableRow/CustomTableRow';
import CustomPagination from 'components/Pagination/Pagination';
import {pagePath} from 'configuration/routeConfig';
import ActionBar from 'features/Home/template/subViews/ActionBar/ActionBar';
import CustomTableHead from 'components/CustomTableHead/CustomTableHead';
const CatalogueList = (props) => {
    const {
        navigateTo,
        checkedData = [],
        handleDeleteSelectedRows,
        pageSize,
        headCells,
        setPageSize,
        handleSelectRow,
        setPage,
        totalPage,
        page = 0,
        tableData,
        catalogueList,
        handleSelectAllClick
    } = props;
    return (
        <CustomCard title='Danh sách Catalogue, Sơ đồ gian hàng, Thông cáo báo chí'>
            <div className='d-flex align-items-center gap-2'>
                <CustomButton className='flex-shrink-0' onClick={() => navigateTo(pagePath.catalogueCreateUrl)}>
                    <Add />
                    Thêm catalogue
                </CustomButton>
                <CustomButton className='flex-shrink-0' onClick={() => navigateTo(pagePath.diagramCreateUrl)}>
                    <Add />
                    Sơ đồ gian hàng
                </CustomButton>
                <CustomButton className='flex-shrink-0' onClick={() => navigateTo(pagePath.releaseCreateUrl)}>
                    <Add />
                    Thông cáo báo chí
                </CustomButton>
            </div>
            <div className='mt-3'>
                {checkedData.length > 0 && (
                    <ActionBar
                        selectedRow={checkedData.length}
                        rowCount={catalogueList.length}
                        handleMultiAction={handleDeleteSelectedRows}
                    />
                )}
            </div>
            <div className='mt-3'>
                <TableContainer className='program-table'>
                    <Table aria-labelledby='tableTitle'>
                        <CustomTableHead
                            handleCheckAll={handleSelectAllClick}
                            headCells={headCells}
                            withCheckbox
                            selectedRows={checkedData.length}
                            rowCount={tableData.length}
                        />
                        <TableBody>
                            {tableData &&
                                tableData.map((row, idx) => {
                                    const isItemSelected = checkedData.includes(row.id);
                                    return (
                                        <CustomTableRow
                                            key={idx}
                                            rowData={row}
                                            selected={isItemSelected}
                                            handleSelectRow={handleSelectRow}
                                        />
                                    );
                                })}
                        </TableBody>
                    </Table>
                    {tableData && (
                        <div className='table-pagination'>
                            <CustomPagination setPage={setPage} totalPage={totalPage} currentPage={page} />
                            <CustomPageSize
                                label={'Số dòng trên trang'}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pageSizeRange={[5, 10, 15, 20, 25]}
                                id={'temp'}
                            />
                        </div>
                    )}
                </TableContainer>
            </div>
        </CustomCard>
    );
};

export default CatalogueList;
