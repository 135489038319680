
import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';
const companyInfoApi = {
    getAllCompanyInfo: () => {
        const url = '/api/companyInfo/getAllCompany';
        return axiosClient.get(url);
    },
    updateCompanyInfo: (data) => {
        const { id, body } = data;
        const url = `/api/companyInfo/companyInfo/${id}`;
        return axiosClient.put(url, body);
    },

};

export default companyInfoApi;
