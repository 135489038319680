import {FormControl, FormHelperText} from '@mui/material';
import {resetpw} from 'assets';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import {pagePath} from 'configuration/routeConfig';
import {handleSetEmail} from 'features/Auth/authSlice';
import React, {useLayoutEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {formatQuantity} from 'utils/formatQuantity';

const defaultValues = {
    password: '',
    repassword: ''
};
const minLength = 8;

const AuthSetPassword = (props) => {
    const {handleSetPasswordSubmit, navigateTo, handleInvalidSubmit, countdowns, email, setIsSentEmail, dispatch} =
        props;
    const {
        handleSubmit,
        control,
        watch,
        getValues,
        formState: {errors}
    } = useForm({defaultValues});
    const authInputs = [
        {
            name: 'password',
            label: 'Mật khẩu mới',
            placeholder: 'Vui lòng nhập mật khẩu mới của bạn',
            type: 'password',
            isHavingPasswordIcon: true,
            rules: {minLength}
        },
        {
            name: 'repassword',
            label: 'Xác nhận mật khẩu',
            placeholder: 'Vui lòng nhập lại mật khẩu mới của bạn',
            type: 'password',
            isHavingPasswordIcon: true,
            rules: {
                minLength,
                validate: (value) => value === getValues('password') || 'Mật khẩu không khớp, xin vui lòng nhập lại!'
            }
        }
    ];

    const isFormValid = watch(['password', 'repassword']).every((value) => value.length > 0);

    useLayoutEffect(() => {
        if (!email || email.length <= 0) {
            navigateTo(pagePath.authLoginUrl);
        }
    }, [email]);

    return (
        <form method='POST' action='#' noValidate onSubmit={handleSubmit(handleSetPasswordSubmit, handleInvalidSubmit)}>
            {countdowns === null ? (
                <>
                    <h4>Thiết lập mật khẩu</h4>

                    <div className='form-content d-flex flex-column mb-4'>
                        {authInputs.map((input) => {
                            const {name, rules, ...validInput} = input;

                            return (
                                <FormControl key={name} className='form-content__control'>
                                    <Controller
                                        name={name}
                                        control={control}
                                        rules={rules}
                                        render={({field}) => (
                                            <CustomInput {...field} {...validInput} valid={!errors[name]} />
                                        )}
                                    />
                                    <FormHelperText className='form-content__helper' error={!!errors[name]}>
                                        {errors[name]?.message
                                            ? errors[name]?.message
                                            : `Mật khẩu bao gồm ít nhất ${minLength} ký tự`}
                                    </FormHelperText>
                                </FormControl>
                            );
                        })}
                    </div>

                    <CustomButton disabled={!isFormValid} fullwidth>
                        Hoàn thành
                    </CustomButton>
                </>
            ) : (
                <div className='d-flex flex-column align-items-center'>
                    <h4 className='text-success'>Thiết lập mật khẩu thành công!</h4>

                    <span className='text-redirect'>
                        Hệ thống sẽ tự điều hướng đến trang đăng nhập sau
                        <span className='text-redirect__timer'> 00:{formatQuantity(countdowns)}</span>
                    </span>
                    <span
                        className='text-variant'
                        onClick={() => {
                            dispatch(handleSetEmail(''));
                            setIsSentEmail(false);
                            navigateTo(pagePath.authLoginUrl);
                        }}>
                        Điều hướng ngay
                    </span>

                    <img src={resetpw} alt='' />
                </div>
            )}
        </form>
    );
};

export default AuthSetPassword;
