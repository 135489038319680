import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';
const bannerApi = {
    getBannerList: (params) => {
        const url = '/api/banner';
        return axiosClient.get(url, {params});
    },
    addBanner: (data) => {
        const {file, type} = data;
        const url = `/api/banner?type=${type}`;
        return axiosFormData.post(url, file);
    },

    deleteBanner: (id) => {
        const url = `/api/banner/${id}`;
        return axiosClient.delete(url);
    }
};

export default bannerApi;
