import {authSelector, homeSelector, programSelector} from 'app/selectors';
import {useSelector} from 'react-redux';
import ProgramContainer from './programContainer';

const Program = (props) => {
    const home = useSelector(homeSelector);
    const auth = useSelector(authSelector);
    const programSelect = useSelector(programSelector);

    const {programList} = programSelect;
    const {userLoginInfo} = auth;

    const programContainerProps = {
        home,
        userLoginInfo,
        programList
    };

    return <ProgramContainer {...programContainerProps} />;
};

export default Program;
