import {authSelector, homeSelector, newsSelector} from 'app/selectors';
import {useSelector} from 'react-redux';
import NewsContainer from './newsContainer';
const News = (props) => {
    const home = useSelector(homeSelector);
    const auth = useSelector(authSelector);
    const newsSelect = useSelector(newsSelector);

    const {newsList = []} = newsSelect;
    const {userLoginInfo} = auth;
    const newsContainerProps = {
        home,
        userLoginInfo,
        newsList
    };

    return <NewsContainer {...newsContainerProps} />;
};

export default News;
