import {Delete, Edit} from 'assets';

export default function propsProvider(props) {
    const {
        history,
        navigateTo,
        searchNewsValue,
        setSearchNewsValue,
        handleSearchNews,
        setPage,
        setPageSize,
        checkedData,
        handleSelectAllClick,
        handleSelectRow,
        handleDeleteSelectedRows,
        paginationData,
        activePage,
        newsList,
        openNewsModal,
        handleSubmitInvalid,
        handleAddNews,
        handleUploadImageList,
        handleDeleteImageList,
        detailImageList,
        descriptionNews,
        setDescriptionNews,
        onUploadImage,
        isOpenNewsModal,
        closeNewsModal,
        isNewsEdit,
        handleDeleteNews,
        selectedNews
    } = props;
    const {page, pageSize, totalPage = 1} = paginationData[activePage] || {};
    const newsTableData =
        newsList &&
        newsList.map((el) => ({
            id: el?.id,
            title: {value: el?.title, isshow: true},
            // description: { value: el?.description, isshow: true },
            location: {value: el?.location, isshow: true},
            email: {value: el?.email, isshow: true},
            cell_phone: {value: el?.cell_phone, isshow: true},
            actions: {
                value: (
                    <div className='d-flex justify-content-center gap-3'>
                        <Edit className='cursor-pointer' onClick={() => openNewsModal(el, true)} />
                        <Delete className='cursor-pointer' onClick={() => openNewsModal(el, false)} />
                    </div>
                ),
                isshow: true
            },
            programObj: {
                ...el
            }
        }));
    const newsHeadCells = [
        {
            id: 'Tiêu đề',
            align: 'left',
            padding: 'normal',
            label: 'tiêu đề'
        },
        // {
        //     id: 'Mô tả',
        //     align: 'left',
        //     padding: 'normal',
        //     label: 'Mô tả'
        // },
        {
            id: 'Địa điểm',
            align: 'left',
            padding: 'normal',
            label: 'Địa điểm'
        },
        {
            id: 'Email',
            align: 'left',
            padding: 'normal',
            label: 'Email'
        },
        {
            id: 'Điện thoại',
            align: 'left',
            padding: 'normal',
            label: 'Điện thoại'
        },
        {
            id: 'Thao tác',
            align: 'center',
            padding: 'normal',
            label: 'Thao tác'
        }
    ];
    return {
        history,
        newsListProps: {
            navigateTo,
            searchNewsValue,
            setSearchNewsValue,
            handleSearchNews,
            headCells: newsHeadCells,
            tableData: newsTableData,
            setPage,
            setPageSize,
            page,
            pageSize,
            totalPage,
            checkedData,
            handleSelectAllClick,
            handleSelectRow,
            handleDeleteSelectedRows
        },
        addNewsProps: {
            handleSubmitInvalid,
            handleAddNews,
            navigateTo,
            handleUploadImageList,
            handleDeleteImageList,
            detailImageList,
            descriptionNews,
            setDescriptionNews,
            onUploadImage
        },
        editNewsProps: {
            isOpenNewsModal,
            closeNewsModal,
            isNewsEdit,
            handleAddNews,
            handleSubmitInvalid,
            handleDeleteNews,
            selectedNews,
            descriptionNews,
            setDescriptionNews,
            onUploadImage,
            detailImageList,
            handleDeleteImageList,
            handleUploadImageList
        }
    };
}
