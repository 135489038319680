import {Delete, Edit} from 'assets';

export default function propsProvider(props) {
    const {
        history,
        navigateTo,
        bannerList,
        checkedData,
        handleDeleteSelectedRows,
        setPageSize,
        handleSelectRow,
        setPage,
        openBannerModal,
        paginationData,
        activePage,
        handleAddBanner,
        handleSubmitInvalid,
        detailImage,
        handleUploadImages,
        handleDeleteImage,
        isOpenBannerModal,
        closeBannerModal,
        handleDeleteBanner
    } = props;

    const {page, pageSize, totalPage = 1} = paginationData[activePage] || {};
    const bannerTableData =
        bannerList &&
        bannerList.map((el) => ({
            id: el?.id,
            image: {value: el?.image_path, isshow: true, align: 'center'},
            name: {value: el?.file_name, isshow: true},
            actions: {
                value: (
                    <div className='d-flex justify-content-center gap-3'>
                        <Delete className='cursor-pointer' onClick={() => openBannerModal(el, false)} />
                    </div>
                ),
                isshow: true
            },
            bannerObj: {
                ...el
            }
        }));
    return {
        history,
        bannerListProps: {
            navigateTo,
            bannerList,
            checkedData,
            handleDeleteSelectedRows,
            pageSize,
            setPageSize,
            handleSelectRow,
            setPage,
            totalPage,
            page,
            tableData: bannerTableData
        },
        addBannerProps: {
            handleAddBanner,
            handleSubmitInvalid,
            navigateTo,
            detailImage,
            handleUploadImages,
            handleDeleteImage
        },
        editBannerProps: {
            isOpenBannerModal,
            closeBannerModal,
            handleDeleteBanner
        }
    };
}
