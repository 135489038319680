import {authSelector, homeSelector, bannerSelector} from 'app/selectors';
import {useSelector} from 'react-redux';
import BannerContainer from './bannerContainer';
const Banner = (props) => {
    const home = useSelector(homeSelector);
    const auth = useSelector(authSelector);
    const bannerSelect = useSelector(bannerSelector);

    const {bannerList = []} = bannerSelect;
    const {userLoginInfo} = auth;
    const bannerContainerProps = {
        home,
        userLoginInfo,
        bannerList
    };

    return <BannerContainer {...bannerContainerProps} />;
};

export default Banner;
