import {FormControl} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';

const defaultValues = {
    email: ''
};

const AuthEnterEmail = (props) => {
    const {handleConfirmEmailSubmit, isSentEmail, goBack} = props;

    const {
        handleSubmit,
        control,
        watch,
        formState: {isSubmitting}
    } = useForm({defaultValues});
    const isFormValid = watch('email').length > 0 && !isSubmitting;

    const authInputs = [
        {
            name: 'email',
            label: 'Email',
            placeholder: 'Nhập email của bạn tại đây',
            type: 'text',
            disabled: isSubmitting
        }
    ];

    return (
        <form method='POST' action='#' noValidate onSubmit={handleSubmit(handleConfirmEmailSubmit)}>
            <h4 className='mb-1'>Quên mật khẩu</h4>
            <p className='form-subTitle'>
                Chúng tôi sẽ gửi link khôi phục mật khẩu vào hộp thư của bạn, bạn vui lòng nhập email bên dưới
            </p>

            <div className='form-content d-flex flex-column'>
                {authInputs.map((input) => {
                    const {name} = input;

                    return (
                        <FormControl key={name} className='form-content__control'>
                            <Controller
                                name={name}
                                control={control}
                                render={({field}) => <CustomInput {...field} {...input} />}
                            />
                        </FormControl>
                    );
                })}
            </div>

            <CustomButton disabled={!isFormValid} fullwidth className='mb-2'>
                {isSentEmail ? 'Đã gửi' : 'Gửi'}
            </CustomButton>
            <CustomButton type='button' variant='text' fullwidth onClick={goBack}>
                Trở về
            </CustomButton>
        </form>
    );
};

export default AuthEnterEmail;
