import ModalPopup from 'components/ModalPopup/ModalPopup';
import ConsultantList from './ConsultantList';
import Home from 'features/Home';
import {pagePath} from 'configuration/routeConfig';
import AddConsultant from './AddConsultant';
import EditConsultant from './EditConsultant';

const ConsultantMainView = (props) => {
    const {modalContent, consultantListProps, history, addConsultantProps, editConsultantProps} = props;
    let ConsultantComponent = null;
    switch (history.location.pathname) {
        case pagePath.consultantUrl:
            ConsultantComponent = <ConsultantList {...consultantListProps} />;
            break;
        case pagePath.consultantCreateUrl:
            ConsultantComponent = <AddConsultant {...addConsultantProps} />;
            break;
        default:
            ConsultantComponent = <ConsultantList {...consultantListProps} />;
            break;
    }
    return (
        <Home>
            <EditConsultant {...editConsultantProps} />
            <ModalPopup {...modalContent} />
            {ConsultantComponent}
        </Home>
    );
};

export default ConsultantMainView;
