import Home from 'features/Home';
import CustomerList from './subViews/CustomerList';
import {pagePath} from 'configuration/routeConfig';
import AddCustomer from './subViews/AddCustomer';
import EditCustomer from './subViews/EditCustomer';
import AddTags from './subViews/AddTags';
import ImportCustomer from './subViews/ImportCustomer';
const CustomerMainView = (props) => {
    const {history, customerListProps, addCustomerProps, editCustomerProps, addTagProps, importCustomerProps} = props;
    let CustomerComponent = null;
    switch (history.location.pathname) {
        case pagePath.customerURL:
            CustomerComponent = <CustomerList {...customerListProps} />;
            break;
        case pagePath.customerCreateURL:
            CustomerComponent = <AddCustomer {...addCustomerProps} />;
            break;
        case pagePath.tagCreateUrl:
            CustomerComponent = <AddTags {...addTagProps} />;
            break;
        default:
            CustomerComponent = <CustomerList {...customerListProps} />;
            break;
    }
    return (
        <Home>
            <ImportCustomer {...importCustomerProps} />
            <EditCustomer {...editCustomerProps} />
            {CustomerComponent}
        </Home>
    );
};

export default CustomerMainView;
