import {Checkbox, FormControl, FormControlLabel} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import {pagePath} from 'configuration/routeConfig';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';

const defaultValues = {
    email: '',
    password: '',
    isSaved: false
};

const AuthLogin = (props) => {
    const {handleLoginSubmit, navigateTo} = props;
    const authInputs = [
        {
            name: 'email',
            label: 'Email hoặc tên người dùng',
            placeholder: 'Vui lòng nhập email bạn đã đăng ký',
            type: 'text'
        },
        {
            name: 'password',
            label: 'Mật khẩu',
            placeholder: 'Vui lòng nhập mật khẩu của bạn',
            type: 'password',
            isHavingPasswordIcon: true
        }
    ];
    const {handleSubmit, control, watch} = useForm({defaultValues});
    const isFormValid = watch(['email', 'password']).every((value) => value.length > 0);

    return (
        <form method='POST' action='#' noValidate onSubmit={handleSubmit(handleLoginSubmit)}>
            <h4>Chào mừng trở lại!</h4>

            <div className='form-content d-flex flex-column'>
                {authInputs.map((input) => {
                    const {name} = input;

                    return (
                        <FormControl key={name} className='form-content__control'>
                            <Controller
                                name={name}
                                control={control}
                                render={({field}) => <CustomInput {...field} {...input} />}
                            />
                        </FormControl>
                    );
                })}
            </div>

            <div className='form-helper'>
                <FormControlLabel control={<Checkbox />} label='Lưu tài khoản' />
                <div className='form-helper__variant' onClick={() => navigateTo(pagePath.authForgotPasswordUrl)}>
                    Quên mật khẩu?
                </div>
            </div>

            <CustomButton disabled={!isFormValid} fullwidth>
                Đăng nhập
            </CustomButton>
        </form>
    );
};

export default AuthLogin;
