export default function propsProvider(props) {
    const {
        navigateTo,
        handleInvalidSubmit,
        handleLoginSubmit,
        handleConfirmEmailSubmit,
        handleSetPasswordSubmit,
        isSentEmail,
        goBack,
        countdowns,
        email,
        dispatch,
        handleDeleteAccount,
        onSendOtpDelete,
        openFormOTP,
        isSentDeleteOtp,
        goBackSendEmailDelete
    } = props;

    return {
        authLoginProps: {navigateTo, handleInvalidSubmit, handleLoginSubmit},
        enterEmailProps: {handleConfirmEmailSubmit, isSentEmail, goBack},
        setPasswordProps: {
            handleSetPasswordSubmit,
            navigateTo,
            handleInvalidSubmit,
            countdowns,
            isSentEmail,
            email,
            dispatch
        },
        deleteAccountProps: {
            handleDeleteAccount,
            navigateTo,
            handleInvalidSubmit,
            onSendOtpDelete,
            openFormOTP,
            isSentDeleteOtp,
            goBackSendEmailDelete
        }
    };
}
