import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';
const checkInAPI = {
    requestGetCheckInList: (params) => {
        const url = '/api/userchecking/getAll';
        return axiosClient.get(url, {params});
    },
    requestDeleteCheckIn: (id) => {
        const url = `/api/userchecking/${id}`;
        return axiosClient.delete(url);
    },
    requestChangeCheckInUser: ({id, payload}) => {
        const url = `/api/userchecking/${id}`;
        return axiosClient.put(url, payload);
    },
    requestAddNewCheckInCustomer: (payload) => {
        const url = `/api/userchecking`;
        return axiosClient.post(url, payload);
    },
    requestCheckExistEmail: (email) => {
        const url = `/api/user/email?email=${email}`;
        return axiosClient.get(url);
    },
    requestProgramRegister: (payload) => {
        const url = `/api/userchecking/program_register`;
        return axiosClient.post(url, payload);
    },
    requestImportUserCheckIn: ({file}) => {
        const url = `/api/userchecking/uploadUserCheckingFile`;
        return axiosFormData.post(url, file);
    }
};

export default checkInAPI;
