import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React, { forwardRef } from 'react';

const CustomDatePicker = forwardRef((props, ref) => {
    const { label, isRequired, value, showTime = false, ...restProps } = props;
    const dateFormat = showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
    let displayLabel = isRequired ? (
        <span>
            {label} <span className='text-required'>*</span>
        </span>
    ) : (
        <span>{label}</span>
    );

    return (
        <div className='custom-datepicker'>
            {displayLabel}

            <DatePicker
                className='custom-datepicker__input'
                value={value ? dayjs(value) : null}
                ref={ref}
                getPopupContainer={(triggerNode) => triggerNode}
                {...restProps}
                format={dateFormat}
                showTime={showTime}
            />
        </div>
    );
});

export default CustomDatePicker;
