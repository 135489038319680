import {Delete, Edit} from 'assets';

export default function propsProvider(props) {
    const {
        paginationData,
        activePage,
        navigateTo,
        setPage,
        setPageSize,
        handleSelectAllClick,
        handleSelectRow,
        checkedData,
        handleDeleteSelectedRows,
        history,
        companyInfo,
        openCompanyInfoModal,
        handleSubmitInvalid,
        isOpenCompanyInfoModal,
        isCompanyInfoEdit,
        selectedCompanyInfo,
        handleEditCompanyInfo,
        closeCompanyModal,
        setDescriptionCompanyInfo,
        descriptionCompanyInfo,
        onUploadImage
    } = props;

    const {page, pageSize, totalPage = 1} = paginationData[activePage] || {};
    const companyInfoHeadCells = [
        {
            id: 'Giới thiệu',
            align: 'left',
            padding: 'normal',
            label: 'Giới thiệu'
        },
        // {
        //     id: 'Chi tiết',
        //     align: 'left',
        //     padding: 'normal',
        //     label: 'Chi tiết'
        // },
        {
            id: 'Thao tác',
            align: 'center',
            padding: 'normal',
            label: 'Thao tác'
        }
    ];
    const companyTableData =
        companyInfo &&
        companyInfo.map((el) => ({
            id: el?.id,
            introduce: {value: el?.introduce, isshow: true},
            // description: { value: el?.description, isshow: true },
            actions: {
                value: (
                    <div className='d-flex justify-content-center gap-3'>
                        <Edit className='cursor-pointer' onClick={() => openCompanyInfoModal(el, true)} />
                    </div>
                ),
                isshow: true
            }
        }));

    return {
        history,
        companyProps: {
            page,
            pageSize,
            totalPage,
            setPage,
            setPageSize,
            headCells: companyInfoHeadCells,
            tableData: companyTableData,
            handleSelectAllClick,
            handleSelectRow,
            checkedData,
            handleDeleteSelectedRows
        },
        updateCompanyProps: {
            handleSubmitInvalid,
            isOpenCompanyInfoModal,
            isCompanyInfoEdit,
            selectedCompanyInfo,
            handleEditCompanyInfo,
            closeCompanyModal,
            setDescriptionCompanyInfo,
            descriptionCompanyInfo,
            onUploadImage
        }
    };
}
