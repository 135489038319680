import React from 'react';
import ConsultantContainer from './consultantContainer';
import {useSelector} from 'react-redux';
import {homeSelector, consultantSelector, authSelector} from 'app/selectors';

const Consultant = (props) => {
    const home = useSelector(homeSelector);
    const consultant = useSelector(consultantSelector);
    const auth = useSelector(authSelector);
    const {userLoginInfo} = auth;
    const {consultants} = consultant;
    const consultantContainerProps = {
        home,
        consultants,
        userLoginInfo
    };

    return <ConsultantContainer {...consultantContainerProps} />;
};

export default Consultant;
