import React from 'react';
import HomeContainer from './HomeContainer';

const Home = (props) => {
    const {} = props;
    const homeContainerProps = {
        ...props
    };

    return <HomeContainer {...homeContainerProps} />;
};

export default Home;
