import {authSelector, customerSelector, homeSelector} from 'app/selectors';
import {useSelector} from 'react-redux';
import CustomerContainer from './customerContainer';
const Customer = (props) => {
    const home = useSelector(homeSelector);
    const customerSelect = useSelector(customerSelector);

    const {customerList = [], tagList = []} = customerSelect;
    const customerContainerProps = {
        home,
        customerList,
        tagList
    };

    return <CustomerContainer {...customerContainerProps} />;
};

export default Customer;
