import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import {Close} from 'assets';
import {Controller, useForm} from 'react-hook-form';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomDatePicker from 'components/CustomDatePicker';
import GroupInput from 'components/GroupInput/GroupInput';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomTextEditor from 'components/CustomTextEditor';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import InputFiles from 'components/InputFiles/InputFiles';
import CustomSelect from 'components/CustomSelect/CustomSelect';
const defaultValues = {
    full_name: '',
    work_unit: '',
    cell_phone: '',
    email: '',
    note: '',
    tags: ''
};

const EditCustomer = (props) => {
    const {
        handleSubmitInvalid,
        isOpenCustomerModal,
        isCustomerEdit,
        selectedCustomer,
        handleAddCustomer,
        closeCustomerModal,
        handleDeleteCustomer,
        tagList
    } = props;
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch,
        reset,
        setValue
    } = useForm({defaultValues});

    const customerInputs = [
        {
            label: 'Họ tên',
            placeholder: 'Nhập họ tên',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'full_name'
        },
        {
            label: 'Nơi làm việc',
            placeholder: 'Nhập nơi làm việc',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'unit_name'
        },
        {
            label: 'Số điện thoại',
            placeholder: 'Nhập số điện thoại',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'phone_number'
        },
        {
            label: 'Địa chỉ email',
            placeholder: 'Nhập địa chỉ email',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'email'
        },
        {
            label: 'Tags',
            placeholder: 'Tags',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'select',
            name: 'tags',
            options:
                tagList?.length > 0 &&
                tagList.map((item) => {
                    return {id: item.id, value: item.name};
                })
        },
        {
            label: 'Ghi chú',
            placeholder: 'Nhập ghi chú',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'note'
        }
    ];

    useEffect(() => {
        reset();
    }, [isOpenCustomerModal]);

    useEffect(() => {
        if (isCustomerEdit && selectedCustomer) {
            setValue('full_name', selectedCustomer?.full_name);
            setValue('phone_number', selectedCustomer?.phone_number);
            setValue('unit_name', selectedCustomer?.unit_name);
            setValue('email', selectedCustomer?.email);
            setValue('note', selectedCustomer?.note);
            setValue('tags', selectedCustomer?.tag_id);
        }
    }, [isOpenCustomerModal, selectedCustomer]);

    return (
        <>
            <Dialog
                maxWidth={isCustomerEdit ? 'sm' : 'xs'}
                fullWidth={true}
                open={isOpenCustomerModal}
                onClose={closeCustomerModal}
                aria-labelledby='voucher-modal'
                aria-describedby='apply-voucher-to-get-discount'
                className='dialog'>
                <form
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (isCustomerEdit) {
                            handleSubmit(handleAddCustomer, handleSubmitInvalid)();
                        } else {
                            handleDeleteCustomer();
                        }
                    }}>
                    <DialogTitle className='dialog-title' id='voucher-modal'>
                        <span>{isCustomerEdit ? 'Chỉnh sửa thông tin khách hàng' : 'Xác nhận'}</span>
                        <button className='dialog-title__close'>
                            <Close
                                className='cursor-pointer'
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeCustomerModal();
                                }}
                            />
                        </button>
                    </DialogTitle>

                    <DialogContent className='dialog-content' style={{padding: '32px 24px'}}>
                        <div className={`${isCustomerEdit ? 'add-program' : ''} `}>
                            {!isCustomerEdit
                                ? 'Bạn có chắc muốn xóa khách hàng này?'
                                : customerInputs.map((input, idx) => {
                                      const {type, rules, groupInputData, label, name, value, ...restProps} = input;

                                      return (
                                          <FormControl key={idx}>
                                              {type !== 'group' ? (
                                                  <Controller
                                                      name={name}
                                                      control={control}
                                                      rules={rules}
                                                      key={idx}
                                                      render={({field}) =>
                                                          type !== 'select' ? (
                                                              <CustomInput {...field} {...restProps} label={label} />
                                                          ) : (
                                                              <CustomSelect
                                                                  label={label}
                                                                  {...field}
                                                                  {...restProps}
                                                                  type={type}
                                                              />
                                                          )
                                                      }
                                                  />
                                              ) : (
                                                  <GroupInput
                                                      control={control}
                                                      label={label}
                                                      key={idx}
                                                      groupInputData={groupInputData}
                                                  />
                                              )}
                                          </FormControl>
                                      );
                                  })}
                        </div>
                    </DialogContent>
                    <DialogActions className='dialog-actions'>
                        <CustomButton
                            variant='text'
                            onClick={(e) => {
                                e.preventDefault();
                                closeCustomerModal();
                            }}>
                            Hủy
                        </CustomButton>
                        <CustomButton
                            variant='contained'
                            type='submit'
                            color={!isCustomerEdit ? 'danger' : 'primary'}
                            // disabled={!isFormValid && isSponsorEdit}
                        >
                            {isCustomerEdit ? 'Lưu thay đổi' : 'Đồng ý'}
                        </CustomButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default EditCustomer;
