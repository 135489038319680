import {Trash, excelIcon} from 'assets';
import React from 'react';
const PreviewExcel = ({fileName, onDeleteExcel}) => {
    return (
        <div className='preview-image'>
            <img src={excelIcon} />
            <p>{fileName}</p>
            {onDeleteExcel && (
                <div className='preview-image__wrapper'>
                    <Trash onClick={onDeleteExcel} />
                </div>
            )}
        </div>
    );
};

export default PreviewExcel;
