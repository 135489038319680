import {FormControl, FormHelperText} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomCard from 'components/CustomCard';
import CustomInput from 'components/CustomInput/CustomInput';
import InputFiles from 'components/InputFiles/InputFiles';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import {pagePath} from 'configuration/routeConfig';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {pdfIcon, Trash} from 'assets';
const defaultValues = {
    pdf: {},
    fileName: '',
    typeImage: {}
};

const AddNewPressRelease = (props) => {
    const {handleSubmitInvalid, navigateTo, handleUploadPdf, pdfUpload, handleDeletePdf, handleAddPdf} = props;
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch
    } = useForm({defaultValues});

    const isFormValid = watch(['pdf']).every((value) => value.length > 0);
    return (
        <CustomCard title='Thông tin thông cáo báo chí'>
            <form
                className='mt-2 add-sponsor'
                method='POST'
                action='#'
                noValidate
                onSubmit={handleSubmit(handleAddPdf, handleSubmitInvalid)}>
                <div className='mb-3'>
                    <div className='d-flex align-items-center flex-wrap gap-3'>
                        {pdfUpload ? (
                            // <PreviewImage image={detailImage?.url} onDeleteImage={handleDeleteImage} />
                            <div>
                                <img src={pdfIcon} />
                                <p>{pdfUpload.name}</p>
                                <div>
                                    <Trash onClick={handleDeletePdf} />
                                </div>
                            </div>
                        ) : (
                            <InputFiles
                                type='file'
                                inputNotes={['Định dạng tệp PDF', 'Kích thước: tối đa 100MB']}
                                accept={['.pdf']}
                                handleUpload={handleUploadPdf}
                                name='pdf'
                                control={control}
                            />
                        )}
                    </div>

                    {errors['images']?.message && <FormHelperText error>{errors['images']?.message}</FormHelperText>}
                </div>

                <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                    <CustomButton variant='outlined' onClick={() => navigateTo(pagePath.catalogueUrl)} type='button'>
                        Hủy
                    </CustomButton>
                    <CustomButton variant='contained' type='submit' disabled={!isFormValid}>
                        Lưu
                    </CustomButton>
                </div>
            </form>
        </CustomCard>
    );
};

export default AddNewPressRelease;
