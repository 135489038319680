import {Delete, Edit} from 'assets';

export default function propsProvider(props) {
    const {
        history,
        navigateTo,
        customerList,
        checkedData,
        handleDeleteSelectedRows,
        setPageSize,
        handleSelectRow,
        setPage,
        paginationData,
        activePage,
        searchCustomerValue,
        setSearchCustomerValue,
        fetchCustomerList,
        openCustomerModal,
        handleSubmitInvalid,
        isOpenCustomerModal,
        isCustomerEdit,
        selectedCustomer,
        handleAddCustomer,
        closeCustomerModal,
        handleDeleteCustomer,
        handleAddTag,
        tagList,
        errorText,
        handleUploadExcel,
        handleConvertData,
        isOpenImportCustomerModal,
        setIsOpenImportCustomer,
        onDeleteExcel,
        importExcelData,
        onSubmitImportDataExcel,
        closeImportCustomer,
        handleChangeFilterByTag
    } = props;

    const {page, pageSize, totalPage = 1} = paginationData[activePage] || {};

    const headCells = [
        {
            id: 'STT',
            align: 'left',
            padding: 'normal',
            label: 'STT'
        },
        {
            id: 'Họ và tên',
            align: 'left',
            padding: 'normal',
            label: 'Họ và tên'
        },
        {
            id: 'Đơn vị',
            align: 'left',
            padding: 'normal',
            label: 'Đơn vị'
        },
        {
            id: 'Số điện thoại',
            align: 'left',
            padding: 'normal',
            label: 'Số điện thoại'
        },
        {
            id: 'Email',
            align: 'left',
            padding: 'normal',
            label: 'Email'
        },
        {
            id: 'Tags',
            align: 'left',
            padding: 'normal',
            label: 'Tags'
        },
        {
            id: 'Ghi chú',
            align: 'left',
            padding: 'normal',
            label: 'Ghi chú'
        },
        {
            id: 'Thao tác',
            align: 'center',
            padding: 'normal',
            label: 'Thao tác'
        }
    ];
    const bannerTableData =
        customerList &&
        customerList.map((el, index) => ({
            id: el?.id,
            stt: {value: index + 1, isshow: true},
            name: {value: el?.full_name, isshow: true, align: 'center'},
            unit_name: {value: el?.unit_name, isshow: true},
            phone_number: {value: el?.phone_number, isshow: true},
            email: {value: el?.email, isshow: true},
            tags_name: {value: el?.name_tag, isshow: true},
            note: {value: el?.note, isshow: true},

            actions: {
                value: (
                    <div className='d-flex justify-content-center gap-3'>
                        <Edit className='cursor-pointer' onClick={() => openCustomerModal(el, true)} />
                        <Delete className='cursor-pointer' onClick={() => openCustomerModal(el, false)} />
                    </div>
                ),
                isshow: true
            },
            bannerObj: {
                ...el
            }
        }));
    return {
        history,
        customerListProps: {
            navigateTo,
            customerList,
            checkedData,
            handleDeleteSelectedRows,
            pageSize,
            setPageSize,
            handleSelectRow,
            setPage,
            totalPage,
            page,
            tableData: bannerTableData,
            headCells: headCells,
            searchCustomerValue,
            setSearchCustomerValue,
            fetchCustomerList,
            setIsOpenImportCustomer,
            handleChangeFilterByTag,
            tagList
        },
        addCustomerProps: {
            navigateTo,
            customerList,
            handleAddCustomer,
            handleSubmitInvalid,
            tagList
        },
        editCustomerProps: {
            handleSubmitInvalid,
            isOpenCustomerModal,
            isCustomerEdit,
            selectedCustomer,
            handleAddCustomer,
            closeCustomerModal,
            handleDeleteCustomer,
            customerList,
            tagList
        },
        addTagProps: {
            handleSubmitInvalid,
            handleAddTag,
            navigateTo,
            customerList,
            tagList,
            errorText
        },
        importCustomerProps: {
            handleSubmitInvalid,
            isOpenImportCustomerModal,
            handleUploadExcel,
            handleConvertData,
            onDeleteExcel,
            importExcelData,
            onSubmitImportDataExcel,
            closeImportCustomer
        }
    };
}
