import {useEffect, useState, useRef, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {calcTotalPage} from 'utils/calcTotalPage';
import propsProvider from './checkInPropsProvider';

import CustomToast from 'components/CustomToast';
import {pagePath} from 'configuration/routeConfig';
import {logout} from 'features/Auth/authSlice';
import {handleChangePage, handleChangePageSize, handleChangeTotalPage} from 'features/Home/homeSlice';
import {getBase64} from 'utils/getBase64';
import CheckInMainView from './template/CheckInMainView';
import {
    addNewCheckInCustomer,
    changeCheckInUser,
    checkExistEmail,
    deleteUserCheckIn,
    getCheckInList,
    handleUpdateCheckInStatus,
    importUserCheckIn,
    registerProgram
} from './checkInSlice';
import {useReactToPrint} from 'react-to-print';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const CheckInContainer = (props) => {
    const {home, checkInList = []} = props;

    const printRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();

    const {paginationData, activePage} = home;
    const [checkedData, setCheckedData] = useState([]);
    const [isOpenBannerModal, setBannerModal] = useState(false);
    const [detailImage, setDetailImage] = useState({});
    const [modalContent, setModalContent] = useState({});
    const [filterEventData, setFilterEventData] = useState();
    const [searchValue, setSearchValue] = useState('');
    const [isOpenImportUserModal, setIsOpenImportUserModal] = useState(false);
    const [importExcelData, setImportExcelData] = useState();

    const filterEventRef = useRef();

    const navigateTo = (path) => {
        try {
            if (path === pagePath.logoutUrl) {
                dispatch(logout()).then((res) => history.push(pagePath.authLoginUrl));
            } else {
                history.push(path);
            }
        } catch (e) {
            CustomToast(false, 'Có lỗi xảy ra');
        }
    };

    const setPage = (value) => {
        dispatch(handleChangePage({activePage: activePage, page: value}));
    };

    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage: activePage, pageSize: value}));
        setPage(1);
    };

    const setTotalPage = (count, pageSize) => {
        const value = calcTotalPage(count, pageSize);
        // console.log('value', value);
        dispatch(handleChangeTotalPage({activePage: activePage, totalPage: value}));
    };

    const handleSelectRow = (event, id) => {};
    const handleDeleteSelectedRows = async () => {};

    const handleSubmitInvalid = (_, event) => {
        console.log('submit invalid');
        // event.target.classList.add('wasvalidated');
    };

    const handleUploadImages = async (e) => {
        const file = e.target.files[0];
        const {size} = file;

        if (size > 5 * 1024 * 1024) {
            CustomToast(false, 'Tệp vượt quá giới hạn dung lượng cho phép');
            return;
        }

        const previewUrl = await getBase64(file);
        setDetailImage({url: previewUrl, file});
    };
    const handleDeleteImage = (e) => {
        setDetailImage({});
    };

    const handleOnAfterPrint = async () => {
        window.location.reload();
    };

    const fetchCheckInList = async () => {
        const res = await dispatch(
            getCheckInList({
                Filters: searchValue ? `user_full_name|user_email@=${searchValue}` : '',
                Sorts: '-created_at',
                Page: paginationData[activePage]?.page ?? 1,
                PageSize: paginationData[activePage]?.pageSize ?? 10
            })
        ).unwrap();
        if (res?.success) {
            const {data} = res;
            setTotalPage(data?.total, data?.pageSize);
        }
    };

    const onPrintQRCode = useReactToPrint({
        content: () => printRef.current,
        onAfterPrint: () => handleOnAfterPrint(),

        onPrintError: () => {
            console.log('onPrintError');
        }
    });
    const onSubmitPrintQRCode = async (data) => {
        const qrCodeUrl = 'id=' + data.id + ';user_id=' + data.user_id + ';program_id=' + data.program_id;
        const printContainer = document.getElementById('print-container');
        const qrCodeInfo = document.createElement('img');
        qrCodeInfo.src = `https://api.qrserver.com/v1/create-qr-code/?data=${qrCodeUrl}&amp;size=200x200`;
        qrCodeInfo.alt = 'barcode';
        qrCodeInfo.width = '200';
        qrCodeInfo.height = '200';
        qrCodeInfo.style.paddingTop = '50px';
        printContainer.appendChild(qrCodeInfo);
        onPrintQRCode();
    };
    const onDeleteCheckInUser = (checkIn) => {
        try {
            const modalPopupState = {
                title: 'Xác nhận',
                closeText: 'Hủy'
            };
            let contentText = '';
            Object.assign(modalPopupState, {
                onConfirm: async () => {
                    //CustomToast(true, 'Thao tác thành công');
                    const res = await dispatch(deleteUserCheckIn(checkIn.id)).unwrap();
                    if (res) {
                        fetchCheckInList();
                        CustomToast(true, 'Thao tác thành công');
                    } else {
                        CustomToast(false, `Thao tác thất bại`);
                    }
                },
                confirmText: 'Đồng ý'
            });
            contentText = `Bạn có chắc muốn xoá" ${checkIn?.user_full_name} " này?`;
            modalPopupState.content = (
                <div
                    className='d-flex justify-content-center align-items-center gap-2'
                    style={{fontWeight: 'bold', fontFamily: 'sans-serif'}}>
                    {contentText}
                </div>
            );
            setModalContent(modalPopupState);
        } catch (err) {
            console.error('unexpected error');
        }
        // dispatch(deleteUserCheckIn(checkIn_id)).then((res) => {
        //     console.log('res', res);
        // });
    };

    const exportToCSV = (programName) => {
        const filterCSVData = [];
        if (checkInList.length > 0) {
            const filterData = checkInList.filter((item) => item?.program_name === programName);
            filterData.forEach((item, index) => {
                filterCSVData.push({
                    STT: index + 1,
                    'Họ và tên': item?.user_full_name,
                    'Đơn vị': item.user_company_name,
                    'Số điện thoại': item.user_phone_number,
                    Email: item.user_email,
                    'Điểm danh': item?.is_checked ? 'Đã điểm danh' : 'Chưa điểm danh',
                    'Sự kiện': item?.program_name
                });
            });
        }
        if (filterCSVData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(filterCSVData);
            const wb = {Sheets: {data: ws}, SheetNames: ['data']};
            const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, 'Customers' + fileExtension);
        }
    };
    const onOpenModalExport = () => {
        try {
            if (checkInList.length > 0) {
                const modalPopupState = {
                    title: 'Xác nhận',
                    closeText: 'Hủy'
                };
                const eventList = [...new Set([...checkInList.map((item) => item.program_name)])];
                filterEventRef.current = eventList[0];
                let contentText = '';
                Object.assign(modalPopupState, {
                    onConfirm: async () => {
                        //CustomToast(true, 'Thao tác thành công');
                        exportToCSV(filterEventRef.current);
                    },
                    confirmText: 'Đồng ý'
                });
                contentText = `Bạn có chắc muốn tải file với sự kiện này?`;
                modalPopupState.content = (
                    <div
                        style={{
                            fontWeight: 'bold',
                            fontFamily: 'sans-serif',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        {contentText}
                        <select
                            value={filterEventRef.current}
                            onChange={(e) => {
                                // console.log('e', e.target.value);
                                filterEventRef.current = e.target.value;
                            }}
                            id='dropdown'>
                            {eventList.length > 0 &&
                                eventList.map((item) => {
                                    return (
                                        <option key={item} value={item}>
                                            {item}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                );
                setModalContent(modalPopupState);
            }
        } catch (err) {
            console.error('unexpected error', err);
        }
    };

    const handleChangeFilterEvent = useCallback(
        (event_name) => {
            if (event_name && checkInList.length > 0) {
                setFilterEventData(
                    event_name === 'Tất cả'
                        ? checkInList
                        : checkInList.filter((item) => item.program_name === event_name)
                );
            }
        },
        [checkInList]
    );
    const handleCheckInForUser = (e, data) => {
        const {position, value} = data;
        const payload = {
            user_id: checkInList[position].user_id,
            program_id: checkInList[position].program_id,
            is_checked: !value
        };
        dispatch(handleUpdateCheckInStatus({position}));
        dispatch(changeCheckInUser({id: checkInList[position].id, payload})).then(async (result) => {
            // console.log(result);
            if (result?.payload?.success) {
                CustomToast(true, 'Chỉnh sửa check in thành công');
            } else {
                CustomToast(false, 'Chỉnh sửa check in thất bại');
            }
        });
    };
    const handleSubmitCreateCustomerCheckIn = (data) => {
        const {email, full_name, work_unit, phone_number, is_checked, program} = data;
        const payloadRegisterProgram = {
            full_name,
            email,
            phone_number,
            address: '',
            position: '',
            company_name: work_unit
        };
        dispatch(checkExistEmail(email)).then((response) => {
            const {success = false} = response?.payload;
            const existProgram = checkInList.filter((item) => item.program_name === program);
            if (success) {
                const {data} = response?.payload;
                if (data) {
                    if (existProgram.length > 0) {
                        const payloadCheckInCustomer = {
                            user_id: data,
                            program_id: existProgram.length > 0 ? existProgram[0].program_info.id : null,
                            is_checked: is_checked
                        };
                        dispatch(addNewCheckInCustomer(payloadCheckInCustomer)).then((res) => {
                            if (res?.payload?.success) {
                                navigateTo(pagePath.checkInURL);
                                CustomToast(true, 'Thêm khách hàng thành công');
                            } else {
                                CustomToast(false, res?.payload?.message ?? 'Thêm khách hàng không thành công');
                            }
                        });
                    } else {
                        CustomToast(false, 'Vui lòng chọn sự kiện');
                    }
                } else {
                    dispatch(registerProgram(payloadRegisterProgram)).then((res) => {
                        if (res?.payload?.success) {
                            const payloadCheckInCustomer = {
                                user_id: res?.payload?.user_id,
                                program_id: existProgram.length > 0 ? existProgram[0].program_info.id : null,
                                is_checked: is_checked
                            };
                            dispatch(addNewCheckInCustomer(payloadCheckInCustomer)).then((res) => {
                                if (res?.payload?.success) {
                                    navigateTo(pagePath.checkInURL);
                                    CustomToast(true, 'Thêm khách hàng thành công');
                                } else {
                                    CustomToast(false, res?.payload?.message ?? 'Thêm khách hàng không thành công');
                                }
                            });
                        } else {
                            CustomToast(false, res?.payload?.message ?? 'Thêm khách hàng thành công');
                        }
                    });
                }
            } else {
                CustomToast(false, 'Đã có lỗi xảy ra');
            }
        });
    };
    const handleUploadExcel = async (e) => {
        setImportExcelData(e.target.files[0]);
    };
    const closeImportUser = () => {
        setImportExcelData(null);
        setIsOpenImportUserModal(false);
    };
    const onDeleteExcel = () => {
        setImportExcelData(null);
    };
    const onSubmitImportDataExcel = () => {
        if (importExcelData) {
            let fileFormData = new FormData();
            fileFormData.append('title', importExcelData?.name);
            fileFormData.append('file', importExcelData);
            dispatch(importUserCheckIn({file: fileFormData})).then((res) => {
                console.log('onSubmitImportDataExcel', res);
                const {success} = res?.payload;
                if (success) {
                    CustomToast('success', 'Import file thành công');
                    fetchCheckInList();
                    closeImportUser();
                } else {
                    CustomToast('false', 'Import file thất bại');
                }
            });
        }
    };

    useEffect(() => {
        fetchCheckInList();
    }, [paginationData]);

    useEffect(() => {
        checkInList && handleChangeFilterEvent('Tất cả');
    }, [checkInList]);

    const companyContainerProps = {
        history,
        navigateTo,
        checkInList,
        checkedData,
        handleDeleteSelectedRows,
        setPageSize,
        handleSelectRow,
        setPage,
        paginationData,
        activePage,
        handleSubmitInvalid,
        detailImage,
        handleUploadImages,
        handleDeleteImage,
        isOpenBannerModal,
        onPrintQRCode,
        printRef,
        onSubmitPrintQRCode,
        onDeleteCheckInUser,
        modalContent,
        exportToCSV,
        onOpenModalExport,
        filterEventData,
        handleChangeFilterEvent,
        setSearchValue,
        searchValue,
        fetchCheckInList,
        handleCheckInForUser,
        handleSubmitCreateCustomerCheckIn,
        isOpenImportUserModal,
        handleUploadExcel,
        onDeleteExcel,
        importExcelData,
        onSubmitImportDataExcel,
        closeImportUser,
        setIsOpenImportUserModal
    };

    return <CheckInMainView {...propsProvider(companyContainerProps)} />;
};

export default CheckInContainer;
