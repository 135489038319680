import ModalPopup from 'components/ModalPopup/ModalPopup';
import Home from 'features/Home';
import CheckInList from './subViews/CheckInList';
import {useHistory} from 'react-router-dom';
import {pagePath} from 'configuration/routeConfig';
import AddNewUserCheckIn from './subViews/AddNew';
import ImportUser from './subViews/ImportUser';
const CheckInMainView = (props) => {
    const {checkInListProps, printRef, modalContent, addNewProps, importUserProps} = props;
    const history = useHistory();
    let CustomerComponent = null;
    switch (history.location.pathname) {
        case pagePath.checkInURL:
            CustomerComponent = <CheckInList {...checkInListProps} />;
            break;
        case pagePath.checkInCreateURL:
            CustomerComponent = <AddNewUserCheckIn {...addNewProps} />;
            break;
        default:
            CustomerComponent = <CheckInList {...checkInListProps} />;
            break;
    }
    return (
        <Home>
            <ModalPopup {...modalContent} />
            <ImportUser {...importUserProps} />
            {CustomerComponent}
            <div
                ref={printRef}
                id='print-container'
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}></div>
        </Home>
    );
};

export default CheckInMainView;
