import { AddImage } from 'assets';
import React from 'react';
import { Controller } from 'react-hook-form';

const InputFiles = (props) => {
    const {
        type = 'image',
        count,
        maxLength,
        accept = '',
        inputNotes = [],
        handleUpload,
        className = '',
        name,
        control,
        rules = {disable: false},
    } = props;
    const isImage = type === 'image';

    return (
        <div className={`input-files ${className}`}>
            <div
                className={`input-files__container ${isImage ? 'input-files__container--image' : 'input-files__container--video'
                    }`}>
                {control ? (
                    <Controller
                        control={control}
                        name={name}
                        rules={rules}
                        render={({ field: { onChange, value, ...restField } }) => (
                            <input
                                id='files'
                                type='file'
                                multiple={!!maxLength}
                                accept={accept.join(', ')}
                                className='input-hold'
                                onChange={(e) => {
                                    handleUpload(e);
                                    onChange(e);
                                }}
                                value={value && value.filename ? value.filename : ""}
                                {...restField}
                            />
                        )}
                    />
                ) : (
                    <input
                        id='files'
                        type='file'
                        multiple={!!maxLength}
                        accept={accept.join(', ')}
                        className='input-hold'
                        onChange={handleUpload}
                    />
                )}

                <div className='input-frame'>
                    <AddImage />
                    {!!maxLength && <span className='test'>{`${count}/${maxLength}`}</span>}
                </div>
            </div>

            <div className='input-note'>
                {inputNotes.map((note, idx) => (
                    <span key={idx}>{note}</span>
                ))}
            </div>
        </div>
    );
};
export default InputFiles;
