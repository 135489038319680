import {FormControl} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import GroupInput from 'components/GroupInput/GroupInput';
import {pagePath} from 'configuration/routeConfig';
import {Controller, useForm} from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';
const defaultValues = {
    newTag: '',
    tags: {}
};

const AddTags = (props) => {
    const {handleSubmitInvalid, handleAddTag, navigateTo, tagList, errorText} = props;
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch
    } = useForm({defaultValues});
    const addInputs = [
        {
            label: 'Tag',
            placeholder: 'Nhập tag mới',
            isRequired: true,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'newTag'
        },
        {
            label: 'Danh sách tags đã có',
            isRequired: false,
            placeholder: 'Danh sách tags đã có',
            type: 'select',
            name: 'tags',
            options:
                tagList?.length > 0 &&
                tagList.map((item) => {
                    return {id: item.id, value: item.name};
                })
        }
    ];
    return (
        <CustomCard title={'Thông tin khách hàng'}>
            <form
                className='mt-2 add-news'
                method='POST'
                action='#'
                noValidate
                onSubmit={handleSubmit(handleAddTag, handleSubmitInvalid)}>
                {addInputs.map((input, idx) => {
                    const {type, rules, groupInputData, label, name, ...restProps} = input;
                    return (
                        <FormControl key={idx}>
                            {type !== 'group' ? (
                                <Controller
                                    name={name}
                                    control={control}
                                    rules={rules}
                                    key={idx}
                                    render={({field}) =>
                                        type !== 'select' ? (
                                            <CustomInput {...field} {...restProps} label={label} />
                                        ) : (
                                            <CustomSelect label={label} {...field} {...restProps} type={type} />
                                        )
                                    }
                                />
                            ) : (
                                <GroupInput control={control} label={label} key={idx} groupInputData={groupInputData} />
                            )}
                        </FormControl>
                    );
                })}
                <div style={{color: 'red', fontSize: 18}}>{errorText}</div>
                <br />
                <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                    <CustomButton variant='outlined' onClick={() => navigateTo(pagePath.customerURL)} type='button'>
                        Hủy
                    </CustomButton>
                    <CustomButton variant='contained' type='submit'>
                        Lưu
                    </CustomButton>
                </div>
            </form>
        </CustomCard>
    );
};

export default AddTags;
