import { Trash } from 'assets';
import React from 'react';

const PreviewImage = (props) => {
    const { image, onDeleteImage } = props;

    return (
        <div className='preview-image'>
            <img src={image} alt='' />

            {onDeleteImage && (
                <div className='preview-image__wrapper'>
                    <Trash onClick={onDeleteImage} />
                </div>
            )}
        </div>
    );
};

export default PreviewImage;
