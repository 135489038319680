import {Table, TableBody, TableContainer} from '@mui/material';
import {Add, Search} from 'assets';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomPageSize from 'components/CustomPageSize/CustomPageSize';
import CustomTableHead from 'components/CustomTableHead/CustomTableHead';
import CustomTableRow from 'components/CustomTableRow/CustomTableRow';
import CustomPagination from 'components/Pagination/Pagination';
import {pagePath} from 'configuration/routeConfig';
import ActionBar from 'features/Home/template/subViews/ActionBar/ActionBar';
import {useMemo} from 'react';

const CheckInList = (props) => {
    const {
        checkInList,
        checkedData = [],
        handleDeleteSelectedRows,
        pageSize,
        setPageSize,
        handleSelectRow,
        setPage,
        totalPage,
        page = 0,
        tableData,
        headCells,
        handleSelectAllClick,
        onOpenModalExport,
        handleChangeFilterEvent,
        handleCheckInForUser,
        navigateTo,
        searchValue,
        setSearchValue,
        fetchCheckInList,
        setIsOpenImportUserModal
    } = props;

    const eventList = useMemo(() => {
        return ['Tất cả'].concat([...new Set([...checkInList.map((item) => item?.program_name)])] || []);
    }, [checkInList]);

    return (
        <CustomCard title='Danh sách check in'>
            <div className='d-flex align-items-center gap-2'>
                <CustomInput
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            fetchCheckInList();
                        }
                    }}
                    startIcon={<Search />}
                    placeholder={'Nhập tên user cần tìm...'}
                />
                <CustomButton className='flex-shrink-0' onClick={() => setIsOpenImportUserModal(true)}>
                    <Add />
                    Import Excel
                </CustomButton>
                <CustomButton className='flex-shrink-0' onClick={() => onOpenModalExport()}>
                    <Add />
                    Export Excel
                </CustomButton>
                <CustomButton className='flex-shrink-0' onClick={() => navigateTo(pagePath.checkInCreateURL)}>
                    <Add />
                    Thêm khách hàng
                </CustomButton>
                {eventList?.length > 0 && (
                    <select
                        // value={filterEventRef.current}
                        onChange={(e) => handleChangeFilterEvent(e.target.value)}
                        className='custom-btn custom-btn__contained custom-btn__lg custom-btn--primary w-50'>
                        {eventList?.length > 0 &&
                            eventList?.map((item) => {
                                return (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                );
                            })}
                    </select>
                )}
            </div>
            <div className='mt-3'>
                {checkedData?.length > 0 && (
                    <ActionBar
                        selectedRow={checkedData?.length}
                        rowCount={checkInList?.length}
                        handleMultiAction={handleDeleteSelectedRows}
                    />
                )}
            </div>
            <div className='mt-3'>
                <TableContainer className='program-table'>
                    <Table aria-labelledby='tableTitle'>
                        <CustomTableHead
                            handleCheckAll={handleSelectAllClick}
                            headCells={headCells}
                            withCheckbox
                            selectedRows={checkedData?.length}
                            rowCount={tableData?.length}
                        />
                        <TableBody>
                            {tableData &&
                                tableData.map((row, idx) => {
                                    const isItemSelected = checkedData.includes(row.id);
                                    const isChecked = row?.is_checked?.value;
                                    return (
                                        <CustomTableRow
                                            key={idx}
                                            rowData={row}
                                            selected={isItemSelected}
                                            is_checked={isChecked}
                                            handleSelectRow={handleSelectRow}
                                            handleIsChecked={handleCheckInForUser}
                                        />
                                    );
                                })}
                        </TableBody>
                    </Table>
                    {tableData && (
                        <div className='table-pagination'>
                            <CustomPagination setPage={setPage} totalPage={totalPage} currentPage={page} />
                            <CustomPageSize
                                label={'Số dòng trên trang'}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pageSizeRange={[5, 10, 15, 20, 25]}
                                id={'temp'}
                            />
                        </div>
                    )}
                </TableContainer>
            </div>
        </CustomCard>
    );
};

export default CheckInList;
