import { Trash, pdfIcon } from 'assets';
import React from 'react';
const PreviewPdf = ({ fileName, onDeletePdf }) => {

    return (
        <div className='preview-image'>
            <img src={pdfIcon} />
            <p>{fileName}</p>
            {onDeletePdf && (
                <div className='preview-image__wrapper'>
                    <Trash onClick={onDeletePdf} />
                </div>
            )}
        </div>
    );
};

export default PreviewPdf;
