import {Delete, Edit} from 'assets';
import dayjs from 'dayjs';

export default function propsProvider(props) {
    const {
        history,
        navigateTo,
        setPage,
        setPageSize,
        checkedData,
        handleSelectAllClick,
        handleSelectRow,
        handleDeleteSelectedRows,
        paginationData,
        activePage,
        supplierList,
        openSupplierModal,
        searchSupplierValue,
        setSearchSupplierValue,
        handleSearchSupplier,
        handleSubmitSupplier,
        detailImageList,
        handleUploadImageList,
        handleDeleteImageList,
        detailImage,
        handleDeleteImage,
        handleUploadImages,
        descriptionSupplier,
        setDescriptionSupplier,
        onUploadImage,
        productSupplierInfo,
        setDescriptionProductSupplier,
        addMoreFieldProductSupplierInfo,
        handleUploadImageProductSupplierInfo,
        handleDeleteImageProductSupplierInfo,
        removeMoreFieldProductSupplierInfo,
        isOpenSupplierModal,
        closeSupplierModal,
        isSupplierEdit,
        handleSubmitInvalid,
        handleDeleteSupplier,
        selectedSupplier,
        handleDeleteProduct,
        moreProduct,
    } = props;
    const {page, pageSize, totalPage = 1} = paginationData[activePage] || {};
    const supplierTableData =
        supplierList &&
        supplierList.map((el) => ({
            id: el?.id,
            name: {value: el?.name, isshow: true},
            address: {value: el?.address, isshow: true},
            main_customer: {value: el?.main_customer, isshow: true},
            founded_date: {value: dayjs(el?.founded_date).format('DD/MM/YYYY'), isshow: true},
            website: {value: el?.website, isshow: true},

            actions: {
                value: (
                    <div className='d-flex justify-content-center gap-3'>
                        <Edit className='cursor-pointer' onClick={() => openSupplierModal(el, true)} />
                        <Delete className='cursor-pointer' onClick={() => openSupplierModal(el, false)} />
                    </div>
                ),
                isshow: true
            },
            programObj: {
                ...el
            }
        }));
    const supplierHeadCells = [
        {
            id: 'Tên nhà cung cấp',
            align: 'left',
            padding: 'none',
            label: 'Tên nhà cung cấp'
        },
        {
            id: 'Địa chỉ',
            align: 'left',
            padding: 'none',
            label: 'Địa chỉ'
        },
        {
            id: 'Khách hàng chính',
            align: 'left',
            padding: 'none',
            label: 'Khách hàng chính'
        },
        {
            id: 'Found Date',
            align: 'left',
            padding: 'none',
            label: 'Found Date'
        },
        {
            id: 'Địa chỉ web',
            align: 'left',
            padding: 'none',
            label: 'Địa chỉ web'
        },
        {
            id: 'Thao tác',
            align: 'center',
            padding: 'normal',
            label: 'Thao tác'
        }
    ];
    return {
        history,
        supplierListProps: {
            navigateTo,
            searchSupplierValue,
            setSearchSupplierValue,
            handleSearchSupplier,
            headCells: supplierHeadCells,
            tableData: supplierTableData,
            setPage,
            setPageSize,
            page,
            pageSize,
            totalPage,
            checkedData,
            handleSelectAllClick,
            handleSelectRow,
            handleDeleteSelectedRows
        },
        addSupplierProps: {
            handleSubmitSupplier,
            navigateTo,
            detailImageList,
            handleUploadImageList,
            handleDeleteImageList,
            detailImage,
            handleDeleteImage,
            handleUploadImages,
            descriptionSupplier,
            setDescriptionSupplier,
            onUploadImage,
            productSupplierInfo,
            setDescriptionProductSupplier,
            addMoreFieldProductSupplierInfo,
            handleUploadImageProductSupplierInfo,
            handleDeleteImageProductSupplierInfo,
            removeMoreFieldProductSupplierInfo
        },
        editSupplierProps: {
            isOpenSupplierModal,
            closeSupplierModal,
            isSupplierEdit,
            handleSubmitInvalid,
            handleDeleteSupplier,
            selectedSupplier,
            handleDeleteProduct,
            moreProduct,
            handleSubmitSupplier,
            detailImageList,
            handleDeleteImageList,
            handleUploadImageList,
            detailImage,
            handleUploadImages,
            handleDeleteImage,
            descriptionSupplier,
            setDescriptionSupplier,
            productSupplierInfo,
            setDescriptionProductSupplier,
            addMoreFieldProductSupplierInfo,
            onUploadImage,
            removeMoreFieldProductSupplierInfo,
            handleUploadImageProductSupplierInfo,
            handleDeleteImageProductSupplierInfo
        }
    };
}
