import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import catalogueApi from 'api/catalogueApi';
import serviceRequest from 'app/serviceRequest';
export const addCatalogue = createAsyncThunk('catalogue/addCatalogue', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: catalogueApi.addCatalogue,
        payload: data
    });
});
export const getCatalogue = createAsyncThunk('catalogue/getCatalogue', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: catalogueApi.getCatalogue,
        payload: data
    });
});
export const deleteCatalogue = createAsyncThunk('catalogue/deleteCatalogue', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: catalogueApi.deleteCatalogue,
        payload: data
    });
});

const catalogue = createSlice({
    name: 'catalogue',
    initialState: {},
    reducers: {},
    extraReducers: (builders) => {
        builders.addCase(getCatalogue.fulfilled, (state, action) => {
            state.catalogueList = action?.payload?.data?.collection;
        });
    }
});

const {reducer, actions} = catalogue;
export default reducer;
