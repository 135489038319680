import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import bannerApi from 'api/bannerApi';
import serviceRequest from 'app/serviceRequest';

export const addBanner = createAsyncThunk('home/addBanner', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: bannerApi.addBanner,
        payload: data
    });
});
export const getBannerList = createAsyncThunk('home/getBannerList', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: bannerApi.getBannerList,
        payload: data
    });
});
export const deleteBanner = createAsyncThunk('home/deleteEvent', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: bannerApi.deleteBanner,
        payload: id
    });
});

const banner = createSlice({
    name: 'banner',
    initialState: {},
    reducers: {},
    extraReducers: (builders) => {
        builders.addCase(getBannerList.fulfilled, (state, action) => {
            state.bannerList = action?.payload?.data?.collection;
        });
    }
});

const {reducer, actions} = banner;
export default reducer;
