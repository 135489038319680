import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {calcTotalPage} from 'utils/calcTotalPage';
import propsProvider from './cataloguePropsProvider';

import CustomToast from 'components/CustomToast';
import {pagePath} from 'configuration/routeConfig';
import {logout} from 'features/Auth/authSlice';
import {handleChangePage, handleChangePageSize, handleChangeTotalPage} from 'features/Home/homeSlice';
import {addCatalogue, deleteCatalogue, getCatalogue} from './catalogueSlice';
import CatalogueMainView from './template/CatalogueMainView';

const CatalogueContainer = (props) => {
    const {home, userLoginInfo, catalogueList} = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const {pathname} = location;
    const {paginationData, activePage} = home;
    const [checkedData, setCheckedData] = useState([]);
    const [isCatalogueEdit, setIsCatalogueEdit] = useState(false);
    const [selectedCatalogue, setSelectedCatalogue] = useState({});
    const [isOpenCatalogueModal, setCatalogueModal] = useState(false);
    const [pdfUpload, setPdfUpload] = useState();
    const [detailImageList, setDetailImageList] = useState([]);

    const navigateTo = (path) => {
        try {
            if (path === pagePath.logoutUrl) {
                dispatch(logout()).then((res) => history.push(pagePath.authLoginUrl));
            } else {
                history.push(path);
            }
        } catch (e) {
            CustomToast(false, 'Có lỗi xảy ra');
        }
    };

    const setPage = (value) => {
        dispatch(handleChangePage({activePage: activePage, page: value}));
    };

    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage: activePage, pageSize: value}));
        setPage(1);
    };

    const setTotalPage = (count, pageSize) => {
        const value = calcTotalPage(count, pageSize);
        // console.log('value', value);
        dispatch(handleChangeTotalPage({activePage: activePage, totalPage: value}));
    };

    const handleSelectRow = (event, id) => {};
    const handleDeleteSelectedRows = async () => {};
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            let newSelected = [];
            // if (activePage === 0) {
            //     newSelected = programList.map((data) => data?.id);
            // } else {
            //     newSelected = sponsorList.map((data) => data?.id);
            // }
            setCheckedData(newSelected);
            return;
        }

        setCheckedData([]);
    };
    const handleSubmitInvalid = (_, event) => {
        console.log('submit invalid');
        // event.target.classList.add('wasvalidated');
    };
    const handleUploadPdf = async (e) => {
        const file = e.target.files[0];
        // if (size > 5 * 1024 * 1024) {
        //     CustomToast(false, 'Tệp vượt quá giới hạn dung lượng cho phép');
        //     return;
        // }

        // const previewUrl = await getBase64(file);
        setPdfUpload(file);
    };
    const handleDeletePdf = () => {
        setPdfUpload(null);
    };
    const handleAddPdf = async () => {
        let fileFormData = new FormData();
        fileFormData.append('title', pdfUpload?.name);
        fileFormData.append('file', pdfUpload);
        const typeFile = pathname.split('/')[1];
        const res = await dispatch(
            addCatalogue({
                type: typeFile.toUpperCase(),
                file: fileFormData
            })
        );
        if (res?.payload?.success) {
            CustomToast('success', 'Thêm catalogue thành công');
            navigateTo(pagePath.catalogueUrl);
        }
    };
    const openCatalogueModal = (catalogue, isEdit) => {
        setIsCatalogueEdit(isEdit);
        setSelectedCatalogue(catalogue);
        setCatalogueModal(true);
    };
    const closeCatalogueModal = () => {
        setSelectedCatalogue({});
        setCatalogueModal(false);
    };
    const fetchCatalogue = async () => {
        const resFetch = await dispatch(
            getCatalogue({
                Filters: '',
                Sorts: '',
                Page: paginationData[activePage]?.page,
                PageSize: paginationData[activePage]?.pageSize
            })
        ).unwrap();
        if (resFetch?.success) {
            const {data} = resFetch;
            setTotalPage(data?.total, data?.pageSize);
        }
    };
    const fetchDeleteCatalogue = async () => {
        const res = await dispatch(deleteCatalogue(selectedCatalogue?.id)).unwrap();

        const {success} = res;
        if (success) {
            CustomToast(success, 'Xóa catalog thành công');
            fetchCatalogue();
            closeCatalogueModal();
        } else {
            CustomToast(false, 'Xóa catalog thất bại ');
        }
    };
    useEffect(() => {
        fetchCatalogue();
    }, [paginationData]);

    const companyContainerProps = {
        history,
        paginationData,
        activePage,
        navigateTo,
        checkedData,
        handleDeleteSelectedRows,
        setPageSize,
        handleSelectRow,
        setPage,
        catalogueList,
        handleSelectAllClick,
        openCatalogueModal,
        handleSubmitInvalid,
        handleUploadPdf,
        pdfUpload,
        handleDeletePdf,
        handleAddPdf,
        isOpenCatalogueModal,
        closeCatalogueModal,
        fetchDeleteCatalogue
    };

    return <CatalogueMainView {...propsProvider(companyContainerProps)} />;
};

export default CatalogueContainer;
