import LoaderSpinner from "components/Loader";
import { redirectList, routeList } from "configuration/routeConfig";
import React from "react";

import {
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CustomRoute from "./CustomRoute";

const Routers = () => {
  return (
    <Router>
      <LoaderSpinner />
      <Switch>
        {redirectList.map((redirect, idx) => (
          <Redirect
            key={`redirect-${idx}`}
            exact
            from={redirect.from}
            to={redirect.to}
          />
        ))}
        {routeList.map((route, idx) => (
          <CustomRoute key={`route-${idx}`} exact {...route} />
        ))}
      </Switch>
      <ToastContainer theme='colored' />
    </Router>
  );
};

export default Routers;
