import {FormControl} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import GroupInput from 'components/GroupInput/GroupInput';
import {pagePath} from 'configuration/routeConfig';
import {Controller, useForm} from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';
const defaultValues = {
    full_name: '',
    work_unit: '',
    phone_number: '',
    email: '',
    is_checked: false,
    program: {}
};

const AddNewUserCheckIn = (props) => {
    const {handleSubmitInvalid, handleSubmitCreateCustomerCheckIn, navigateTo, checkInList} = props;

    const {
        handleSubmit,
        control,
        formState: {errors},
        watch
    } = useForm({defaultValues});
    const addInputs = [
        {
            label: 'Họ tên',
            placeholder: 'Nhập họ tên',
            isRequired: true,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'full_name'
        },
        {
            label: 'Nơi làm việc',
            placeholder: 'Nhập nơi làm việc',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'work_unit'
        },
        {
            label: 'Số điện thoại',
            placeholder: 'Nhập số điện thoại',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'phone_number'
        },
        {
            label: 'Địa chỉ email',
            placeholder: 'Nhập địa chỉ email',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'email'
        },

        {
            label: 'Danh sách sự kiện',
            isRequired: false,
            placeholder: 'Danh sách sự kiện',
            type: 'select',
            name: 'program',
            options:
                checkInList?.length > 0 &&
                checkInList.map((item) => {
                    return {id: item, value: item};
                })
        },
        {
            label: 'Khách hàng check in',
            placeholder: 'Khách hàng check in',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'checkbox',
            name: 'is_checked'
        }
    ];
    return (
        <CustomCard title={'Thông tin khách'}>
            <form
                className='mt-2 add-news'
                method='POST'
                action='#'
                noValidate
                onSubmit={handleSubmit(handleSubmitCreateCustomerCheckIn, handleSubmitInvalid)}>
                {addInputs.map((input, idx) => {
                    const {type, rules, groupInputData, label, name, ...restProps} = input;
                    return (
                        <FormControl key={idx}>
                            {type !== 'group' ? (
                                <Controller
                                    name={name}
                                    control={control}
                                    rules={rules}
                                    key={idx}
                                    render={({field}) => (
                                        // type !== 'select' ? (
                                        //     <CustomInput {...field} {...restProps} label={label} />
                                        // ) : (
                                        //     <CustomSelect label={label} {...field} {...restProps} type={type} />
                                        // )
                                        <>
                                            {type === 'text' && <CustomInput {...field} {...restProps} label={label} />}
                                            {type === 'select' && (
                                                <CustomSelect label={label} {...field} {...restProps} type={type} />
                                            )}
                                            {type === 'checkbox' && (
                                                <div className='checkbox-label'>
                                                    <CustomCheckbox
                                                        checked={input.rules.value}
                                                        // onChange={(e) => handleIsChecked(e, input)}
                                                        color='primary'
                                                        inputProps={{
                                                            'aria-labelledby': input
                                                        }}
                                                        isContrast={true}
                                                    />
                                                    {label}
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            ) : (
                                <GroupInput control={control} label={label} key={idx} groupInputData={groupInputData} />
                            )}
                        </FormControl>
                    );
                })}

                <br />
                <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                    <CustomButton variant='outlined' onClick={() => navigateTo(pagePath.checkInURL)} type='button'>
                        Hủy
                    </CustomButton>
                    <CustomButton variant='contained' type='submit'>
                        Lưu
                    </CustomButton>
                </div>
            </form>
        </CustomCard>
    );
};

export default AddNewUserCheckIn;
