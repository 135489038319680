import {Delete, Edit} from 'assets';
import dayjs from 'dayjs';

export default function propsProvider(props) {
    const {
        navigateTo,
        searchProgramValue,
        setSearchProgramValue,
        handleSearchProgram,
        setPage,
        setPageSize,
        checkedData = [],
        handleSelectAllClick,
        handleSelectRow,
        handleDeleteSelectedRows,
        paginationData,
        activePage,
        programList,
        openProgramModal,
        history,
        handleSubmitInvalid,
        handleAddProgram,
        handleUploadPdf,
        pdfUpload,
        handleDeletePdf,
        descriptionProgram,
        setDescriptionProgram,
        onUploadImage,
        programBanner,
        handleDeleteBannerProgram,
        handleUploadBannerProgram,
        isOpenProgramModal,
        closeProgramModal,
        isProgramEdit,
        handleDeleteProgram,
        selectedProgram
    } = props;
    const {page, pageSize, totalPage = 1} = paginationData[activePage] || {};

    const programTableData =
        programList &&
        programList.map((el) => ({
            id: el?.id,
            name: {value: el?.name, isshow: true},
            speaker: {value: el?.speaker, isshow: true},
            address: {value: el?.address, isshow: true},
            time: {value: dayjs(el?.start_date).format('DD/MM/YYYY'), isshow: true},
            actions: {
                value: (
                    <div className='d-flex justify-content-center gap-3'>
                        <Edit className='cursor-pointer' onClick={() => openProgramModal(el, true)} />
                        <Delete className='cursor-pointer' onClick={() => openProgramModal(el, false)} />
                    </div>
                ),
                isshow: true
            },
            programObj: {
                ...el
            }
        }));
    const programHeadCells = [
        {
            id: 'Tên chương trình',
            align: 'left',
            padding: 'none',
            label: 'Tên chương trình'
        },
        {
            id: 'Diễn giả',
            align: 'left',
            padding: 'normal',
            label: 'Diễn giả'
        },
        {
            id: 'Địa điểm',
            align: 'left',
            padding: 'normal',
            label: 'Địa điểm'
        },
        {
            id: 'Ngày bắt đầu',
            align: 'left',
            padding: 'normal',
            label: 'Ngày bắt đầu'
        },
        {
            id: 'Thao tác',
            align: 'center',
            padding: 'normal',
            label: 'Thao tác'
        }
    ];
    return {
        history,
        programListProps: {
            navigateTo,
            searchProgramValue,
            setSearchProgramValue,
            handleSearchProgram,
            headCells: programHeadCells,
            tableData: programTableData,
            setPage,
            setPageSize,
            page,
            pageSize,
            totalPage,
            checkedData,
            handleSelectAllClick,
            handleSelectRow,
            handleDeleteSelectedRows
        },
        addProgramProps: {
            handleSubmitInvalid,
            handleAddProgram,
            navigateTo,
            handleUploadPdf,
            pdfUpload,
            handleDeletePdf,
            descriptionProgram,
            setDescriptionProgram,
            onUploadImage,
            programBanner,
            handleDeleteBannerProgram,
            handleUploadBannerProgram
        },
        editProgramProps: {
            isOpenProgramModal,
            closeProgramModal,
            isProgramEdit,
            handleAddProgram,
            handleSubmitInvalid,
            handleDeleteProgram,
            selectedProgram,
            handleUploadPdf,
            pdfUpload,
            handleDeletePdf,
            programBanner,
            handleUploadBannerProgram,
            handleDeleteBannerProgram,
            descriptionProgram,
            setDescriptionProgram,
            onUploadImage
        }
    };
}
