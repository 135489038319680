import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';

const customerApi = {
    getCustomerList: (params) => {
        const url = '/api/customer/getAll';
        return axiosClient.get(url, {params});
    },
    requestAddCustomer: (data) => {
        const {tag_name, body} = data;
        const url = `/api/customer/createCustomer?tag_name=${tag_name}`;
        return axiosClient.post(url, body);
    },
    requestEditCustomer: (data) => {
        const {id, newTag, body} = data;
        const url = `/api/customer/updateCustomer/${id}?tag_name=${newTag}`;
        return axiosClient.put(url, body);
    },
    requestDeleteCustomer: (id) => {
        const url = `/api/customer/${id}`;
        return axiosClient.delete(url);
    },
    requestAddNewTag: (payload) => {
        const url = `/api/customer/tag/createTag`;
        return axiosClient.post(url, payload);
    },
    requestGetAllTag: (params) => {
        const url = '/api/customer/tag/getAll';
        return axiosClient.get(url, {params});
    },
    requestUploadCustomerFile: (data) => {
        const {file} = data;
        const url = `/api/customer/uploadCustomerFile`;
        return axiosFormData.post(url, file);
    }
};

export default customerApi;
