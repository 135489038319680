import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';

const eventApi = {
    getEventList: (params) => {
        // const url = '/api/getEventInfoByAdmin';
        const url = '/api/program/getAll';
        return axiosClient.get(url, {params});
    },
    addEvent: (data) => {
        const url = '/api/program';
        return axiosClient.post(url, data);
    },
    updateEvent: (data) => {
        const {id, body} = data;
        const url = `/api/program/${id}`;
        return axiosClient.put(url, body);
    },
    deleteEvent: (id) => {
        const url = `/api/program/${id}`;
        return axiosClient.delete(url);
    },
    uploadFileEvent: (data) => {
        const {file, id} = data;
        const url = `/api/program/uploadFile/${id}`;
        return axiosFormData.put(url, file);
    },
    uploadBannerProgram: (data) => {
        const {file, id} = data;
        const url = `/api/program/uploadBanner/${id}`;
        return axiosFormData.post(url, file);
    }
};

export default eventApi;
