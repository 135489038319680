import {pagePath} from 'configuration/routeConfig';
import Home from 'features/Home';
import CompanyInfo from './CompanyInfo';
import UpdateCompanyInfo from './UpdateCompanyInfo';

const CompanyMainView = (props) => {
    const {companyProps, history, updateCompanyProps} = props;
    let CompanyComponent = null;
    switch (history.location.pathname) {
        case pagePath.companyInfoUrl:
            CompanyComponent = <CompanyInfo {...companyProps} />;
            break;
        // case pagePath.updateCompanyInfoUrl:
        //     CompanyComponent = <UpdateCompanyInfo {...updateCompanyProps} />;
        //     break;
        default:
            CompanyComponent = <CompanyInfo {...companyProps} />;
            break;
    }
    return (
        <Home>
            <UpdateCompanyInfo {...updateCompanyProps} />
            {CompanyComponent}
        </Home>
    );
};

export default CompanyMainView;
