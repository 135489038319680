import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText } from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import { Close } from 'assets';
import { Controller, useForm } from 'react-hook-form';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomDatePicker from 'components/CustomDatePicker';
import GroupInput from 'components/GroupInput/GroupInput';
import dayjs from 'dayjs';
import CustomTextEditor from 'components/CustomTextEditor';
import 'react-quill/dist/quill.snow.css';
import InputFiles from 'components/InputFiles/InputFiles';
import PreviewImage from 'components/PreviewImage/PreviewImage';
const defaultValues = {
    title: '',
    description: '',
    location: '',
    email: '',
    formTime: '',
    toTime: '',
    cellPhone: ''
};

const EditNews = (props) => {
    const {
        isOpenNewsModal,
        closeNewsModal,
        isNewsEdit,
        handleAddNews,
        handleSubmitInvalid,
        handleDeleteNews,
        selectedNews,
        descriptionNews,
        setDescriptionNews,
        onUploadImage,
        detailImageList = [],
        handleDeleteImageList,
        handleUploadImageList
    } = props;
    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
        reset,
        setValue
    } = useForm({ defaultValues });

    const addNewsInputs = [
        {
            label: 'Tiêu đề tin tức',
            placeholder: 'Nhập tên tiêu đề tin tức',
            isRequired: true,
            rules: { required: { value: true, messages: 'trường này là bắt buộc' } },
            type: 'text',
            name: 'title'
        },
        {
            label: 'Ngày',
            placeholder: 'Ngày',
            isRequired: true,
            rules: { required: { value: true, messages: 'trường này là bắt buộc' } },
            type: 'date',
            name: 'fromTime'
        },
        {
            label: 'Địa điểm',
            placeholder: 'Nhập địa điểm',
            isRequired: false,
            rules: { required: { value: false, messages: 'trường này là bắt buộc' } },
            type: 'text',
            name: 'location'
        },

        // {
        //     label: (
        //         <span>
        //             Thời gian <span className='text-required'>*</span>
        //         </span>
        //     ),
        //     type: 'group',
        //     control,
        //     groupInputData: [
        //         {
        //             placeholder: 'Từ',
        //             isRequired: true,
        //             rules: { required: { value: true, messages: 'trường này là bắt buộc' } },
        //             type: 'date',
        //             name: 'fromTime'
        //         },
        //         {
        //             placeholder: 'Đến',
        //             isRequired: true,
        //             rules: { required: { value: true, messages: 'trường này là bắt buộc' } },
        //             type: 'date',
        //             name: 'toTime'
        //         }
        //     ]
        // },
        // {
        //     label: 'Email',
        //     placeholder: 'Nhập email',
        //     isRequired: false,
        //     rules: { required: { value: false, messages: 'trường này là bắt buộc' } },
        //     type: 'text',
        //     name: 'email'
        // },
        // {
        //     label: 'SĐT',
        //     placeholder: 'Nhập SĐT',
        //     isRequired: false,
        //     rules: { required: { value: false, messages: 'trường này là bắt buộc' } },
        //     type: 'text',
        //     name: 'cellPhone'
        // }
    ];

    useEffect(() => {
        reset();
    }, [isOpenNewsModal]);

    useEffect(() => {
        if (isNewsEdit && selectedNews) {
            setValue('title', selectedNews?.title);
            setValue('description', selectedNews?.description);
            setValue('location', selectedNews?.location);
            // setValue('email', selectedNews?.email);
            setValue('fromTime', new Date(selectedNews?.start_date));
            setValue('toTime', new Date(selectedNews?.end_date));
            // setValue('cellPhone', selectedNews?.cell_phone);
        }
    }, [isOpenNewsModal, selectedNews]);

    return (
        <>
            <Dialog
                maxWidth={isNewsEdit ? 'sm' : 'xs'}
                fullWidth={true}
                open={isOpenNewsModal}
                onClose={closeNewsModal}
                aria-labelledby='voucher-modal'
                aria-describedby='apply-voucher-to-get-discount'
                className='dialog'>
                <form
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (isNewsEdit) {
                            handleSubmit(handleAddNews, handleSubmitInvalid)();
                        } else {
                            handleDeleteNews();
                        }
                    }}>
                    <DialogTitle className='dialog-title' id='voucher-modal'>
                        <span>{isNewsEdit ? 'Chỉnh sửa tin tức' : 'Xác nhận'}</span>
                        <button className='dialog-title__close'>
                            <Close
                                className='cursor-pointer'
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeNewsModal();
                                }}
                            />
                        </button>
                    </DialogTitle>

                    <DialogContent className='dialog-content' style={{ padding: '32px 24px' }}>

                        {isNewsEdit && <div style={{ width: '100%' }}>
                            <div
                                style={{ marginBottom: 20 }}
                                className='d-flex align-items-center flex-wrap gap-3'>
                                {detailImageList.length > 0 && detailImageList.map((image, idx) => {
                                    return (
                                        <PreviewImage
                                            key={idx}
                                            image={image?.url}
                                            onDeleteImage={() => handleDeleteImageList(image)}
                                        />
                                    );
                                })}
                                {detailImageList.length === 0 && (
                                    <InputFiles
                                        type='image'
                                        inputNotes={['Thêm hình ảnh', 'Định dạng tệp PNG, JPEG, JPG', 'Kích thước: tối đa 5MB']}
                                        accept={['.png', '.jpeg', '.jpg']}
                                        handleUpload={handleUploadImageList}
                                        name='image'
                                        control={control}
                                    />
                                )}
                            </div>
                            {errors['images']?.message && <FormHelperText error>{errors['images']?.message}</FormHelperText>}
                        </div>}
                        <div className='add-program'>
                            {!isNewsEdit
                                ? 'Bạn có chắc muốn xóa tin tức này?'
                                :
                                addNewsInputs.map((input, idx) => {
                                    const { type, rules, groupInputData, label, name, ...restProps } = input;

                                    return (
                                        <FormControl key={idx}>
                                            {type !== 'group' ? (
                                                <Controller
                                                    name={name}
                                                    control={control}
                                                    rules={rules}
                                                    key={idx}
                                                    render={({ field: { value, ...restField } }) =>
                                                        type === 'date' ? (
                                                            <CustomDatePicker {...restField} {...restProps} label={label} value={value} />
                                                        ) : (
                                                            <CustomInput label={label} value={value} {...restField} {...restProps} />
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <GroupInput
                                                    control={control}
                                                    label={label}
                                                    key={idx}
                                                    groupInputData={groupInputData}
                                                />
                                            )}
                                        </FormControl>
                                    );
                                })}

                            {isNewsEdit && <CustomTextEditor value={descriptionNews} setValue={setDescriptionNews} onUploadImage={onUploadImage} />}
                        </div>

                    </DialogContent>
                    <DialogActions className='dialog-actions'>
                        <CustomButton
                            variant='text'
                            onClick={(e) => {
                                e.preventDefault();
                                closeNewsModal();
                            }}>
                            Hủy
                        </CustomButton>
                        <CustomButton
                            variant='contained'
                            type='submit'
                            color={!isNewsEdit ? 'danger' : 'primary'}
                        // disabled={!isFormValid && isSponsorEdit}
                        >
                            {isNewsEdit ? 'Lưu thay đổi' : 'Đồng ý'}
                        </CustomButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default EditNews;
