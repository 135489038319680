import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import customerApi from 'api/customerApi';
import serviceRequest from 'app/serviceRequest';

export const getCustomerList = createAsyncThunk('customer/getCustomerList', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: customerApi.getCustomerList,
        payload: data
    });
});
export const addCustomer = createAsyncThunk('customer/addCustomer', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: customerApi.requestAddCustomer,
        payload: data
    });
});
export const updateCustomer = createAsyncThunk('customer/updateCustomer', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: customerApi.requestEditCustomer,
        payload: data
    });
});
export const deleteCustomer = createAsyncThunk('customer/deleteCustomer', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: customerApi.requestDeleteCustomer,
        payload: data
    });
});
export const addNewTag = createAsyncThunk('customer/addNewTag', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: customerApi.requestAddNewTag,
        payload: data
    });
});
export const getAllTag = createAsyncThunk('customer/getAllTag', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: customerApi.requestGetAllTag,
        payload: data
    });
});
export const importFileCustomer = createAsyncThunk('customer/importFileCustomer', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: customerApi.requestUploadCustomerFile,
        payload: data
    });
});

const customer = createSlice({
    name: 'customer',
    initialState: {},
    reducers: {},
    extraReducers: (builders) => {
        builders.addCase(getCustomerList.fulfilled, (state, action) => {
            state.customerList = action?.payload?.data?.collection;
        });
        builders.addCase(getAllTag.fulfilled, (state, action) => {
            state.tagList = action?.payload?.data?.collection;
        });
    }
});

const {reducer, actions} = customer;
export default reducer;
