import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {calcTotalPage} from 'utils/calcTotalPage';
import propsProvider from './bannerPropsProvider';

import CustomToast from 'components/CustomToast';
import {pagePath} from 'configuration/routeConfig';
import {logout} from 'features/Auth/authSlice';
import {handleChangePage, handleChangePageSize, handleChangeTotalPage, uploadImage} from 'features/Home/homeSlice';
import BannerMainView from './template/BannerMainView';
import {addBanner, deleteBanner, getBannerList} from './bannerSlice';
import {getBase64} from 'utils/getBase64';

const BannerContainer = (props) => {
    const {home, userLoginInfo, bannerList} = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const {paginationData, activePage} = home;
    const [checkedData, setCheckedData] = useState([]);
    const [isBannerEdit, setIsBannerEdit] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState({});
    const [isOpenBannerModal, setBannerModal] = useState(false);
    const [detailImage, setDetailImage] = useState({});
    const navigateTo = (path) => {
        try {
            if (path === pagePath.logoutUrl) {
                dispatch(logout()).then((res) => history.push(pagePath.authLoginUrl));
            } else {
                history.push(path);
            }
        } catch (e) {
            CustomToast(false, 'Có lỗi xảy ra');
        }
    };

    const setPage = (value) => {
        dispatch(handleChangePage({activePage: activePage, page: value}));
    };

    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage: activePage, pageSize: value}));
        setPage(1);
    };

    const setTotalPage = (count, pageSize) => {
        const value = calcTotalPage(count, pageSize);
        // console.log('value', value);
        dispatch(handleChangeTotalPage({activePage: activePage, totalPage: value}));
    };

    const handleSelectRow = (event, id) => {};
    const handleDeleteSelectedRows = async () => {};
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            let newSelected = [];
            // if (activePage === 0) {
            //     newSelected = programList.map((data) => data?.id);
            // } else {
            //     newSelected = sponsorList.map((data) => data?.id);
            // }
            setCheckedData(newSelected);
            return;
        }

        setCheckedData([]);
    };
    const handleSubmitInvalid = (_, event) => {
        console.log('submit invalid');
        // event.target.classList.add('wasvalidated');
    };
    const handleDeleteBanner = async () => {
        const res = await dispatch(deleteBanner(selectedBanner?.id)).unwrap();
        const {success} = res;
        if (success) {
            CustomToast(success, 'Xóa banner thành công');
            fetchBannerList();
            closeBannerModal();
        } else {
            CustomToast(false, 'Xóa banner thất bại ');
            closeBannerModal();
        }
    };
    const handleAddBanner = async (data) => {
        const {fileName, typeImage} = data;
        let fileFormData = new FormData();
        const file = detailImage.file;
        fileFormData.append('file', file);
        const res = await dispatch(
            addBanner({
                type: typeImage == 'd3fc51f2-5917-4dfa-8243-bb1fbd42a013' ? 'Banner' : 'Slide',
                file: fileFormData
            })
        );

        const {success} = res.payload;
        if (success) {
            CustomToast(success, 'Thêm banner thành công');
            if (!isBannerEdit) navigateTo(pagePath.bannerUrl);
            else {
                fetchBannerList();
                closeBannerModal();
            }
        }
    };
    const handleUploadImages = async (e) => {
        const file = e.target.files[0];
        const {size} = file;

        if (size > 5 * 1024 * 1024) {
            CustomToast(false, 'Tệp vượt quá giới hạn dung lượng cho phép');
            return;
        }

        const previewUrl = await getBase64(file);
        setDetailImage({url: previewUrl, file});
    };
    const handleDeleteImage = (e) => {
        setDetailImage({});
    };
    const openBannerModal = (banner, isEdit) => {
        setIsBannerEdit(isEdit);
        setSelectedBanner(banner);
        setBannerModal(true);
    };
    const closeBannerModal = () => {
        setSelectedBanner({});
        setBannerModal(false);
    };

    const fetchBannerList = async () => {
        const res = await dispatch(
            getBannerList({
                Page: paginationData[activePage]?.page ?? 1,
                PageSize: paginationData[activePage]?.pageSize ?? 10
            })
        ).unwrap();
        const {success} = res;
        if (success) {
            const {data} = res;
            setTotalPage(data?.total, data?.pagesize);
        }
    };
    useEffect(() => {
        fetchBannerList();
    }, [paginationData]);

    const companyContainerProps = {
        history,
        navigateTo,
        bannerList,
        checkedData,
        handleDeleteSelectedRows,
        setPageSize,
        handleSelectRow,
        setPage,
        openBannerModal,
        paginationData,
        activePage,
        handleAddBanner,
        handleSubmitInvalid,
        detailImage,
        handleUploadImages,
        handleDeleteImage,
        isOpenBannerModal,
        closeBannerModal,
        handleDeleteBanner
    };

    return <BannerMainView {...propsProvider(companyContainerProps)} />;
};

export default BannerContainer;
